import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setBrands(state: StateType, brands: any): void {
    state.brands = brands;
  },
  setTotal(state: StateType, total: number): void {
    state.total = total;
  },
};
