<template>
  <b-field
    :label="$t('igPaymentVoucher.label')"
    :message="isInvalid ? $t('igPaymentVoucher.invalid') : ''"
    :type="getType"
    label-position="inside">
    <b-input
      v-model="voucher"
      :placeholder="$t('igPaymentVoucher.placeholder')"
      expanded></b-input>
    <p class="control">
      <b-button
        class="button is-primary"
        :loading="loadingVoucher"
        :disabled="loadingVoucher"
        @click="checkAndApply"
      >
        {{ $t('igPaymentVoucher.btnApply') }}
      </b-button>
    </p>
  </b-field>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('plans');

export default {
  name: 'ig-payment-voucher',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      voucher: null,
      isInvalid: false,
      isSuccess: false,
    };
  },
  computed: {
    ...mapState([
      'loadingVoucher',
    ]),
    getType() {
      if (this.isInvalid) return 'is-danger';
      if (this.isSuccess) return 'is-success';
      return '';
    },
  },
  methods: {
    ...mapActions([
      'getVoucher',
    ]),
    async checkAndApply() {
      this.isInvalid = false;
      this.isSuccess = false;

      try {
        const voucherInfo = await this.getVoucher({
          type: this.type,
          code: this.voucher,
        });
        this.$emit('apply', voucherInfo);
        this.isSuccess = true;
      } catch (e) {
        this.isInvalid = true;
      }
    },
  },
};
</script>
