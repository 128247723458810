import { ActionContext } from 'vuex';
import { HttpStore } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getOrder(context: ActionContext<StateType, any>, orderId: string): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.get(`orders/${orderId}`);

      commit('setOrder', response);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
};
