<template>
  <ig-page-base :loading="loading">
    <div class="box">
      <h2 class="title">
        {{ $t('pageMapping.title') }}
      </h2>

      <p class="content is-size-7" v-html="$t('pageMapping.info')"></p>

      <form @submit.prevent="() => getListMappings()">
        <div class="columns">
            <div class="column">
                <b-field
                        :label="$t('pageMapping.filters.type.label')"
                        label-position="inside">
                    <b-select
                            :placeholder="$t('pageMapping.filters.type.placeholder')"
                            v-model="filters.type"
                            :disabled="loading"
                            expanded>
                        <option :value="null">
                            {{ $t('pageMapping.filters.type.all') }}
                        </option>
                        <option
                                v-for="type in types"
                                :key="type.id"
                                :value="type.id">{{ $t(type.label) }}</option>
                    </b-select>
                </b-field>
            </div>
          <div class="column">
            <b-field
              :label="$t('pageMapping.filters.moduleConfig.label')"
              label-position="inside">
              <b-select
                :placeholder="$t('pageMapping.filters.moduleConfig.placeholder')"
                v-model="filters.moduleConfigId"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageMapping.filters.moduleConfig.all') }}
                </option>
                <option
                  v-for="module in moduleConfigs"
                  :key="module.id"
                  :value="module.id">{{ module.label }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageMapping.filters.identifier.label')"
              label-position="inside">
              <b-input
                :placeholder="$t('pageMapping.filters.identifier.placeholder')"
                v-model="filters.identifier"
                :disabled="loading" />
            </b-field>
          </div>
        </div>

        <div class="field has-addons but has-addons-right">
          <div class="buttons">
            <b-button
              class="is-uppercase"
              :label="$t('pageMapping.filters.btnReset')"
              type="is-default"
              @click="cleanFilters" />
            <b-button
              native-type="submit"
              class="is-uppercase"
              :label="$t('pageMapping.filters.btnSearch')"
              :disabled="loading"
              :loading="loading"
              type="is-primary" />
          </div>
        </div>
      </form>

      <div class="field has-addons but has-addons-right">
        <b-button
          class="is-uppercase"
          :label="$t('pageMapping.btnNew')"
          type="is-success"
          @click="newMapping"/>
      </div>

      <ig-manage-mapping v-if="formNewOpen" @reload="typeId => getListMappings(typeId)" />

      <hr />

      <div v-if="mappings.length > 0">
        <div v-for="mapping in mappings" :key="mapping._id" class="mapping__item">
          <div class="mapping__item-container">
            <div class="mapping__module">
              <div class="mapping__module-image">
                <img
                    :src="mapping.leftModuleConfig.image"
                    :alt="mapping.leftModuleConfig.name" />
              </div>
            </div>
            <div class="mapping__info">
              <div class="mapping__info-header">
                <div class="mapping__module-title">
                  {{ mapping.leftModuleConfig.name }}
                </div>

                <b-tag
                    type="is-primary is-light"
                    class="mapping__info-type"
                >
                  {{ $t(`pageMapping.types.${mapping.type}`) }}
                </b-tag>
              </div>

              <div class="mapping__info-name">
                {{ mapping.leftLabel }}
              </div>

              <div class="mapping__info-identifier">
                <small>
                  <strong>ID: </strong> <code>{{ mapping.leftIdentifier }}</code>
                </small>
              </div>
            </div>
          </div>

          <div class="column is-2 mapping__separator">
            <img
                src="@/assets/arrows.png"
                style="height: 50px"
                alt="Setas ilustrando mapeamento" />

            <div>
              <b-button
                  size="is-small"
                  type="is-danger"
                  icon-right="trash"
                  @click="deleteMapping(mapping.id)"
              />
            </div>
          </div>

          <div class="mapping__item-container">
            <div class="mapping__module">
              <div class="mapping__module-image">
                <img
                    :src="mapping.rightModuleConfig.image"
                    :alt="mapping.rightModuleConfig.name" />
              </div>
            </div>
            <div class="mapping__info">
              <div class="mapping__info-header">
                <div class="mapping__module-title">
                  {{ mapping.rightModuleConfig.name }}
                </div>

                <b-tag
                    type="is-primary is-light"
                    class="mapping__info-type"
                >
                  {{ $t(`pageMapping.types.${mapping.type}`) }}
                </b-tag>
              </div>

              <div class="mapping__info-name">
                {{ mapping.rightLabel }}
              </div>

              <div class="mapping__info-identifier">
                <small>
                  <strong>ID: </strong> <code>{{ mapping.rightIdentifier }}</code>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ig-empty message="Nenhum mapeamento encontrado, veja os filtros acima" v-else />
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgEmpty } from '@integrai/ui';
import IgManageMapping from './components/IgManageMapping.vue';

const { mapState, mapActions } = createNamespacedHelpers('mappings');

export default Vue.extend({
  name: 'page-mappings',
  components: {
    IgPageBase,
    IgEmpty,
    IgManageMapping,
  },
  data() {
    return {
      formNewOpen: false,
      filters: {},
      moduleConfigs: [],
    };
  },
  computed: {
    ...mapState([
      'loading',
      'types',
      'mappings',
    ]),
  },
  methods: {
    ...mapActions([
      'searchMappings',
      'createMapping',
      'removeMapping',
      'getModuleConfigs',
      'getTypes',
    ]),
    cleanFilters() {
      this.filters = {};
    },
    async getListMappings(typeId) {
      if (typeId) {
        this.filters.type = typeId;
      }

      await this.searchMappings(this.filters);
    },
    newMapping() {
      this.formNewOpen = true;
    },
    async deleteMapping(id) {
      await this.removeMapping(id);
      await this.getListMappings();
    },
  },
  async beforeMount() {
    this.moduleConfigs = await this.getModuleConfigs();

    await Promise.all([
      this.getTypes(),
      this.getListMappings(),
    ]);
  },
});
</script>

<style lang="scss">
.mapping{
  &__item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  &__item-container{
    display: flex;
    padding: .5rem;
    border: 1px solid #dedcdc;
    border-radius: 10px;
    width: 100%;
  }
  &__separator{
    text-align: center;
  }
  &__module{
    display: flex;
    justify-items: center;
    &-image{
      width: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: .5rem;
      padding: 0.5rem;
    }
    &-title{
      font-size: .8rem;
    }
  }
  &__info{
    flex: auto;
    &-header{
      display: flex;
      justify-content: space-between;
    }
    &-name{
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: .5rem;
    }
    &-identifier{
      font-size: 0.8rem;
    }
  }
}
</style>
