<template>
  <ig-page-base>
    <div class="box brands__content">
      <div class="brands__title-box">
        <h2 class="title brands__title has-text-weight-semibold">
          {{ $t(`storePages.product.brands.manage.${isUpdate ? 'titleUpdate' : 'titleCreate'}`) }}
        </h2>
      </div>

      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="brands__form-header">
            <h2 class="brands__form-title">
              {{ $t('storePages.product.brands.manage.fields.headerDefault') }}
            </h2>

            <b-switch
              v-model="form.enabled"
              type="is-primary">
              {{ $t('storePages.product.brands.manage.fields.enabled.label') }}
            </b-switch>
          </div>

          <validation-provider
            rules="required"
            :name="$t('storePages.product.brands.manage.fields.name.label')"
            v-slot="{ errors, valid }"
          >
            <div class="field">
              <b-field
                :label="$t('storePages.product.brands.manage.fields.name.label')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
                label-position="inside">
                <b-input v-model="form.name" />
              </b-field>
            </div>
          </validation-provider>

          <div class="field">
            <b-field
              :label="$t('storePages.product.brands.manage.fields.site.label')"
              label-position="inside">
              <b-input
                v-model="form.site"
                :placeholder="$t('storePages.product.brands.manage.fields.site.placeholder')"
              />
            </b-field>
          </div>

          <div class="field">
            <b-field
              :label="$t('storePages.product.brands.manage.fields.description.label')"
              label-position="inside">
              <b-input type="textarea" v-model="form.description" />
            </b-field>
          </div>

          <div class="brands__form-header">
            <h2 class="brands__form-title">
              {{ $t('storePages.product.brands.manage.fields.headerImage') }}
            </h2>
          </div>

          <ig-file-upload
            ref="image"
            :labelIdle="$t('storePages.product.brands.manage.fields.dropLabel')"
            :files="form.logo"
            :server="serverPhoto"
            :instantUpload="false"
            :allowProcess="false"
            acceptedFileTypes="image/*"
            maxFileSize="1MB"
            @error="error"
            credits=""
          />

          <div class="buttons brands__form-actions">
            <b-button
              tag="router-link"
              :to="{ name: 'brands' }"
              type="is-default"
            >
              {{ $t('storePages.product.brands.manage.btnCancel') }}
            </b-button>

            <b-button
              native-type="submit"
              type="is-primary"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t(`storePages.product.brands.manage.${isUpdate ? 'btnUpdate' : 'btnCreate'}`) }}
            </b-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase,
  IgFileUpload,
  EventBus,
} from '@integrai/ui';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('brands');

export default Vue.extend({
  name: 'page-manage-brand',
  components: {
    IgPageBase,
    IgFileUpload,
  },
  data() {
    return {
      presignedUrl: null,
      isUpdate: false,
      form: {
        enabled: true,
      },
    };
  },
  computed: {
    ...mapState([
      'loading',
    ]),
    serverPhoto() {
      return {
        revert: null,
        restore: null,
        load: null,
        fetch: null,
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const request = new XMLHttpRequest();

          request.open('PUT', this.presignedUrl, true);

          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total);
          };

          request.onload = function onload() {
            if (request.status >= 200 && request.status < 300) {
              load(request.responseText);
            } else {
              error('oh no');
            }
          };

          request.send(file);

          return {
            abort: () => {
              request.abort();
              abort();
            },
          };
        },
      };
    },
  },
  methods: {
    ...mapActions([
      'getPresignedUrlPhoto',
      'getBrand',
      'createBrand',
      'updateBrand',
    ]),
    ...mapMutations([
      'toggleLoading',
    ]),
    error() {
      this.toggleLoading(false);
    },
    async submit() {
      this.toggleLoading(true);

      const file = this.$refs.image.getFile();

      if (!file) {
        this.form.logo = null;
      } else if (file && typeof file.source === 'object') {
        const {
          presignedUrl,
          logo,
        } = await this.getPresignedUrlPhoto(file.source.name);

        this.presignedUrl = presignedUrl;
        await this.$refs.image.processFile();
        this.form.logo = logo;
        this.presignedUrl = null;
      }

      if (this.isUpdate) {
        const {
          id,
          ...form
        } = this.form;

        await this.updateBrand({
          id,
          form,
        });
      } else {
        await this.createBrand(this.form);
      }

      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t(
          `storePages.product.brands.manage.${this.isUpdate ? 'updateSuccess' : 'createSuccess'}`,
        ),
      });

      this.$router.push({ name: 'brands' });
    },
  },
  async beforeMount() {
    const { id } = this.$route.params;
    if (id) {
      try {
        this.form = await this.getBrand(id);
        this.isUpdate = true;
      } catch {
        this.$router.push({ name: 'brands' });
      }
    }
  },
});
</script>

<style lang="scss">
  .brands{
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__form-header{
      display: flex;
      border-bottom: 1px solid #b5b5b5;
      padding: 1rem 0;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }
    &__form-title{
      font-weight: 700;
    }
    &__form-actions{
      justify-content: flex-end;
    }
    &__drop-title{
      font-size: 1.7rem!important;
      font-weight: 600;
      margin-bottom: 0.5rem;
      .svg-inline--fa{
        display: block;
        margin: 0.5rem auto;
        font-size: 2rem;
      }
    }
    &__drop-subtitle{
      font-size: 0.8rem!important;
      margin-bottom: 3rem;
    }
  }

  .field{
    margin-bottom: 2rem;
  }
</style>
