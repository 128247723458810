<template>
  <ig-page-base :loading="loading">
    <div class="box integration-plan-and-consumption__content" v-if="!loading">
      <div>
        <h2 class="title integration-plan-and-consumption__title has-text-weight-semibold">
          {{ $t('pagePlanAndConsumption.plan.title') }}
        </h2>

        <b-message
          type="is-success"
          icon="check-circle"
          icon-size="is-small"
          v-if="successShopify"
          has-icon>
          <span v-html="$t('pagePlanAndConsumption.shopifyPlanSuccess')"></span>
        </b-message>

        <div class="integration-plan">
          <div class="integration-plan__info">
            <div class="integration-plan__actual">
              <ul class="integration-plan__list">
                <li
                  v-for="plan in plans"
                  :key="plan.name"
                  class="integration-plan__list-item tag is-uppercase"
                  :class="{
                    'is-primary': plan.slug === actualPlan.slug,
                    'is-success': actualPlan.migrationPlanSlug === plan.slug,
                  }"
                >
                  {{ plan.name }}
                </li>
              </ul>
              <small
                v-if="actualPlan.migrationPlanSlug"
                class="integration-plan__migration-text">
                {{ $t('pagePlanAndConsumption.plan.migrationText') }}
              </small>
            </div>
            <div class="integration-plan__value">
              <span>
                {{ $t('pagePlanAndConsumption.plan.value') }}
              </span>

              <strong class="integration-plan__price">
                <span v-if="hasPlan">
                  <span>{{ $t('pagePlanAndConsumption.plan.priceCurrency') }} </span>
                  <span>{{ actualPlan.price | finance }}</span>
                  <span> / </span>
                  <span>{{ $t('pagePlanAndConsumption.plan.priceSuffix') }}</span>
                </span>
                <span v-if="!hasPlan">
                  {{ $t('pagePlanAndConsumption.plan.free') }}
                </span>
              </strong>
            </div>
          </div>
          <div class="integration-plan__btn">
            <b-button
              tag="router-link"
              :to="{ name: 'plans' }"
              class="is-light is-uppercase"
              :class="{
                'is-success': !hasPlan,
                'is-primary': hasPlan,
              }"
            >
              <span v-if="!hasPlan">
                {{ $t('pagePlanAndConsumption.plan.btnSubscription') }}
              </span>
              <span v-if="hasPlan">
                {{ $t('pagePlanAndConsumption.plan.btnChangeSubscription') }}
              </span>
            </b-button>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <h2 class="title integration-plan-and-consumption__title has-text-weight-semibold">
          {{ $t('pagePlanAndConsumption.consumption.title') }}
        </h2>

        <p class="content is-size-7">
          <span v-html="$t('pagePlanAndConsumption.consumption.info', {
           totalEvents: planConsumption.events.actual,
           totalModules: planConsumption.modules.actual
           })"></span>
          <strong class="has-text-primary">
            {{ planConsumption.endCycle | date }}
          </strong>.
        </p>

        <ig-progress-bar
          :label="$t('pagePlanAndConsumption.consumption.events')"
          :value="planConsumption.events.actual"
          :max-value="planConsumption.events.max"
        />

        <ig-progress-bar
          :label="$t('pagePlanAndConsumption.consumption.modules')"
          :value="planConsumption.modules.actual"
          :max-value="planConsumption.modules.max"
        />

        <div class="integration-plan-and-consumption__restrictions">
          <div class="integration-plan-and-consumption__restrictions-item">
            <span class="integration-plan-and-consumption__restrictions-label">
              {{ $t('pagePlanAndConsumption.consumption.contingency') }}
            </span>
            <span
              class="tag is-light"
              :class="{
                'is-success': planConsumption.hasContingency,
                'is-danger': !planConsumption.hasContingency,
              }"
            >
              {{ $t([
                'pagePlanAndConsumption',
                'consumption',
                planConsumption.hasContingency ? 'available' : 'unavailable',
              ].join('.')) }}
            </span>
          </div>
          <div class="integration-plan-and-consumption__restrictions-item">
            <span class="integration-plan-and-consumption__restrictions-label">
              {{ $t('pagePlanAndConsumption.consumption.support') }}
            </span>
            <span
              class="tag is-light"
              :class="{
                'is-success': planConsumption.hasSupport,
                'is-danger': !planConsumption.hasSupport,
              }"
            >
              {{ $t([
                'pagePlanAndConsumption',
                'consumption',
                planConsumption.hasSupport ? 'available' : 'unavailable',
              ].join('.')) }}
            </span>
          </div>
          <div class="integration-plan-and-consumption__restrictions-item">
            <span class="integration-plan-and-consumption__restrictions-label">
              {{ $t('pagePlanAndConsumption.consumption.erp') }}
            </span>
            <span
              class="tag is-light"
              :class="{
                'is-success': planConsumption.hasErp,
                'is-danger': !planConsumption.hasErp,
              }"
            >
              {{ $t([
                'pagePlanAndConsumption',
                'consumption',
                planConsumption.hasErp ? 'available' : 'unavailable',
              ].join('.')) }}
            </span>
          </div>
          <div class="integration-plan-and-consumption__restrictions-item" style="display: none">
            <span class="integration-plan-and-consumption__restrictions-label">
              {{ $t('pagePlanAndConsumption.consumption.marketplace') }}
            </span>
            <span
              class="tag is-light"
              :class="{
                'is-success': planConsumption.hasMartetplace,
                'is-danger': !planConsumption.hasMartetplace,
              }"
            >
              {{ $t([
                'pagePlanAndConsumption',
                'consumption',
                planConsumption.hasMartetplace ? 'available' : 'unavailable',
              ].join('.')) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgProgressBar } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('plan-and-consumption');

export default Vue.extend({
  name: 'page-plan-and-consumption',
  components: {
    IgPageBase,
    IgProgressBar,
  },
  data() {
    return {
      successShopify: false,
    };
  },
  computed: {
    ...mapState([
      'loading',
      'plans',
      'planConsumption',
      'actualPlan',
    ]),
    hasPlan() {
      const {
        name = '',
      } = this.actualPlan || {};

      return name.toLowerCase() !== 'free';
    },
  },
  methods: {
    ...mapActions([
      'getPlanAndConsumption',
    ]),
  },
  async beforeMount() {
    if (this.$route.query.successShopify) {
      this.successShopify = true;
      this.$router.replace({});
    }

    await this.getPlanAndConsumption();
  },
});
</script>

<style lang="scss">
  .integration-plan-and-consumption{
    &__title{
      padding-bottom: 1rem;
    }
    &__content{
      width: 60%;
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
    &__restrictions{
      display: flex;
      justify-content: space-around;
      margin: 2rem 0;
      @media screen and (max-width: 760px) {
        flex-direction: column;
      }
    }
    &__restrictions-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 760px) {
        margin-bottom: 1rem;
      }
    }
    &__restrictions-label{
      margin-bottom: 1rem;
    }
  }

  .integration-plan{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 760px) {
      flex-direction: column;
      justify-content: center;
    }
    &__info{
      @media screen and (max-width: 760px) {
        margin-bottom: 2rem;
      }
    }
    &__actual{
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    &__migration-text{
      font-size: 0.8rem;
      margin-top: .5rem;
    }
    &__value{
      @media screen and (max-width: 760px) {
        text-align: center;
      }
    }
    &__list{
      display: flex;
      &-item{
        margin: 0 .2rem;
        &.is-primary{
          border-color: #0b75a2;
        }
      }
    }
    &__btn{}
  }
</style>
