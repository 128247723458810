<template>
  <section>
    <p>
      {{ $t('pageIntegrationDetail.step_2.introduction') }}
    </p>

    <div class="integration-detail__form">
      <h2 class="integration-detail__form-title sub-title">
        {{ $t('pageIntegrationDetail.step_2.formTitle') }}
      </h2>

      <ig-form-schema
        v-if="module"
        :schema="module.formSchemaAuth"
        :model="formConfigs"
        :validation="true"
        @submit="checkAuth">
        <template slot="actions">
          <b-button
            native-type="submit"
            class="is-uppercase"
            :label="$t('pageIntegrationDetail.step_2.btnSave')"
            :disabled="loadingSave"
            :loading="loadingSave"
            type="is-primary" />
        </template>
      </ig-form-schema>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { IgFormSchema } from '@integrai/ui';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('integrations');

export default {
  name: 'integration-step2',
  components: {
    IgFormSchema,
  },
  computed: {
    ...mapState([
      'loadingSave',
      'module',
      'client',
    ]),
    formConfigs() {
      const {
        name: moduleName,
        _id: moduleId,
        configs,
        oauth,
        authError,
      } = this.module;

      return {
        ...configs,
        moduleId,
        moduleName,
        oauth,
        configs,
        authError,
      };
    },
  },
  methods: {
    ...mapMutations([
      'changeStep',
    ]),
    ...mapActions([
      'getIntegration',
      'saveModule',
      'checkAndSaveAuth',
    ]),
    async checkAuth(newConfigs) {
      const {
        oauth, //eslint-disable-line
        configs, //eslint-disable-line
        ...restConfigs
      } = newConfigs;

      await this.checkAndSaveAuth({
        module: this.module._id, //eslint-disable-line
        configs: restConfigs,
      });

      await this.getIntegration({
        id: this.module._id, //eslint-disable-line
        moduleConfigId: this.module.moduleConfigId,
      });

      this.changeStep(3);
    },
  },
};
</script>

<style lang="scss">
  .integration-detail{
    &__form{
      padding: 2rem 0;
    }
    &__form-title{
      padding-bottom: 1rem;
    }
  }
</style>
