import { ActionContext } from 'vuex';
import { Http } from '@integrai/ui';
import StateType, { FormType } from './state.type';

export default {
  async getContingency(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const contingency: any = await Http.get('contingency');
      commit('setContingency', contingency);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async updateContigency(
    context: ActionContext<StateType, {}>,
    form: FormType,
  ): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await Http.put('contingency', form);
      commit('toggleLoading', false);
    } catch (e) {
      commit('toggleLoading', false);
      throw e;
    }
  },
};
