<template>
  <div>
    <h2 class="ig-payment-methods__title">
      {{ $t('pageCheckout.title') }}
    </h2>

    <div class="ig-payment-methods__plan">
      <b-icon
        class="ig-payment-methods__plan-icon"
        icon="cloud"
        size="is-large"/>

      <template v-if="checkout.product">
        <div class="ig-payment-methods__plan-info">
          <label class="ig-payment-methods__plan-label">
            {{ $t('pageCheckout.productSelected') }}
          </label>
          <strong class="ig-payment-methods__plan-value">
            {{ checkout.product.name }}
          </strong>
        </div>

        <div class="ig-payment-methods__plan-info">
          <label class="ig-payment-methods__plan-label">
            {{ $t('pageCheckout.productPrice') }}
          </label>
          <div>
            <small class="ig-payment-methods__plan-old-value" v-if="discount">
              R${{ checkout.product.price | finance(0) }}
            </small>
            <strong class="ig-payment-methods__plan-value">
              R${{ getPriceWithDiscount(discount, checkout.product.price) | finance(2, ',') }}
              <span v-if="discount && discount.isPercent">({{ discount.value }}%)</span>
            </strong>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="ig-payment-methods__plan-info">
          <label class="ig-payment-methods__plan-label">
            {{ $t('pageCheckout.planSelected') }}
          </label>
          <strong class="ig-payment-methods__plan-value">
            {{ checkout.plan.name }}
          </strong>
        </div>

        <div class="ig-payment-methods__plan-info">
          <label class="ig-payment-methods__plan-label">
            {{ $t('pageCheckout.planPrice') }}
          </label>
          <div>
            <small class="ig-payment-methods__plan-old-value" v-if="discount">
              R${{ checkout.plan.price | finance }}
            </small>
            <strong class="ig-payment-methods__plan-value">
              R${{ getPriceWithDiscount(discount, checkout.plan.price) | finance(2, ',') }}
              <span v-if="discount && discount.isPercent">({{ discount.value }}%)</span>
            </strong>
          </div>
        </div>
      </template>
    </div>

    <div class="ig-payment-methods__voucher">
     <ig-payment-voucher
       :type="checkout.product ? 'PRODUCT' : 'SUBSCRIPTION'"
       @apply="applyVoucher"/>
    </div>

    <hr />

    <div class="ig-payment-methods__methods">
      <button
        class="ig-payment-methods__method"
        @click="chooseMethod(method.value)"
        v-for="method in methods"
        :key="method.value"
      >
        <b-icon
          class="ig-payment-methods__method-icon"
          :icon="method.icon"
          size="is-large"/>
        <span class="ig-payment-methods__method-label">
          {{ method.label }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import IgPaymentVoucher from './IgPaymentVoucher.vue';

const { mapState, mapMutations } = createNamespacedHelpers('plans');

export default {
  name: 'ig-payment-methods',
  data() {
    return {
      discount: null,
    };
  },
  computed: {
    ...mapState([
      'methods',
      'checkout',
    ]),
  },
  methods: {
    ...mapMutations([
      'setCheckout',
      'setMethods',
    ]),
    chooseMethod(method) {
      this.setCheckout({
        method,
      });
      this.$emit('changeTab', 1);
    },
    applyVoucher(info) {
      this.discount = info;

      this.setCheckout({
        voucher: info.code,
      });
    },
    getPriceWithDiscount(discount, price) {
      if (discount && discount.value) {
        return discount.isPercent ? price - (price * (discount.value / 100)) : price - discount;
      }

      return price;
    },
  },
  beforeMount() {
    this.setMethods([
      {
        label: this.$t('paymentsMethods.boleto'),
        icon: 'barcode',
        value: 'BOLETO',
      },
      {
        label: this.$t('paymentsMethods.creditCard'),
        icon: 'credit-card',
        value: 'CREDIT_CARD',
      },
    ]);
  },
  components: {
    IgPaymentVoucher,
  },
};
</script>

<style lang="scss" scoped>
  .ig-payment-methods{
    &__title{
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 2rem;
      margin-right: 4rem;
      @media screen and (max-width: 760px) {
        font-size: 1rem;
        margin-right: 0;
        margin-top: 0.3rem;
        text-align: center;
      }
    }
    &__plan{
      background: #22b2f0;
      padding: 1rem;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-bottom: 3rem;
      &:after{
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #22b2f0;
      }
      &-icon{
        color: #fff;
        font-size: 1rem;
        margin-right: 1rem;
      }
      &-info{
        display: flex;
        flex-direction: column;
        flex: auto;
      }
      &-label{
        font-weight: bold;
        font-size: .8rem;
        margin-right: 1rem;
        line-height: 1;
        color: #164254;
      }
      &-old-value{
        font-weight: bold;
        text-decoration-line: line-through;
      }
      &-value{
        font-weight: bold;
        color: #fff;
        font-size: 1rem;
      }
    }
    &__methods{
      display: flex;
      flex-direction: column;
    }
    &__method{
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: .2rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      background-color: #fff;
      &:hover{
        box-shadow: inset rgba(143,160,241,0.2) 0 0 0 1px, rgba(213,220,247,0.59) 0 10px 20px;
        cursor: pointer;
        color: #22b2f0;
        border-color: #22b2f0;
      }
      &-icon{
        font-size: .9rem;
        margin-right: .2rem;
      }
      &-label{
        font-weight: bold;
      }
    }
  }
</style>
