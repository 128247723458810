import Vue from 'vue';
import Vuex from 'vuex';

import { Store } from '@integrai/ui';

/* eslint-disable */
// @ts-ignore
import * as privateStores from './features/private/**/store/index.ts';
// @ts-ignore
import * as publicStores from './features/public/**/store/index.ts';
// @ts-ignore
import * as componentStores from './components/**/store/index.ts';
/* eslint-enable */
Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: Store.convert({
    ...publicStores,
    ...privateStores,
    ...componentStores,
  }),
});
