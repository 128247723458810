var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","close-button-aria-label":"Close","aria-modal":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"800px"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.$t('pageFlow.manage.title'))+" ")]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeModal()}}})]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('pageFlow.filters.sourceModule.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('pageFlow.filters.sourceModule.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":_vm.$t('pageFlow.filters.sourceModule.placeholder'),"disabled":_vm.loading,"expanded":""},model:{value:(_vm.formFlow.moduleSourceConfig),callback:function ($$v) {_vm.$set(_vm.formFlow, "moduleSourceConfig", $$v)},expression:"formFlow.moduleSourceConfig"}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('pageFlow.filters.sourceModule.all'))+" ")]),_vm._l((_vm.modules),function(module){return _c('option',{key:module.moduleConfigId,domProps:{"value":module.moduleConfigId}},[_vm._v(_vm._s(module.name))])})],2)],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('pageFlow.filters.targetModule.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('pageFlow.filters.targetModule.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":_vm.$t('pageFlow.filters.targetModule.placeholder'),"disabled":_vm.loading,"expanded":""},model:{value:(_vm.formFlow.moduleTargetConfig),callback:function ($$v) {_vm.$set(_vm.formFlow, "moduleTargetConfig", $$v)},expression:"formFlow.moduleTargetConfig"}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('pageFlow.filters.targetModule.all'))+" ")]),_vm._l((_vm.modules),function(module){return _c('option',{key:module.moduleConfigId,domProps:{"value":module.moduleConfigId}},[_vm._v(_vm._s(module.name))])})],2)],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('pageFlow.filters.event.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('pageFlow.filters.event.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":_vm.$t('pageFlow.filters.event.placeholder'),"disabled":_vm.loading,"expanded":""},model:{value:(_vm.formFlow.event),callback:function ($$v) {_vm.$set(_vm.formFlow, "event", $$v)},expression:"formFlow.event"}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('pageFlow.filters.event.all'))+" ")]),_vm._l((_vm.filterEvents),function(event){return _c('option',{key:event.value,domProps:{"value":event.value}},[_vm._v(_vm._s(event.label))])})],2)],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('pageFlow.filters.sourceModule.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-switch',{attrs:{"type":"is-primary"},model:{value:(_vm.formFlow.active),callback:function ($$v) {_vm.$set(_vm.formFlow, "active", $$v)},expression:"formFlow.active"}},[_vm._v(" "+_vm._s(_vm.$t('pageFlow.filters.status.label'))+" ")])],1)]}}],null,true)})],1)]),_c('b-field',{attrs:{"label":_vm.$t('pageFlow.manage.fields.functionCheck.label'),"message":_vm.$t('pageFlow.manage.fields.functionCheck.info'),"label-position":"inside"}},[_c('b-input',{attrs:{"type":"textarea","placeholder":_vm.$t('pageFlow.manage.fields.functionCheck.placeholder')},model:{value:(_vm.formFlow.functionCheck),callback:function ($$v) {_vm.$set(_vm.formFlow, "functionCheck", $$v)},expression:"formFlow.functionCheck"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"label":_vm.$t('pageFlow.manage.buttons.cancel'),"disabled":_vm.loadingSave},on:{"click":function($event){return _vm.closeModal()}}}),_c('b-button',{attrs:{"native-type":"submit","label":_vm.$t('pageFlow.manage.buttons.save'),"loading":_vm.loadingSave,"disabled":_vm.loadingSave,"type":"is-primary"}})],1)])])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }