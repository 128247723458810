<template>
  <div>
    <b-button
      tag="a"
      href="https://ajuda.integrai.com.br/helpdesk"
      target="_blank"
      :class="['ig-open-ticket-popup__btn', {
        'ig-open-ticket-popup__btn--is-bottom': isPageBottom,
        'ig-open-ticket-popup__btn--is-mobile': isMobile,
      }]"
      type="is-primary"
      icon-left="question-circle"
      rounded>
      <template v-if="!isMobile">
        {{ $t('openTicket.button') }}
      </template>
    </b-button>

    <b-modal
      v-model="modalVisible"
      :width="640"
      scroll="keep"
      class="ig-open-ticket-popup__modal">
      <div class="card">
        <div class="card-header">
          <p class="card-header-title title is-4 ig-open-ticket-popup__title">
            {{ $t('openTicket.title') }}
          </p>

          <a
            href="https://ajuda.integrai.com.br/helpdesk"
            target="_blank"
            class="card-header-icon"
            aria-label="more options">
            Meus chamados
          </a>
        </div>

        <div class="card-content ig-open-ticket-popup__content">
          <p class="ig-open-ticket-popup__subtitle title is-6 has-text-centered">
            {{ $t('openTicket.attendance') }}
          </p>

          <iframe
            src="https://integrai.tomticket.com/formulario?id=EP52059&account=3331776P03032021061959"
            class="ig-open-ticket-popup__iframe"></iframe>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ig-open-ticket-popup',
  data() {
    return {
      modalVisible: false,
      isPageBottom: false,
      isMobile: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
    setIsPageBottom() {
      this.isPageBottom = !this.isMobile
        && (window.innerHeight + window.scrollY) >= document.body.offsetHeight - 50;
    },
    setIsMobile() {
      this.isMobile = window.innerWidth <= 500;
    },
  },
  mounted() {
    window.onscroll = this.setIsPageBottom;
    window.onresize = this.setIsMobile;
    window.onload = this.setIsMobile;
  },
};
</script>

<style lang="scss" scoped>
  .ig-open-ticket-popup {
    &__btn {
      position: fixed;
      bottom: 1.5rem;
      right: 2rem;
      z-index: 1;
      transition: bottom .2s ease, width .2s ease;

      &--is-bottom {
        bottom: 7rem;
      }

      &--is-mobile {
        width: 40px;
      }
    }

    &__title {
      padding: 1.25rem 2.75rem;
      margin: 0;
    }

    &__subtitle {
      padding: 0;
      font-weight: normal;
    }

    &__content {
      height: 540px;
      max-height: 100%;
    }

    &__iframe {
      width: 100%;
      height: 100%;
      border-width: 0;
      overflow: auto;
    }
  }
</style>
