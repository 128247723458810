import { ActionContext } from 'vuex';
import { Http, Auth } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getPlans(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const [
        actualPlan,
        plans,
      ] = await Promise.all([
        Http.get('plan'),
        Http.get('plan/list'),
      ]);

      commit('setActualPlan', actualPlan);
      commit('setPlans', plans);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async subscribe(context: ActionContext<StateType, {}>): Promise<any> {
    const { commit, state, dispatch } = context || {};

    commit('toggleLoadingCheckout', true);

    try {
      const response = await Http.post('plan/subscribe', state.checkout);

      if (state.checkout.plan.price > state.actualPlan.price) {
        Auth.updateUser({
          plan: {
            name: state.checkout.plan.name,
          },
        });
      }

      await dispatch('getPlans');

      commit('toggleLoadingCheckout', false);

      return response;
    } catch (e) {
      commit('toggleLoadingCheckout', false);
      throw e;
    }
  },
  async buyProduct(context: ActionContext<StateType, {}>): Promise<any> {
    const { commit, state } = context || {};

    commit('toggleLoadingCheckout', true);

    try {
      const response = await Http.post('product/buy', state.checkout);

      commit('toggleLoadingCheckout', false);

      return response;
    } catch (e) {
      commit('toggleLoadingCheckout', false);
      throw e;
    }
  },
  async getVoucher(
    context: ActionContext<StateType, {}>,
    voucher: any,
  ): Promise<any> {
    const { commit } = context || {};
    const { type, code } = voucher || {};

    commit('toggleLoadingVoucher', true);

    try {
      const response = await Http.get(`plan/voucher/${type}/${code}`);

      commit('toggleLoadingVoucher', false);

      return response;
    } catch (e) {
      commit('toggleLoadingVoucher', false);
      throw e;
    }
  },
  async checkIsShopifyCustomer(
    context: ActionContext<StateType, {}>,
  ): Promise<any> {
    const { commit } = context || {};

    try {
      const isShopifyCustomer = await Http.get('module/is-shopify-customer');

      commit('toggleIsShopifyCustomer', isShopifyCustomer);
    } catch {
      commit('toggleIsShopifyCustomer', false);
    }
  },
  async subscribeShopify(
    context: ActionContext<StateType, {}>,
    plan: any,
  ): Promise<any> {
    const { commit } = context || {};

    try {
      commit('toggleLoadingCheckout', true);
      return await Http.post('plan/subscribe-shopify', {
        plan,
      });
    } catch {
      commit('toggleLoadingCheckout', false);
      return null;
    }
  },
};
