import { RouteConfig } from 'vue-router';

export default {
  path: 'register/success',
  name: 'page-register-success',
  component: () => import('./PageRegisterSuccess.vue'),
  meta: {
    title: 'Cadastro realizado com sucesso',
  },
} as RouteConfig;
