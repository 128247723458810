import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleVariationLoading(state: StateType, isLoading: boolean): void {
    state.loadingVariation = isLoading;
  },
  setAttributes(state: StateType, attributes: any): void {
    state.attributes = attributes;
  },
  setTotal(state: StateType, total: number): void {
    state.total = total;
  },
};
