import { RouteConfig } from 'vue-router';

import PageBrands from './PageBrands.vue';
import PageManageBrand from './pages/manage-brand/PageManageBrand.vue';

export default {
  path: 'brands',
  children: [
    {
      path: '',
      name: 'brands',
      component: PageBrands,
      meta: {
        title: 'Marcas',
      },
    },
    {
      path: ':id?',
      name: 'manage-attribute',
      component: PageManageBrand,
      meta: {
        title: 'Gerenciar marca',
      },
    },
  ],
} as RouteConfig;
