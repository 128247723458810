import { RouteConfig } from 'vue-router';

export default {
  path: 'email-confirmation/:token',
  name: 'page-email-confirmation',
  component: () => import('./PageEmailConfirmation.vue'),
  meta: {
    title: 'Confirmação de e-mail',
  },
} as RouteConfig;
