import { ActionContext } from 'vuex';
import StateType from '@/features/private/integrations/store/state.type';
import { Http } from '@integrai/ui';

export default {
  async getClient(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    await Http.get('client')
      .then((client) => commit('setClient', client));
  },
  me(context: ActionContext<StateType, {}>): Promise<any> {
    return Http.get('auth/me');
  },
  async sendAccountVerificationEmail(
    context: ActionContext<StateType, {}>,
    id: string,
  ): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    return Http.post('/client/account-verification', { id })
      .then(() => commit('setEmailSent', true))
      .finally(() => commit('toggleLoading', false));
  },
  async searchClient(context: ActionContext<StateType, {}>, query: string): Promise<any> {
    return Http.get('auth/client-search', {
      params: {
        query,
      },
    });
  },
  async loginClient(context: ActionContext<StateType, {}>, id: string): Promise<any> {
    return Http.post('auth/client-login', {
      id,
    });
  },
  async subscribeShopifyPlan(
    context: ActionContext<StateType, {}>,
    data: any,
  ): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await Http.post(`plan/subscribe-shopify/${data.plan}/${data.chargeId}`);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
};
