<template>
  <ig-page-base :loading="loading">
    <div class="box integration-password__content">
      <h2 class="title integration-password__title has-text-weight-semibold">
        {{ $t('pagePassword.title') }}
      </h2>

      <ig-form-schema
        v-if="!loading"
        :schema="customSchema"
        @submit="save"
      >
        <template slot="actions">
          <b-button
            native-type="submit"
            class="is-uppercase integration-password__btn"
            :label="$t('pagePassword.btnSave')"
            :disabled="loading"
            :loading="loading"
            type="is-primary"
            expanded></b-button>
        </template>
      </ig-form-schema>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgFormSchema, EventBus } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('account');

export default Vue.extend({
  name: 'page-password',
  components: {
    IgPageBase,
    IgFormSchema,
  },
  computed: {
    customSchema() {
      return {
        fields: [
          {
            name: 'password',
            componentPrefix: 'ig',
            component: 'password',
            fieldAttrs: {
              label: this.$t('pagePassword.form.password.label'),
            },
            attrs: {
              placeholder: this.$t('pagePassword.form.password.placeholder'),
              icon: 'key',
              type: 'password',
              'password-reveal': true,
            },
            validation: {
              name: this.$t('pagePassword.form.password.label'),
              rules: 'required|min:8|numbers|letters',
              vid: 'password',
              'data-vv-as': this.$t('pagePassword.form.password.label'),
            },
          },
          {
            name: 'confirmPassword',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pagePassword.form.confirmPassword.label'),
            },
            attrs: {
              placeholder: this.$t('pagePassword.form.confirmPassword.placeholder'),
              icon: 'key',
              type: 'password',
              'password-reveal': true,
            },
            validation: {
              name: this.$t('pagePassword.form.confirmPassword.label'),
              customMessages: {
                confirmed: this.$t('pagePassword.form.confirmPassword.confirmed'),
              },
              rules: 'required|confirmed:password',
              'data-vv-as': this.$t('pagePassword.form.password.label'),
            },
          },
        ],
      };
    },
    ...mapState([
      'loading',
    ]),
  },
  methods: {
    ...mapActions([
      'updateAccount',
    ]),
    async save(account) {
      await this.updateAccount(account);
      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t('pagePassword.messageSuccess'),
      });
    },
  },
});
</script>

<style lang="scss">
  .integration-password{
    &__title{
      padding-bottom: 1rem;
    }
    &__content{
      width: 50%;
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
    &__btn {
      margin-top: 2rem;
    }
  }
</style>
