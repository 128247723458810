import { ActionContext } from 'vuex';
import { EventBus, Http } from '@integrai/ui';
import StateType from '@/features/private/settings/pages/account/store/state.type';

export default {
  async getEventsEnum(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('setEventsEnum', await Http.get('event-history/events'));
  },
  async getFlows(context: ActionContext<StateType, {}>, filters: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      commit('setFlows', await Http.post('flows/list', filters));
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async getFlow(context: ActionContext<StateType, {}>, row: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      commit('setFlow', await Http.get(`flows/${row.id}/${row.moduleTarget.id}/${row.event}`));
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async saveFlow(context: ActionContext<StateType, {}>, form: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoadingSave', true);

    try {
      await Http.post('flows', form);
      commit('toggleLoadingSave', false);

      EventBus.$emit('toast', {
        type: 'is-success',
        message: 'Fluxo salvo com sucesso',
      });
    } catch {
      commit('toggleLoadingSave', false);
    }
  },
  async removeFlow(context: ActionContext<StateType, {}>, form: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoadingSave', true);

    try {
      await Http.post('flows/delete', form);
      commit('toggleLoadingSave', false);
    } catch {
      commit('toggleLoadingSave', false);
    }
  },
  async getModules(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('setModules', await Http.get('module/clients'));
  },
};
