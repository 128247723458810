import { RouteConfig } from 'vue-router';

import PageMapping from './PageMapping.vue';

export default {
  path: 'mappings',
  name: 'mappings',
  component: PageMapping,
  meta: {
    title: 'Mapeamentos',
  },
} as RouteConfig;
