import { RouteConfig } from 'vue-router';

import PageIntegrationSteps from './PageIntegrationSteps.vue';

export default {
  path: 'integrations-detail/:id/:moduleConfigId?',
  name: 'integrations-detail',
  component: PageIntegrationSteps,
  meta: {
    title: 'Detalhe da integração',
  },
  params: {
    id: null,
    moduleConfigId: null,
  },
} as RouteConfig;
