<template>
  <section>
    <ig-form-schema
      v-if="!loading"
      :schema="formSchema"
      :model="boleto"
      @submit="form => $emit('save', form)"
    >
      <template slot="actions">
        <b-button
          native-type="submit"
          class="is-uppercase integration-account__btn"
          :label="$t('igFormPaymentBoleto.btnContinue')"
          :disabled="loading"
          :loading="loading"
          type="is-primary"
          expanded></b-button>
      </template>
    </ig-form-schema>
  </section>
</template>

<script>
import {
  IgFormSchema,
} from '@integrai/ui';

export default {
  name: 'ig-form-payment-boleto',
  props: {
    loading: {
      type: Boolean,
    },
    boleto: {
      type: Object,
    },
  },
  computed: {
    formSchema() {
      return {
        fields: [
          {
            name: 'dueDate',
            component: 'select',
            attrs: {
              expanded: true,
              placeholder: this.$t('igFormPaymentBoleto.dueDatePlaceholder'),
            },
            fieldAttrs: {
              label: this.$t('igFormPaymentBoleto.dueDate'),
            },
            validation: {
              name: this.$t('igFormPaymentBoleto.dueDate'),
              rules: 'required',
            },
            options: [
              {
                value: 1,
                label: `${this.$t('igFormPaymentBoleto.prefixDueDate')} 01`,
              },
              {
                value: 5,
                label: `${this.$t('igFormPaymentBoleto.prefixDueDate')} 05`,
              },
              {
                value: 10,
                label: `${this.$t('igFormPaymentBoleto.prefixDueDate')} 10`,
              },
              {
                value: 15,
                label: `${this.$t('igFormPaymentBoleto.prefixDueDate')} 15`,
              },
              {
                value: 20,
                label: `${this.$t('igFormPaymentBoleto.prefixDueDate')} 20`,
              },
              {
                value: 25,
                label: `${this.$t('igFormPaymentBoleto.prefixDueDate')} 25`,
              },
            ],
          },
        ],
      };
    },
  },
  components: {
    IgFormSchema,
  },
};
</script>
