import StateType, { ClientType, PlanStatusType } from '@/features/private/home/store/state.type';

export default {
  emailVerified(state: StateType): boolean | undefined {
    const { emailVerified } = state.client || {};

    return emailVerified;
  },
  usedFreeTier(state: StateType): boolean | undefined {
    const { planStatus = {} as PlanStatusType } = state.client || {} as ClientType;
    const { usedFreeTier = false } = planStatus || {};

    return usedFreeTier;
  },
};
