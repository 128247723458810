import { RouteConfig } from 'vue-router';

import PageSettings from './PageSettings.vue';
/* eslint-disable */
// @ts-ignore
import * as children from './pages/*/routes.ts';
/* eslint-enable */

export default {
  name: 'settings',
  path: 'settings',
  component: PageSettings,
  children,
} as RouteConfig;
