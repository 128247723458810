










import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase } from '@integrai/ui';

import IntegrationHeader from './components/IntegrationHeader.vue';
import IntegrationCards from './components/IntegrationCards.vue';
import IntegrationRequestForm from './components/IntegrationRequestForm.vue';
import IntegrationNewConfig from './components/IntegrationNewConfig.vue';

const { mapState, mapActions } = createNamespacedHelpers('integrations');

export default Vue.extend({
  name: 'page-integrations',
  data() {
    return {
      openNewConfig: null,
    };
  },
  components: {
    IgPageBase,
    IntegrationHeader,
    IntegrationCards,
    IntegrationRequestForm,
    IntegrationNewConfig,
  },
  computed: {
    ...mapState([
      'loading',
    ]),
  },
  methods: {
    ...mapActions([
      'getIntegrations',
    ]),
  },
  async beforeMount(): Promise<void> {
    await Promise.all([
      this.getIntegrations(),
    ]);
  },
});
