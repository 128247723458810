









































import Vue from 'vue';
import { Auth } from '@integrai/ui';

export default Vue.extend({
  name: 'page-store',
  computed: {
    hasStore() {
      return Auth.getUser().hasStore;
    },
    menus() {
      return [
        {
          label: this.$t('storeMenu.groupProduct'),
          children: [
            // {
            //   pathName: 'products',
            //   label: this.$t('storePages.product.products.menuTitle'),
            // },
            {
              pathName: 'product-import-and-export',
              label: this.$t('storePages.product.importAndExport.menuTitle'),
            },
            // {
            //   pathName: 'brands',
            //   label: this.$t('storePages.product.brands.menuTitle'),
            // },
            // {
            //   pathName: 'categories',
            //   label: this.$t('storePages.product.categories.menuTitle'),
            // },
            // {
            //   pathName: 'attributes',
            //   label: this.$t('storePages.product.attributes.menuTitle'),
            // },
          ],
        },
        // {
        //   label: this.$t('storeMenu.groupOrders'),
        //   children: [
        //     {
        //       pathName: 'orders',
        //       label: this.$t('storePages.orders.title'),
        //     },
        //   ],
        // },
      ];
    },
  },
  methods: {
    isActive(pathName: string): boolean {
      return this.$route.name === pathName;
    },
  },
});
