import { ActionContext, Commit } from 'vuex';
import { Http } from '@integrai/ui';

import { RegisterParams, StateType } from './store.types';

export default {
  async register(context: ActionContext<StateType, Commit>, form: RegisterParams) {
    const { commit, dispatch } = context || {};

    commit('toggleLoading', true);

    try {
      await Http.post('/auth/register', form);

      await dispatch('login/login', {
        email: form.email,
        password: form.password,
      }, { root: true });

      commit('toggleLoading', false);
    } catch (e) {
      commit('toggleLoading', false);
      throw e;
    }
  },
  async getPartners(context: ActionContext<StateType, Commit>) {
    const { commit } = context || {};
    commit('setPartners', await Http.get('/auth/partners'));
  },
  async oauth(context: ActionContext<StateType, Commit>, code: string) {
    return Http.post('/auth/oauth', {
      code,
    });
  },
};
