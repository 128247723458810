<template>
  <ig-modal @close="$emit('close')">
    <b-tabs
      class="ig-checkout-tabs"
      v-model="activeTab">
      <b-tab-item>
        <ig-payment-methods @changeTab="tab => activeTab = tab" />
      </b-tab-item>
      <b-tab-item>
        <ig-payment-method-info @changeTab="tab => activeTab = tab" />
      </b-tab-item>
      <b-tab-item>
        <ig-payment-client-info
          @changeTab="tab => activeTab = tab"
          @finish="finish"
        />
      </b-tab-item>
      <b-tab-item>
        <ig-checkout-success :boleto-url="boletoUrl" />
      </b-tab-item>
    </b-tabs>
  </ig-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { IgModal, EventBus } from '@integrai/ui';
import IgPaymentMethods from '@/features/private/plan/components/IgPaymentMethods.vue';
import IgPaymentMethodInfo from '@/features/private/plan/components/IgPaymentMethodInfo.vue';
import IgPaymentClientInfo from '@/features/private/plan/components/IgPaymentClientInfo.vue';
import IgCheckoutSuccess from '@/features/private/plan/components/IgCheckoutSuccess.vue';

const { mapState, mapActions } = createNamespacedHelpers('plans');

export default {
  name: 'ig-checkout-modal',
  props: {
    hasProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 0,
      boletoUrl: '',
    };
  },
  computed: {
    ...mapState([
      'checkout',
    ]),
  },
  methods: {
    ...mapActions([
      'subscribe',
      'buyProduct',
    ]),
    async finish() {
      if (this.checkout.product) {
        return this.finishBuyProduct();
      }

      return this.finishSubscribe();
    },
    async finishSubscribe() {
      const response = await this.subscribe();
      this.boletoUrl = response.boletoUrl;

      if (this.checkout.plan.price === 0) {
        EventBus.$emit('toast', {
          type: 'is-success',
          message: this.$t('pageCheckout.messageSuccess'),
        });

        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      } else {
        this.activeTab = 3;
      }
    },
    async finishBuyProduct() {
      const response = await this.buyProduct();
      this.boletoUrl = response.boletoUrl;
      this.activeTab = 3;
    },
  },
  beforeMount() {
    this.boletoUrl = null;

    if (this.checkout.plan && this.checkout.plan.price === 0) {
      this.activeTab = 2;
    }
  },
  components: {
    IgModal,
    IgPaymentMethods,
    IgPaymentMethodInfo,
    IgPaymentClientInfo,
    IgCheckoutSuccess,
  },
};
</script>

<style lang="scss">
  .ig-checkout-tabs{
    .tabs{
      display: none;
    }
    .tab-content{
      padding: 0;
      .tab-item{
        outline: none;
      }
    }
  }
</style>
