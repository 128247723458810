<template>
  <div>
    <b-message
      class="integration-request-form__message"
      type="is-info"
      icon="info-circle"
      icon-size="is-small"
      has-icon>
      {{ $t('pageIntegration.requestForm.message') }}

      <a
        @click="showForm = true"
        class="is-primary is-text has-text-weight-bold">
        {{ $t('pageIntegration.requestForm.linkText') }}
      </a>
    </b-message>

    <b-modal
      v-model="showForm"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal>
      <template>
        <ig-modal @close="showForm = false">
          <ig-external-form :id="1" @finish="showForm = false" />
        </ig-modal>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { IgModal } from '@integrai/ui';
import IgExternalForm from './IgExternalForm.vue';

export default {
  name: 'integration-request-form',
  data() {
    return {
      showForm: false,
    };
  },
  components: {
    IgModal,
    IgExternalForm,
  },
};
</script>

<style lang="scss">
.integration-request-form {
  &__message{
    margin-top: 2rem;
  }
}
</style>
