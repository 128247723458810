import { ActionContext } from 'vuex';
import { HttpStore } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getProducts(context: ActionContext<StateType, {}>, filters: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.post('products/filter', filters);

      commit('setProducts', response.products);
      commit('setTotal', response.total);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
};
