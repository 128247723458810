<template>
  <section>
    <div v-if="checkout.plan && checkout.plan.price === 0">
      <h2 class="ig-payment-info__title">
        {{ $t('igPaymentClientInfo.downgradeFree.title') }}
      </h2>

      <p class="content is-size-7">
        {{ $t('igPaymentClientInfo.downgradeFree.info') }}
      </p>

      <p
        class="content is-size-7"
        v-html="$t('igPaymentClientInfo.downgradeFree.restrictions')"></p>

      <b-button
        @click="cancel"
        class="is-uppercase"
        :label="$t('igPaymentClientInfo.downgradeFree.btnSave')"
        type="is-danger"
        :loading="loadingCheckout"
        :disabled="loadingCheckout"
        expanded></b-button>
    </div>
    <div v-else>
      <h2 class="ig-payment-info__title">
        {{ $t('igPaymentClientInfo.title') }}

        <b-button
          class="ig-payment-info__btn-back"
          @click="back">
          <b-icon icon="arrow-alt-circle-left"></b-icon>
        </b-button>
      </h2>

      <div class="ig-payment-info__method-form">
        <ig-payment-client-info-form
          :external-loading="loadingCheckout"
          @save="save"/>
      </div>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import IgPaymentClientInfoForm
  from '@/components/ig-payment-client-info-form/IgPaymentClientInfoForm.vue';

const { mapState, mapMutations } = createNamespacedHelpers('plans');

export default {
  name: 'ig-payment-client-info',
  computed: {
    ...mapState([
      'loadingCheckout',
      'methods',
      'checkout',
    ]),
    method() {
      return this.methods
        .find((method) => method.value === this.checkout.method);
    },
  },
  methods: {
    ...mapMutations([
      'setCheckout',
    ]),
    back() {
      this.$emit('changeTab', 1);
    },
    save(clientInfo) {
      this.setCheckout({
        clientInfo,
      });
      this.$emit('finish');
    },
    cancel() {
      this.$emit('finish');
    },
  },
  components: {
    IgPaymentClientInfoForm,
  },
};
</script>

<style lang="scss">
.ig-payment-info{
  &__title{
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    margin-right: 4rem;
    @media screen and (max-width: 760px) {
      font-size: 1rem;
      margin-right: 0;
      margin-top: 0.3rem;
      text-align: center;
    }
  }
  &__btn-back{
    padding: 0;
    border: 0;
    font-size: 1.2rem;
    position: absolute;
    left: -25px;
    top: 1px;
    height: auto;
    color: rgba(10,10,10,.2);
    @media screen and (max-width: 760px) {
      left: 10px;
      top: 4px;
    }
  }
}
</style>
