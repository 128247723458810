import StateType from './state.type';

export default {
  loading: false,
  loadingCheckout: false,
  loadingVoucher: false,
  isShopifyCustomer: false,
  plans: [],
  actualPlan: {},
  checkout: {},
  methods: [],
} as StateType;
