import { ActionContext } from 'vuex';
import { HttpStore } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getBrands(context: ActionContext<StateType, {}>, filters: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.post('brands/filter', filters);

      commit('setBrands', response.brands);
      commit('setTotal', response.total);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async getBrand(context: ActionContext<StateType, {}>, id: any): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.get(`brands/${id}`);

      commit('toggleLoading', false);
      return response;
    } catch (err) {
      commit('toggleLoading', false);
      throw err;
    }
  },
  async getPresignedUrlPhoto(
    context: ActionContext<StateType, {}>,
    fileName: string,
  ): Promise<{ logo: string; presignedUrl: string }> {
    const response: any = await HttpStore.get(`brands/presigned-url?fileName=${encodeURIComponent(fileName)}`);
    return {
      presignedUrl: response.presignedUrl,
      logo: response.logo,
    };
  },
  async createBrand(context: ActionContext<StateType, {}>, form: any): Promise<void> {
    const { commit } = context || {};

    try {
      await HttpStore.post('brands', form);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async updateBrand(context: ActionContext<StateType, {}>, data: any): Promise<void> {
    const { id, form } = data;
    const { commit } = context || {};

    try {
      await HttpStore.put(`brands/${id}`, form);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async removeBrand(context: ActionContext<StateType, {}>, id: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await HttpStore.delete(`brands/${id}`);
      commit('toggleLoading', false);
    } catch (err) {
      commit('toggleLoading', false);
      throw err;
    }
  },
};
