import { ActionContext } from 'vuex';
import { HttpStore } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getCategories(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const categories: any = await HttpStore.get('categories');

      commit('setCategories', categories);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async getCategory(context: ActionContext<StateType, {}>, id: any): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.get(`categories/${id}`);

      commit('toggleLoading', false);
      return response;
    } catch (err) {
      commit('toggleLoading', false);
      throw err;
    }
  },
  async createCategory(context: ActionContext<StateType, {}>, form: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await HttpStore.post('categories', form);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async updateCategory(context: ActionContext<StateType, {}>, data: any): Promise<void> {
    const { id, form } = data;
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await HttpStore.put(`categories/${id}`, form);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async removeCategory(context: ActionContext<StateType, {}>, id: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await HttpStore.delete(`categories/${id}`);
      commit('toggleLoading', false);
    } catch (err) {
      commit('toggleLoading', false);
      throw err;
    }
  },
};
