import { RouteConfig } from 'vue-router';

import PageIntegrationAuth from './PageIntegrationAuth.vue';

export default {
  path: 'integration-detail/:id/auth',
  name: 'integration-detail-auth',
  component: PageIntegrationAuth,
  params: {
    id: null,
  },
  meta: {
    title: 'Integrações - Autenticação',
  },
} as RouteConfig;
