import { RouteConfig } from 'vue-router';

import PagePassword from './PagePassword.vue';

export default {
  path: 'password',
  name: 'password',
  component: PagePassword,
  meta: {
    title: 'Alterar senha',
  },
} as RouteConfig;
