import { ActionContext } from 'vuex';
import { HttpStore } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getAttributes(context: ActionContext<StateType, {}>, filters: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.post('product-attributes/filter', filters);

      commit('setAttributes', response.attributes);
      commit('setTotal', response.total);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async getAttribute(context: ActionContext<StateType, {}>, id: any): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.get(`product-attributes/${id}`);

      commit('toggleLoading', false);
      return response;
    } catch (err) {
      commit('toggleLoading', false);
      throw err;
    }
  },
  async createAttribute(context: ActionContext<StateType, {}>, form: any): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const attribute = await HttpStore.post('product-attributes', form);
      commit('toggleLoading', false);
      return attribute;
    } catch {
      commit('toggleLoading', false);
      return null;
    }
  },
  async updateAttribute(context: ActionContext<StateType, {}>, data: any): Promise<void> {
    const { id, form } = data;
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await HttpStore.put(`product-attributes/${id}`, form);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async removeAttribute(context: ActionContext<StateType, {}>, id: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await HttpStore.delete(`product-attributes/${id}`);
      commit('toggleLoading', false);
    } catch (err) {
      commit('toggleLoading', false);
      throw err;
    }
  },
  async createVariation(context: ActionContext<StateType, {}>, data: any): Promise<void> {
    const { attributeId, form } = data;
    const { commit } = context || {};

    commit('toggleVariationLoading', true);

    try {
      await HttpStore.post(`product-attributes/${attributeId}/variation`, form);
      commit('toggleVariationLoading', false);
    } catch {
      commit('toggleVariationLoading', false);
    }
  },
  async updateVariation(context: ActionContext<StateType, {}>, data: any): Promise<void> {
    const { attributeId, id, form } = data;
    const { commit } = context || {};

    commit('toggleVariationLoading', true);

    try {
      await HttpStore.put(`product-attributes/${attributeId}/variation/${id}`, form);
      commit('toggleVariationLoading', false);
    } catch {
      commit('toggleVariationLoading', false);
    }
  },
  async removeVariation(context: ActionContext<StateType, {}>, data: any): Promise<void> {
    const { attributeId, id } = data;
    const { commit } = context || {};

    commit('toggleVariationLoading', true);

    try {
      await HttpStore.delete(`product-attributes/${attributeId}/variation/${id}`);
      commit('toggleVariationLoading', false);
    } catch (err) {
      commit('toggleVariationLoading', false);
      throw err;
    }
  },
};
