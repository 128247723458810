import StateType from './state.type';

export default {
  loading: false,
  loadingSave: false,
  form: {
    categorySelected: 'all',
    query: null,
  },
  categories: [],
  modules: [],
  modulesList: [],
  module: {},
  client: {},
  actualStep: 1,
} as StateType;
