<template>
  <ig-page-base>
    <div class="box orders__content">
      <div class="product-import-and-export__title-box">
        <h2 class="title product-import-and-export__title has-text-weight-semibold">
          {{ $t('storePages.orders.title') }}
        </h2>
      </div>

      <div class="orders__filters">
        <div class="columns">
          <div class="column is-6">
            <form @submit.prevent="getOrderList()">
              <b-field grouped>
                <b-input
                  v-model="filters.query"
                  :placeholder="$t('storePages.product.products.filters.query')"
                  expanded />
                <p class="control">
                  <b-button
                    native-type="submit"
                    type="is-primary"
                    :label="$t('storePages.product.products.filters.btnSearch')"
                    :loading="loading"
                    :disabled="loading"
                  ></b-button>
                </p>
              </b-field>
            </form>
          </div>
          <div class="column has-text-right">
            <b-button
              type="is-primary"
              icon-left="filter"
              outlined
              :label="$t('storePages.product.products.filters.btnFilters')"
              :disabled="loading"
              @click="showFilters = true"
            ></b-button>

            <ig-sidebar-filters
              v-model="showFilters"
              v-slot="{ filters }"
              @submit="applyFilters"
            >
              <b-field
                :label="$t('storePages.product.products.filters.enabled.label')"
                label-position="inside">

                <b-select
                  v-model="filters.enabled"
                  :placeholder="$t('storePages.product.products.filters.enabled.options.all')"
                  expanded>
                  <option :value="undefined">
                    {{ $t('storePages.product.products.filters.enabled.options.all') }}
                  </option>
                  <option :value="true">
                    {{ $t('storePages.product.products.filters.enabled.options.active') }}
                  </option>
                  <option :value="false">
                    {{ $t('storePages.product.products.filters.enabled.options.inactive') }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                :label="$t('storePages.product.products.filters.type.label')"
                label-position="inside">
                <b-select v-model="filters.type" expanded>
                  <option :value="null">
                    {{ $t('storePages.product.products.filters.type.options.all') }}
                  </option>
                  <option value="SIMPLE">
                    {{ $t('storePages.product.products.filters.type.options.simple') }}
                  </option>
                  <option value="CONFIGURABLE">
                    {{ $t('storePages.product.products.filters.type.options.configurable') }}
                  </option>
                </b-select>
              </b-field>
            </ig-sidebar-filters>
          </div>
        </div>
      </div>

      <hr />

      <b-table
        :loading="loading"
        :data="orders"
        backend-sorting
        default-sort="createdAt"
        default-sort-direction="desc"
        paginated
        pagination-simple
        backend-pagination
        @page-change="onPageChange"
        :total="total"
        :per-page="filters.limit"
        :current-page.sync="filters.page"
        @sort="sort"
        @click="row => editOrder(row.id)"
        striped
        hoverable
        scrollable
      >
        <template #empty>
          <ig-empty />
        </template>

        <template #bottom-left>
          <ig-per-page
            :total-displayed="orders.length"
            :total="total"
            :perPage="filters.limit"
            @change="changePerPage"
          />
        </template>

        <b-table-column
          :label="$t('storePages.orders.table.status')"
          sortable
        >
          <template v-slot="props">
            <b-tag :type="getStatusType(props.row.status)">
              {{ getStatusLabel(props.row.status) }}
            </b-tag>
          </template>
        </b-table-column>

        <b-table-column
          :label="$t('storePages.orders.table.createdAt')"
          sortable
        >
          <template v-slot="props">
            {{ getFormattedDate(props.row.createdAt) }}
          </template>
        </b-table-column>

        <b-table-column
          width="200"
          :label="$t('storePages.orders.table.customer')"
          sortable
        >
          <template v-slot="props">
            {{ props.row.customer.name || '-' }}
          </template>
        </b-table-column>

        <b-table-column
          :label="$t('storePages.orders.table.paymentMethod')"
        >
          <template v-slot="props">
            {{ getPaymentMethodLabel(props.row.payment[0].method) }}
          </template>
        </b-table-column>

        <b-table-column
          :label="$t('storePages.orders.table.price')"
        >
          <template v-slot="props">
            {{ getFormattedPrice(props.row.invoice.value) }}
          </template>
        </b-table-column>
      </b-table>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import * as Moment from 'moment';

import {
  IgPageBase,
  IgEmpty,
  IgSidebarFilters,
  IgPerPage,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('orders');

export default Vue.extend({
  name: 'page-orders',
  components: {
    IgPageBase,
    IgEmpty,
    IgSidebarFilters,
    IgPerPage,
  },
  data() {
    return {
      showFilters: false,
      filters: {
        limit: 10,
        page: 1,
      },
    };
  },
  computed: {
    ...mapState([
      'loading',
      'orders',
      'total',
    ]),
  },
  methods: {
    ...mapActions([
      'getOrders',
    ]),
    getFormattedPrice(price) {
      if (price !== undefined) {
        return price.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        });
      }

      return '-';
    },
    getPaymentMethodLabel(method) {
      const labels = {
        BOLETO: 'Boleto',
        CREDIT_CARD: 'Cartão de Cŕedito',
        PIX: 'Pix',
      };

      return labels[method] || '-';
    },
    getStatusLabel(status) {
      const labels = {
        pending: 'Pendente',
        processing: 'Processando',
        authorized: 'Autorizado',
        paid: 'Pago',
        invoiced: 'Faturado',
        shipped: 'Enviado',
        delivered: 'Entregue',
        refunded: 'Reembolso',
        refused: 'Recusado',
        canceled: 'Cancelado',
      };

      return labels[status] || '-';
    },
    getStatusType(status) {
      const types = {
        pending: 'is-warning',
        processing: 'is-default',
        authorized: 'is-success',
        paid: 'is-success',
        invoiced: 'is-success',
        shipped: 'is-success',
        delivered: 'is-success',
        refunded: 'is-warning',
        refused: 'is-danger',
        canceled: 'is-danger',
      };

      return types[status] || 'is-default';
    },
    getFormattedDate(date) {
      return Moment(date).format('DD/MM/YYYY HH:mm');
    },
    sort(sortField, sortOrder) {
      Object.assign(this.filters, {
        sortField,
        sortOrder,
      });

      this.getOrderList();
    },
    onPageChange(page) {
      Object.assign(this.filters, {
        page,
      });

      this.getOrderList();
    },
    changePerPage(limit) {
      Object.assign(this.filters, {
        limit,
      });

      this.getOrderList();
    },
    applyFilters(filters) {
      this.getOrderList({
        ...this.filters,
        ...filters || {},
      });
    },
    async getOrderList(filters) {
      await this.getOrders(filters || this.filters);
    },
    editOrder(orderId) {
      this.$router.push({
        name: 'orderDetail',
        params: {
          id: orderId,
        },
      });
    },
  },
  async beforeMount() {
    console.log('mounted: ');
    await this.getOrderList();
  },
});
</script>

<style lang="scss">
  .orders {
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__line-through {
      text-decoration: line-through;
    }
  }
</style>
