import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleLoadingSave(state: StateType, isLoading: boolean): void {
    state.loadingSave = isLoading;
  },
  setFlows(state: StateType, flows: any[]): void {
    state.flows = flows;
  },
  setFlow(state: StateType, flow: any): void {
    state.flow = flow;
  },
  setModules(state: StateType, modules: any[]): void {
    state.modules = modules;
  },
  setEventsEnum(state: StateType, eventsEnum: any[]): void {
    state.eventsEnum = eventsEnum;
  },
};
