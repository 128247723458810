import { ActionContext, Commit } from 'vuex';
import { Http } from '@integrai/ui';
import { ResetPasswordParams, StateType } from '../types/StateType';

export default {
  forgotPassword(context: ActionContext<StateType, Commit>, form: ResetPasswordParams) {
    const { commit } = context || {};

    commit('toggleLoading', true);

    return Http.post('/auth/forgot-password', form)
      .finally(() => commit('toggleLoading', false));
  },
};
