














import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('orderDetail');

export default Vue.extend({
  name: 'page-order-detail',
  components: {
    IgPageBase,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState([
      'loading',
      'order',
    ]),
  },
  methods: {
    ...mapActions([
      'getOrder',
    ]),
  },
  async beforeMount() {
    const { id } = this.$route.params;
    await this.getOrder(id);
  },
});
