import { ActionContext } from 'vuex';
import { HttpStore } from '@integrai/ui';
import StateType from '@/features/private/settings/pages/account/store/state.type';

export default {
  async downloadExcelModel(context: ActionContext<StateType, {}>): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response = await HttpStore.request({
        url: 'product-file/download',
        method: 'GET',
        responseType: 'blob',
      });

      commit('toggleLoading', false);
      return response;
    } catch {
      commit('toggleLoading', false);
    }
    return null;
  },
  async getPresignedUrl(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('setPresignedUrl', await HttpStore.get('product-file/presigned-url'));
  },
  async getPresignedUrlPhoto(
    context: ActionContext<StateType, {}>,
    fileName: string,
  ): Promise<any> {
    return HttpStore.get(`product-file/presigned-url/photo?fileName=${fileName}`);
  },
};
