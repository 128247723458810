import { RouteConfig } from 'vue-router';

import PageFlow from './PageFlow.vue';

export default {
  path: 'flows',
  name: 'flows',
  component: PageFlow,
  meta: {
    title: 'Fluxos',
  },
} as RouteConfig;
