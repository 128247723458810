import { RouteConfig } from 'vue-router';

import PageAttributes from './PageAttributes.vue';
import PageManageAttribute from './pages/manage-attribute/PageManageAttribute.vue';

export default {
  path: 'attributes',
  children: [
    {
      path: '',
      name: 'attributes',
      component: PageAttributes,
      meta: {
        title: 'Atributos',
      },
    },
    {
      path: ':id?',
      name: 'manage-attribute',
      component: PageManageAttribute,
      meta: {
        title: 'Gerenciar atributo',
      },
    },
  ],
} as RouteConfig;
