import { RouteConfig } from 'vue-router';

import PageProductImportAndExport from './PageProductImportAndExport.vue';

export default {
  path: 'import-and-export',
  name: 'product-import-and-export',
  component: PageProductImportAndExport,
  meta: {
    title: 'Importar produtos',
  },
} as RouteConfig;
