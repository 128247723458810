import { RouteConfig } from 'vue-router';

import PageProducts from './PageProducs.vue';

export default {
  path: 'list',
  name: 'products',
  component: PageProducts,
  meta: {
    title: 'Produtos',
  },
} as RouteConfig;
