<template>
  <ig-page-base>
    <div class="box brands__content">
      <div class="brands__title-box">
        <h2 class="title brands__title has-text-weight-semibold">
          {{ $t('storePages.product.brands.title') }}
        </h2>

        <b-button
          type="is-success"
          tag="router-link"
          :to="{ name: 'manage-attribute' }"
          :label="$t('storePages.product.brands.btnNewBrand')"
        ></b-button>
      </div>

      <div class="brands__filters">
        <div class="columns">
          <div class="column is-6">
            <form @submit.prevent="getBrandsList()">
              <b-field grouped>
                <b-input
                  v-model="filters.query"
                  :placeholder="$t('storePages.product.brands.filters.query')"
                  expanded />
                <p class="control">
                  <b-button
                    native-type="submit"
                    type="is-primary"
                    :label="$t('storePages.product.brands.filters.btnSearch')"
                    :loading="loading"
                    :disabled="loading"
                  ></b-button>
                </p>
              </b-field>
            </form>
          </div>
          <div class="column has-text-right">
            <b-button
              type="is-primary"
              icon-left="filter"
              outlined
              :label="$t('storePages.product.brands.filters.btnFilters')"
              :disabled="loading"
              @click="showFilters = true"
            ></b-button>

            <ig-sidebar-filters
              v-model="showFilters"
              v-slot="{ filters }"
              @submit="applyFilters"
            >
              <b-field
                :label="$t('storePages.product.brands.filters.enabled.label')"
                label-position="inside">

                <b-select
                  v-model="filters.enabled"
                  :placeholder="$t('storePages.product.brands.filters.enabled.options.all')"
                  expanded>
                  <option :value="undefined">
                    {{ $t('storePages.product.brands.filters.enabled.options.all') }}
                  </option>
                  <option :value="true">
                    {{ $t('storePages.product.brands.filters.enabled.options.active') }}
                  </option>
                  <option :value="false">
                    {{ $t('storePages.product.brands.filters.enabled.options.inactive') }}
                  </option>
                </b-select>
              </b-field>
            </ig-sidebar-filters>
          </div>
        </div>
      </div>

      <hr />

      <b-table
        :loading="loading"
        :data="brands"
        backend-sorting
        default-sort="name"
        default-sort-direction="desc"
        paginated
        pagination-simple
        backend-pagination
        @page-change="onPageChange"
        :total="total"
        :per-page="filters.limit"
        :current-page.sync="filters.page"
        @sort="sort"
        @click="row => editBrand(row.id)"
        striped
        hoverable
        scrollable
        :row-class="() => 'brands__table-row'"
      >
        <template #empty>
          <ig-empty />
        </template>

        <template #bottom-left>
          <ig-per-page
            :total-displayed="brands.length"
            :total="total"
            :perPage="filters.limit"
            @change="changePerPage"
          />
        </template>

        <b-table-column
          field="name"
          :label="$t('storePages.product.brands.table.header.brand')"
          v-slot="props"
          sortable
        >
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <img
                  :src="props.row.logo
                  ? props.row.logo : 'https://assets.integrai.com.br/store/no_image.png'"
                  :alt="props.row.name">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>{{ props.row.name }}</strong>
                </p>
              </div>
            </div>
          </article>
        </b-table-column>

        <b-table-column
          field="enabled"
          :label="$t('storePages.product.brands.table.header.status')"
          centered
          sortable
          v-slot="props"
        >
          <span class="tag is-uppercase" :class="{
          'is-success': props.row.enabled,
          'is-danger':  !props.row.enabled,
          }">
            {{ $t(`storePages.product.brands.table.data.statusEnum.${props.row.enabled}`) }}
          </span>
          {{ props.row.status }}
        </b-table-column>

        <b-table-column
          width="100"
          v-slot="props"
          custom-key="actions"
        >
          <div class="buttons">
            <b-tooltip
              append-to-body
              :label="$t('storePages.product.brands.table.data.actions.edit')">
              <b-button
                class="brands__btn-action"
                type="is-default is-light"
                icon-left="pen"
                size="is-small"
                @click.stop="editBrand(props.row.id)">
              </b-button>
            </b-tooltip>
            <b-tooltip
              append-to-body
              :label="$t('storePages.product.brands.table.data.actions.delete')">
              <b-button
                class="brands__btn-action"
                type="is-danger is-light"
                icon-left="trash"
                size="is-small"
                @click.stop="deleteBrand(props.row)">
              </b-button>
            </b-tooltip>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase,
  IgEmpty,
  IgSidebarFilters,
  IgPerPage, EventBus,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('brands');

export default Vue.extend({
  name: 'page-brands',
  components: {
    IgPageBase,
    IgEmpty,
    IgSidebarFilters,
    IgPerPage,
  },
  data() {
    return {
      showFilters: false,
      filters: {
        limit: 10,
        page: 1,
      },
    };
  },
  computed: {
    ...mapState([
      'loading',
      'brands',
      'total',
    ]),
  },
  methods: {
    ...mapActions([
      'getBrands',
      'removeBrand',
    ]),
    sort(sortField, sortOrder) {
      Object.assign(this.filters, {
        sortField,
        sortOrder,
      });

      this.getBrandsList();
    },
    onPageChange(page) {
      Object.assign(this.filters, {
        page,
      });

      this.getBrandsList();
    },
    changePerPage(limit) {
      Object.assign(this.filters, {
        limit,
      });

      this.getBrandsList();
    },
    applyFilters(filters) {
      this.getBrandsList({
        ...this.filters,
        ...filters || {},
      });
    },
    async getBrandsList(filters) {
      await this.getBrands(filters || this.filters);
    },
    editBrand(id) {
      return this.$router.push({
        name: 'manage-attribute',
        params: {
          id,
        },
      });
    },
    deleteBrand(row) {
      return this.$buefy.dialog.confirm({
        title: String(this.$t('storePages.product.brands.deleteDialog.title')),
        message: String(this.$t('storePages.product.brands.deleteDialog.message', {
          name: row.name,
        })),
        confirmText: String(this.$t('storePages.product.brands.deleteDialog.confirm')),
        cancelText: String(this.$t('storePages.product.brands.deleteDialog.cancel')),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.removeBrand(row.id);
          await this.getBrandsList();

          EventBus.$emit('toast', {
            type: 'is-success',
            message: this.$t('storePages.product.brands.deleteDialog.successMessage'),
          });
        },
      });
    },
  },
  async beforeMount() {
    await this.getBrandsList();
  },
});
</script>

<style lang="scss">
  .brands{
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__table-row{
      cursor: pointer;
      .brands__btn-action{
        visibility: hidden;
      }
      &:hover{
        .brands__btn-action{
          visibility: visible;
        }
      }
    }
  }
</style>
