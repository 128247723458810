import { RouteConfig } from 'vue-router';

import PagePlanAndConsumption from './PagePlanAndConsumption.vue';

export default {
  path: 'plan-and-consumption',
  name: 'plan-and-consumption',
  component: PagePlanAndConsumption,
  meta: {
    title: 'Plano e Consumo',
  },
} as RouteConfig;
