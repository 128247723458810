import { StateType, FormType } from './store.types';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setPartners(state: StateType, partners: any[]): void {
    state.partners = partners;
  },
  setForm(state: StateType, form: FormType): void {
    state.form = {
      ...state.form,
      ...form,
    };
  },
};
