import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setClientInfo(state: StateType, clientInfo: any): void {
    state.clientInfo = clientInfo;
  },
};
