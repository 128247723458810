import StateType, { OrderType } from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setOrder(state: StateType, order: OrderType): void {
    state.order = order;
  },
};
