import { ActionContext } from 'vuex';
import { EventBus, Http } from '@integrai/ui';
import StateType from '@/features/private/settings/pages/account/store/state.type';

export default {
  async searchMappings(context: ActionContext<StateType, {}>, filters: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      commit('setMappings', await Http.post('mappings/list', filters));
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async createMapping(context: ActionContext<StateType, {}>, form: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoadingSave', true);

    try {
      await Http.post('mappings', form);
      commit('toggleLoadingSave', false);

      EventBus.$emit('toast', {
        type: 'is-success',
        message: 'Mapeamento salvo com sucesso',
      });
    } catch {
      commit('toggleLoadingSave', false);
    }
  },
  async removeMapping(context: ActionContext<StateType, {}>, id: string): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoadingSave', true);

    try {
      await Http.delete(`mappings/${id}`);
      commit('toggleLoadingSave', false);
    } catch {
      commit('toggleLoadingSave', false);
    }
  },
  async getTypes(context: ActionContext<StateType, {}>): Promise<any> {
    const { commit } = context || {};
    commit('setTypes', await Http.get('mappings/types'));
  },
  async getModuleConfigs(context: ActionContext<StateType, {}>, params: any): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    const moduleConfigs = await Http.get('mappings/module-configs', { params });

    commit('toggleLoading', false);

    return moduleConfigs;
  },
};
