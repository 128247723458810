<template>
  <ig-page-base>
    <div class="box product-import-and-export__content">
      <div class="product-import-and-export__title-box">
        <h2 class="title product-import-and-export__title has-text-weight-semibold">
          {{ $t('storePages.product.importAndExport.title') }}
        </h2>
      </div>

      <b-steps
        class="product-import-and-export__steps"
        v-model="activeStep"
        :has-navigation="false">
        <b-step-item
          visible
          :label="$t('storePages.product.importAndExport.steps.photo.title')">
          <div class="product-import-and-export__file-box">
            <div class="product-import-and-export__drop-box">
              <ig-file-upload
                ref="imagesUpload"
                :labelIdle="$t('storePages.product.importAndExport.steps.photo.dropLabel')"
                acceptedFileTypes="image/*"
                maxFileSize="1MB"
                :server="serverPhoto"
                :maxFiles="100"
                :allowImagePreview="false"
                :allowReplace="false"
                :allowRevert="false"
                :allowReorder="false"
                allowMultiple
                credits=""
                @addfilestart="disableSkip = true"
                @processfilestart="disableSkip = true"
                @processfiles="nextStep"
              />
            </div>
            <div class="has-text-right mt-6">
              <b-button
                size="is-small"
                type="is-primary"
                :disabled="disableSkip"
                @click="nextStep(0)"
              >Pular</b-button>
            </div>
          </div>
        </b-step-item>
        <b-step-item
          visible
          :label="$t('storePages.product.importAndExport.steps.spreadsheet.title')">
          <div class="product-import-and-export__file-box" v-if="activeStep === 1">
            <div class="product-import-and-export__drop-box">
              <ig-file-upload
                ref="fileUpload"
                :labelIdle="$t('storePages.product.importAndExport.steps.spreadsheet.dropLabel')"
                :acceptedFileTypes="[
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ]"
                maxFileSize="5MB"
                :server="serverExcel"
                :allowReplace="false"
                :allowRevert="false"
                :allowReorder="false"
                credits=""
                @processfile="nextStep"
              />

              <div class="has-text-right mt-6">
                <b-button
                  type="is-text"
                  icon-left="upload"
                  @click="chooseFile"
                >
                  {{ $t('storePages.product.importAndExport.steps.spreadsheet.btnChooseFile') }}
                </b-button>

                <b-button
                  type="is-text"
                  icon-left="download"
                  :loading="loading"
                  :disabled="loading"
                  @click="download"
                >
                  {{ $t('storePages.product.importAndExport.steps.spreadsheet.btnDownloadFile') }}
                </b-button>
              </div>
            </div>
          </div>
        </b-step-item>
        <b-step-item
          visible
          :label="$t('storePages.product.importAndExport.steps.success.title')">
          <section class="product-import-and-export__box-success">
            <h2 class="title has-text-centered product-import-and-export__box-success__title">
              <b-icon icon="check-circle"></b-icon>
              {{ $t('storePages.product.importAndExport.steps.success.subTitle') }}
            </h2>

            <p class="content is-size-7 has-text-centered
              product-import-and-export__box-success__info">
            <span
              v-html="$t('storePages.product.importAndExport.steps.success.info', {
                email: user.email,
              })"></span>
            </p>

            <b-button
              class="is-uppercase product-import-and-export__box-success__btn"
              type="is-success"
              tag="router-link"
              :to="{ name: 'event-history' }"
            >
              {{ $t('storePages.product.importAndExport.steps.success.btnEventHistory') }}
            </b-button>
          </section>
        </b-step-item>
      </b-steps>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase, IgFileUpload, Auth,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('product-import-and-export');

export default Vue.extend({
  name: 'page-product-import-and-export',
  components: {
    IgPageBase,
    IgFileUpload,
  },
  data() {
    return {
      activeStep: 0,
      disableSkip: false,
      show: false,
    };
  },
  computed: {
    ...mapState([
      'loading',
      'presignedUrl',
    ]),
    user() {
      return Auth.getUser();
    },
    serverPhoto() {
      return {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const request = new XMLHttpRequest();

          this.getPresignedUrlPhoto(file.name).then((url) => {
            request.open('PUT', url, true);

            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            };

            request.onload = function onload() {
              if (request.status >= 200 && request.status < 300) {
                load(request.responseText);
              } else {
                error('oh no');
              }
            };

            request.send(file);
          }).catch(() => {
            request.abort();
            abort();
          });

          return {
            abort: () => {
              request.abort();
              abort();
            },
          };
        },
      };
    },
    serverExcel() {
      return {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const request = new XMLHttpRequest();

          request.open('PUT', this.presignedUrl, true);

          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total);
          };

          request.onload = function onload() {
            if (request.status >= 200 && request.status < 300) {
              load(request.responseText);
            } else {
              error('oh no');
            }
          };

          request.send(file);

          return {
            abort: () => {
              request.abort();
              abort();
            },
          };
        },
      };
    },
  },
  methods: {
    ...mapActions([
      'downloadExcelModel',
      'getPresignedUrl',
      'getPresignedUrlPhoto',
    ]),
    async download() {
      const response = await this.downloadExcelModel();
      const url = window.URL.createObjectURL(new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'importar-produtos.xlsx');
      document.body.appendChild(link);
      link.click();
    },
    chooseFile() {
      this.$refs.fileUpload.browse();
    },
    nextStep(timeout = 1000) {
      setTimeout(() => {
        this.activeStep += 1;
      }, timeout);
    },
  },
  async beforeMount() {
    await this.getPresignedUrl();
  },
});
</script>

<style lang="scss">
  .product-import-and-export{
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__steps{
      margin: 3rem 0;
    }
    &__file-box{
      padding: 5rem;
    }
    &__drop-box{
      margin: 0 15% 1rem;
      max-height: 350px;
      overflow-y: auto;
      box-shadow: inset rgba(143,160,241,0.2) 0 0 0 1px, rgba(213,220,247,0.59) 0 10px 20px;
      border-radius: 10px;
    }
    &__drop-title{
      font-size: 1.7rem!important;
      font-weight: 600;
      margin-bottom: 0.5rem;
      .svg-inline--fa{
        display: block;
        margin: 0.5rem auto;
        font-size: 2rem;
      }
    }
    &__drop-subtitle{
      font-size: 0.8rem!important;
      margin-bottom: 3rem;
    }
    &__box-success{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 1rem 20% 1rem;
      &__title{
        color: #3dc616;
        margin-bottom: 3rem!important;
        font-size: 1.5rem;
      }
      &__info{
        padding: 0 2rem;
        margin-bottom: 3rem!important;
      }
    }
  }

  .filepond--panel-root {
    background-color: transparent;
  }

  .filepond--drop-label {
    height: auto;
  }
</style>
