import StateType, { FormType, CategoryType, ModuleType } from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleLoadingSave(state: StateType, isLoading: boolean): void {
    state.loadingSave = isLoading;
  },
  setForm(state: StateType, form: FormType): void {
    state.form = Object.assign(state.form, form);
  },
  setCategories(state: StateType, categories: CategoryType[]): void {
    state.categories = categories;
  },
  setModules(state: StateType, modules: ModuleType[]): void {
    state.modules = modules;
  },
  setModule(state: StateType, module: ModuleType): void {
    state.module = module;
  },
  setModulesList(state: StateType, modules: ModuleType[]): void {
    state.modulesList = modules;
  },
  changeStep(state: StateType, step: number): void {
    state.actualStep = step;
  },
  setClient(state: StateType, client: any): void {
    state.client = client;
  },
};
