<template>
  <ig-page-base :loading="loading">
    <div class="box">
      <h2 class="title">
        {{ $t('pageFlow.title') }}
      </h2>

      <p class="content is-size-7" v-html="$t('pageFlow.info')"></p>

      <form @submit.prevent="getListFlows">
        <div class="columns">
          <div class="column">
            <b-field
              :label="$t('pageFlow.filters.sourceModule.label')"
              label-position="inside">
              <b-select
                :placeholder="$t('pageFlow.filters.sourceModule.placeholder')"
                v-model="filters.sourceModuleConfig"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageFlow.filters.sourceModule.all') }}
                </option>
                <option
                  v-for="module in modules"
                  :key="module.moduleConfigId"
                  :value="module.moduleConfigId">{{ module.name }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageFlow.filters.targetModule.label')"
              label-position="inside">
              <b-select
                :placeholder="$t('pageFlow.filters.targetModule.placeholder')"
                v-model="filters.targetModuleConfig"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageFlow.filters.targetModule.all') }}
                </option>
                <option
                  v-for="module in modules"
                  :key="module.moduleConfigId"
                  :value="module.moduleConfigId">{{ module.name }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageFlow.filters.event.label')"
              label-position="inside">
              <b-select
                :placeholder="$t('pageFlow.filters.event.placeholder')"
                v-model="filters.event"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageFlow.filters.event.all') }}
                </option>
                <option
                  v-for="event in filterEvents"
                  :key="event.value"
                  :value="event.value">{{ event.label }}</option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div class="field has-addons but has-addons-right">
          <div class="buttons">
            <b-button
              class="is-uppercase"
              :label="$t('pageFlow.filters.btnReset')"
              type="is-default"
              @click="cleanFilters" />
            <b-button
              native-type="submit"
              class="is-uppercase"
              :label="$t('pageFlow.filters.btnSearch')"
              :disabled="loading"
              :loading="loading"
              type="is-primary" />
          </div>
        </div>
      </form>

      <ig-manage-flow :open="modalOpen" @close="closeManage" />

      <div class="field has-addons but has-addons-right">
        <b-button
          class="is-uppercase"
          :label="$t('pageFlow.btnNew')"
          type="is-success"
          @click="newFlow"/>
      </div>

      <hr />

      <div class="table-containerx">
        <b-table
          :loading="loading"
          :data="flows"
          default-sort="moduleSource.name"
          default-sort-direction="asc"
          striped
          hoverable
          scrollable
        >
          <template #empty>
            <ig-empty />
          </template>

          <b-table-column
            v-slot="props"
            width="96"
            centered
          >
            <div class="logo-image">
              <img class="logo-image-img" :src="props.row.moduleSource.logo">
            </div>
          </b-table-column>

          <b-table-column
            field="moduleSource.name"
            :label="$t('pageFlow.table.moduleSource')"
            sortable
            v-slot="props"
          >
            {{ props.row.moduleSource.name }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            width="96"
            centered
          >
            <div class="logo-image">
              <img class="logo-image-img" :src="props.row.moduleTarget.logo">
            </div>
          </b-table-column>

          <b-table-column
            field="moduleTarget.name"
            :label="$t('pageFlow.table.moduleTarget')"
            sortable
            v-slot="props"
          >
            {{ props.row.moduleTarget.name }}
          </b-table-column>

          <b-table-column
            field="event"
            :label="$t('pageFlow.table.event')"
            sortable
            v-slot="props"
          >
            {{ $t(`pageEventHistory.events.${props.row.event}`) }}
          </b-table-column>

          <b-table-column
            field="active"
            :label="$t('pageFlow.table.status.title')"
            sortable
            centered
            v-slot="props"
          >
            <b-tag
              :type="props.row.active ? 'is-success' : 'is-danger'"
              class="is-uppercase"
            >
              {{ $t(`pageFlow.table.status.${props.row.active ? 'enabled' : 'disabled'}`) }}
            </b-tag>
          </b-table-column>

          <b-table-column
            width="100"
            :label="$t('pageFlow.table.actions')"
            centered
            v-slot="props"
          >
            <div class="buttons">
              <b-field>
                <b-tooltip
                  append-to-body
                  :label="$t('pageFlow.table.actionsTooltips.edit')">
                  <b-button
                    size="is-small"
                    type="is-primary"
                    @click="editFlow(props)"
                  >Editar</b-button>
                </b-tooltip>
                <b-tooltip
                  append-to-body
                  :label="$t('pageFlow.table.actionsTooltips.remove')">
                  <b-button
                    size="is-small"
                    type="is-danger"
                    @click="deleteFlow(props)"
                  >Excluir</b-button>
                </b-tooltip>
              </b-field>
            </div>
          </b-table-column>

        </b-table>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgEmpty } from '@integrai/ui';
import IgManageFlow from './components/IgManageFlow.vue';

const { mapState, mapActions } = createNamespacedHelpers('flows');

export default Vue.extend({
  name: 'page-flows',
  components: {
    IgPageBase,
    IgEmpty,
    IgManageFlow,
  },
  data() {
    return {
      modalOpen: false,
      filters: {},
    };
  },
  computed: {
    ...mapState([
      'loading',
      'eventsEnum',
      'totalFlows',
      'flows',
      'flow',
      'modules',
    ]),
    filterEvents() {
      const events = this.eventsEnum || [];

      return events
        .map((event) => ({
          value: event,
          label: this.$t(`pageEventHistory.events.${event}`),
        }));
    },
  },
  methods: {
    ...mapActions([
      'getEventsEnum',
      'getFlows',
      'getFlow',
      'getModules',
      'removeFlow',
    ]),
    cleanFilters() {
      this.filters = {};
    },
    async getListFlows() {
      await this.getFlows(this.filters);
    },
    newFlow() {
      this.modalOpen = true;
    },
    async editFlow(props) {
      await this.getFlow(props.row);
      this.modalOpen = true;
    },
    async deleteFlow(props) {
      await this.getFlow(props.row);
      await this.removeFlow(this.flow);
      await this.getListFlows();
    },
    async closeManage(reload) {
      this.modalOpen = false;

      if (reload) {
        await this.getListFlows();
      }
    },
  },
  async beforeMount() {
    await Promise.all([
      this.getEventsEnum(),
      this.getModules(),
      this.getListFlows(),
    ]);
  },
});
</script>

<style>
.logo-image{
  position: relative;
  display: flex;
  height: 60px;
  align-items: center;
}

.logo-image-img{
  display: block;
  width: 100%;
  height: auto;
}
</style>
