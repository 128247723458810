import { render, staticRenderFns } from "./IgPaymentMethods.vue?vue&type=template&id=f45dc4c4&scoped=true&"
import script from "./IgPaymentMethods.vue?vue&type=script&lang=js&"
export * from "./IgPaymentMethods.vue?vue&type=script&lang=js&"
import style0 from "./IgPaymentMethods.vue?vue&type=style&index=0&id=f45dc4c4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f45dc4c4",
  null
  
)

export default component.exports