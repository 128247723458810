import { RouteConfig } from 'vue-router';

import PageFinance from './PageFinance.vue';

export default {
  path: 'finance',
  name: 'finance',
  component: PageFinance,
  meta: {
    title: 'Financeiro',
  },
} as RouteConfig;
