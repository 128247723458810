<template>
  <div class="ig-integration__new-config">
    <div class="ig-integration__new-config__header">
      <b-button
        type="is-primary"
        @click="modalOpen = true"
        rounded>{{ $t('pageIntegration.newConfig.btnNew') }}</b-button>
    </div>

    <b-modal
      v-model="modalOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal>
      <template>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ $t('pageIntegration.newConfig.modal.title') }}
            </p>
            <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
          </header>
          <form @submit.prevent="onSubmit">
            <section class="modal-card-body">
              <b-field
                label-position="inside"
                :label="$t('pageIntegration.newConfig.modal.fieldName.label')">
                <b-input
                  v-model="form.name"
                  :label="$t('pageIntegration.newConfig.modal.fieldName.placeholder')"
                  required>
                </b-input>
              </b-field>

              <b-field
                :label="$t('pageIntegration.newConfig.modal.fieldModule.label')"
                label-position="inside">
                <b-select
                  :placeholder="$t('pageIntegration.newConfig.modal.fieldModule.placeholder')"
                  v-model="form.module"
                  expanded>
                  <option
                    v-for="module in modulesList"
                    :key="module.id"
                    :value="module.id">{{ module.name }}</option>
                </b-select>
              </b-field>
            </section>

            <footer class="modal-card-foot">
              <b-button
                :label="$t('pageIntegration.newConfig.modal.buttons.cancel')"
                @click="$emit('close')" />
              <b-button
                native-type="submit"
                :label="$t('pageIntegration.newConfig.modal.buttons.create')"
                :loading="loadingSave"
                :disabled="loadingSave || !form.name || !form.module"
                type="is-primary" />
            </footer>
          </form>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { EventBus } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('integrations');

export default {
  name: 'integrations-new-config',
  props: ['openNewConfig'],
  data() {
    return {
      modalOpen: false,
      form: {
        name: null,
        module: null,
      },
    };
  },
  computed: {
    ...mapState([
      'modulesList',
      'loadingSave',
    ]),
  },
  watch: {
    openNewConfig(id) {
      this.form.module = id;
      this.modalOpen = true;
    },
  },
  methods: {
    ...mapActions([
      'createConfig',
    ]),
    async onSubmit() {
      const moduleConfigId = await this.createConfig(this.form);

      if (moduleConfigId) {
        EventBus.$emit('toast', {
          type: 'is-success',
          message: this.$t('pageIntegration.newConfig.modal.success'),
        });

        return this.$router.push({
          name: 'integrations-detail',
          params: {
            // eslint-disable-next-line no-underscore-dangle
            id: String(this.form.module),
            moduleConfigId,
          },
        });
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
  .ig-integration{
    &__new-config{
      margin-top: 2rem;
      &__header{
        text-align: right;
      }
    }
  }
</style>
