import StateType, { OrderType } from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setOrders(state: StateType, orders: OrderType[]): void {
    state.orders = orders;
  },
  setTotal(state: StateType, total: number): void {
    state.total = total;
  },
};
