import { RouteConfig } from 'vue-router';

/* eslint-disable */
// @ts-ignore
import * as children from './pages/*/routes.ts';
/* eslint-enable */

export default {
  name: 'product',
  path: 'product',
  component: { template: '<router-view></router-view>' },
  children,
  redirect: {
    name: 'product-import-and-export',
  },
} as RouteConfig;
