import { RouteConfig } from 'vue-router';

import PageOrders from './PageOrders.vue';

export default {
  path: 'list',
  name: 'orders',
  component: PageOrders,
  meta: {
    title: 'Pedidos',
  },
} as RouteConfig;
