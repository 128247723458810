import Vue from 'vue';
import {
  IntegraiUi,
  Http,
  Auth,
  HttpStore,
} from '@integrai/ui';

import '@integrai/ui/dist/index.css';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';

Vue.use(IntegraiUi);

Vue.config.productionTip = false;

const httpInterceptor = (request: any) => {
  if (Auth.isAuth()) {
    Object.assign(request.headers, {
      Authorization: `Bearer ${Auth.getToken()}`,
    });
  }

  return request;
};

Http.setBaseUrl(process.env.VUE_APP_MANAGE_API_URL || '');
Http.setInterceptorRequest(httpInterceptor, (error: any) => Promise.reject(error));

HttpStore.setBaseUrl(process.env.VUE_APP_STORE_API_URL || '');
HttpStore.setInterceptorRequest(httpInterceptor, (error: any) => Promise.reject(error));

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
