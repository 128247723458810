import StateType from './state.type';

export default {
  loading: false,
  tableLoading: false,
  totalBillings: 0,
  billings: [],
  paymentMethodInfo: {},
  actualPlan: {},
  nextCycleDate: null,
  clientPlanInfo: {},
} as StateType;
