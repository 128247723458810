<template>
  <section>
    <ig-page-loading v-if="loading"></ig-page-loading>
    <template v-else>
      <h2 class="subtitle" v-html="form.title"></h2>

      <p class="is-size-7 content" v-html="form.description"></p>

      <ig-form-schema
        :schema="form.schema"
        :model="formModel"
        @submit="save"
      >
        <template slot="actions">
          <b-button
            native-type="submit"
            class="is-uppercase ig-external-form__submit"
            :label="form.submitBtn"
            :loading="loadingSave"
            :disabled="loadingSave"
            type="is-primary"
            expanded></b-button>
        </template>
      </ig-form-schema>
    </template>
  </section>
</template>

<script>
import {
  IgFormSchema, IgPageLoading, Http, EventBus,
} from '@integrai/ui';
import * as Lodash from 'lodash';

export default {
  name: 'ig-form-external',
  props: {
    id: {
      type: Number,
      required: true,
    },
    models: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      loadingSave: false,
      form: {
        title: null,
        description: null,
        schema: null,
        submitBtn: null,
        successMessage: null,
        models: {},
      },
      clientInfo: {},
    };
  },
  computed: {
    formModel() {
      const defaultModels = {
        client: this.clientInfo,
        ...this.models || {},
      };

      const {
        models = {},
      } = this.form;

      return Object.keys(models)
        .map((key) => ({
          [key]: Lodash.get(defaultModels, models[key]),
        }))
        .reduce((fieldA, fieldB) => ({ ...fieldA, ...fieldB }), {});
    },
  },
  methods: {
    async save(form) {
      this.loadingSave = true;

      try {
        await Http.post(`external-form/${this.id}`, form);
        this.loadingSave = false;

        EventBus.$emit('toast', {
          type: 'is-success',
          message: this.form.successMessage,
        });

        this.$emit('finish');
      } catch (e) {
        this.loadingSave = false;
      }
    },
  },
  async beforeMount() {
    this.form = await Http.get(`external-form/${this.id}`);
    this.clientInfo = await Http.get('client');
    this.loading = false;
  },
  components: {
    IgFormSchema,
    IgPageLoading,
  },
};
</script>

<style lang="scss">
.ig-external-form{
  &__submit{
    margin-top: 1rem;
  }
}
</style>
