import { ActionContext, Commit } from 'vuex';
import { Http, Auth, AuthBackOffice } from '@integrai/ui';
import { LoginParams, StateType } from '../types/StateType';

export default {
  login(context: ActionContext<StateType, Commit>, form: LoginParams) {
    const { commit } = context || {};

    commit('toggleLoading', true);

    return Http.post('/auth/login', form)
      .then((user) => Auth.updateUser(user))
      .finally(() => commit('toggleLoading', false));
  },
  loginBackOffice(context: ActionContext<StateType, Commit>, form: LoginParams) {
    const { commit } = context || {};

    commit('toggleLoading', true);

    return Http.post('/auth/login-back-office', form)
      .then((user) => AuthBackOffice.updateUser(user))
      .finally(() => commit('toggleLoading', false));
  },
};
