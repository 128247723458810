import { ActionContext } from 'vuex';
import { Http } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getPlanAndConsumption(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      commit('setPlans', await Http.get('plan/list'));
      commit('setPlanConsumption', await Http.get('plan/consumption'));
      commit('setActualPlan', await Http.get('plan'));
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
};
