var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ig-page-base',{attrs:{"loading":_vm.loading}},[(!_vm.loading)?_c('div',{staticClass:"box integration-plan-and-consumption__content"},[_c('div',[_c('h2',{staticClass:"title integration-plan-and-consumption__title has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.plan.title'))+" ")]),(_vm.successShopify)?_c('b-message',{attrs:{"type":"is-success","icon":"check-circle","icon-size":"is-small","has-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('pagePlanAndConsumption.shopifyPlanSuccess'))}})]):_vm._e(),_c('div',{staticClass:"integration-plan"},[_c('div',{staticClass:"integration-plan__info"},[_c('div',{staticClass:"integration-plan__actual"},[_c('ul',{staticClass:"integration-plan__list"},_vm._l((_vm.plans),function(plan){return _c('li',{key:plan.name,staticClass:"integration-plan__list-item tag is-uppercase",class:{
                  'is-primary': plan.slug === _vm.actualPlan.slug,
                  'is-success': _vm.actualPlan.migrationPlanSlug === plan.slug,
                }},[_vm._v(" "+_vm._s(plan.name)+" ")])}),0),(_vm.actualPlan.migrationPlanSlug)?_c('small',{staticClass:"integration-plan__migration-text"},[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.plan.migrationText'))+" ")]):_vm._e()]),_c('div',{staticClass:"integration-plan__value"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.plan.value'))+" ")]),_c('strong',{staticClass:"integration-plan__price"},[(_vm.hasPlan)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('pagePlanAndConsumption.plan.priceCurrency'))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("finance")(_vm.actualPlan.price)))]),_c('span',[_vm._v(" / ")]),_c('span',[_vm._v(_vm._s(_vm.$t('pagePlanAndConsumption.plan.priceSuffix')))])]):_vm._e(),(!_vm.hasPlan)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.plan.free'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"integration-plan__btn"},[_c('b-button',{staticClass:"is-light is-uppercase",class:{
              'is-success': !_vm.hasPlan,
              'is-primary': _vm.hasPlan,
            },attrs:{"tag":"router-link","to":{ name: 'plans' }}},[(!_vm.hasPlan)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.plan.btnSubscription'))+" ")]):_vm._e(),(_vm.hasPlan)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.plan.btnChangeSubscription'))+" ")]):_vm._e()])],1)])],1),_c('hr'),_c('div',[_c('h2',{staticClass:"title integration-plan-and-consumption__title has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.consumption.title'))+" ")]),_c('p',{staticClass:"content is-size-7"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('pagePlanAndConsumption.consumption.info', {
         totalEvents: _vm.planConsumption.events.actual,
         totalModules: _vm.planConsumption.modules.actual
         }))}}),_c('strong',{staticClass:"has-text-primary"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.planConsumption.endCycle))+" ")]),_vm._v(". ")]),_c('ig-progress-bar',{attrs:{"label":_vm.$t('pagePlanAndConsumption.consumption.events'),"value":_vm.planConsumption.events.actual,"max-value":_vm.planConsumption.events.max}}),_c('ig-progress-bar',{attrs:{"label":_vm.$t('pagePlanAndConsumption.consumption.modules'),"value":_vm.planConsumption.modules.actual,"max-value":_vm.planConsumption.modules.max}}),_c('div',{staticClass:"integration-plan-and-consumption__restrictions"},[_c('div',{staticClass:"integration-plan-and-consumption__restrictions-item"},[_c('span',{staticClass:"integration-plan-and-consumption__restrictions-label"},[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.consumption.contingency'))+" ")]),_c('span',{staticClass:"tag is-light",class:{
              'is-success': _vm.planConsumption.hasContingency,
              'is-danger': !_vm.planConsumption.hasContingency,
            }},[_vm._v(" "+_vm._s(_vm.$t([ 'pagePlanAndConsumption', 'consumption', _vm.planConsumption.hasContingency ? 'available' : 'unavailable' ].join('.')))+" ")])]),_c('div',{staticClass:"integration-plan-and-consumption__restrictions-item"},[_c('span',{staticClass:"integration-plan-and-consumption__restrictions-label"},[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.consumption.support'))+" ")]),_c('span',{staticClass:"tag is-light",class:{
              'is-success': _vm.planConsumption.hasSupport,
              'is-danger': !_vm.planConsumption.hasSupport,
            }},[_vm._v(" "+_vm._s(_vm.$t([ 'pagePlanAndConsumption', 'consumption', _vm.planConsumption.hasSupport ? 'available' : 'unavailable' ].join('.')))+" ")])]),_c('div',{staticClass:"integration-plan-and-consumption__restrictions-item"},[_c('span',{staticClass:"integration-plan-and-consumption__restrictions-label"},[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.consumption.erp'))+" ")]),_c('span',{staticClass:"tag is-light",class:{
              'is-success': _vm.planConsumption.hasErp,
              'is-danger': !_vm.planConsumption.hasErp,
            }},[_vm._v(" "+_vm._s(_vm.$t([ 'pagePlanAndConsumption', 'consumption', _vm.planConsumption.hasErp ? 'available' : 'unavailable' ].join('.')))+" ")])]),_c('div',{staticClass:"integration-plan-and-consumption__restrictions-item",staticStyle:{"display":"none"}},[_c('span',{staticClass:"integration-plan-and-consumption__restrictions-label"},[_vm._v(" "+_vm._s(_vm.$t('pagePlanAndConsumption.consumption.marketplace'))+" ")]),_c('span',{staticClass:"tag is-light",class:{
              'is-success': _vm.planConsumption.hasMartetplace,
              'is-danger': !_vm.planConsumption.hasMartetplace,
            }},[_vm._v(" "+_vm._s(_vm.$t([ 'pagePlanAndConsumption', 'consumption', _vm.planConsumption.hasMartetplace ? 'available' : 'unavailable' ].join('.')))+" ")])])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }