import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Route } from '@integrai/ui';
import PageHome from '@/features/private/home/PageHome.vue';
import PageAuth from '@/features/public/auth/PageAuth.vue';

/* eslint-disable */
// @ts-ignore
import * as privateFeatures from './features/private/*/routes.ts';
// @ts-ignore
import * as publicFeatures from './features/public/**/routes.ts';
/* eslint-enable */

import RouteManager from './providers/RouteManager';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    name: 'auth',
    component: PageAuth,
    children: Route.convert(publicFeatures),
    redirect: {
      name: 'page-login',
    },
  },
  {
    path: '/',
    name: 'home',
    component: PageHome,
    children: Route.convert(privateFeatures),
    meta: {
      requiresAuth: true,
    },
    redirect: {
      name: 'integrations',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => new RouteManager(to, from, next).beforeEach());

export default router;
