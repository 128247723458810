import { StateType } from './store.types';

export default {
  loading: false,
  partners: [],
  form: {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    termsAndConditionsAgree: false,
  },
} as StateType;
