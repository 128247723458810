import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleLoadingRerun(state: StateType, data: any): void {
    const { eventIndex, isLoading } = data;
    state.events[eventIndex].loadingRerun = isLoading;
  },
  setTotalEvents(state: StateType, totalEvents: number): void {
    state.totalEvents = totalEvents;
  },
  setEventsEnum(state: StateType, eventsEnum: any[]): void {
    state.eventsEnum = eventsEnum;
  },
  setEvents(state: StateType, events: any[]): void {
    state.events = events;
  },
  setModules(state: StateType, modules: any[]): void {
    state.modules = modules;
  },
  setEventRequests(state: StateType, data: any): void {
    const { eventIndex, requests, loadingRequests } = data;
    state.events[eventIndex].loadingRequests = loadingRequests;
    state.events[eventIndex].requests = requests.map((request: any) => ({
      ...request,
      expanded: false,
    }));
  },
  expandEventRequest(state: StateType, data: any): void {
    const { eventIndex, requestKey } = data;

    state.events[eventIndex].requests[requestKey].expanded = !state
      .events[eventIndex].requests[requestKey].expanded;
  },
};
