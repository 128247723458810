<template>
  <ig-page-base>
    <div class="box categories__content">
      <div class="categories__title-box">
        <h2 class="title categories__title has-text-weight-semibold">
          {{ $t(`storePages.product.categories.manage.${isUpdate
          ? 'titleUpdate' : 'titleCreate'}`) }}
        </h2>
      </div>

      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="categories__form-header">
            <h2 class="categories__form-title">
              {{ $t('storePages.product.categories.manage.fields.headerDefault') }}
            </h2>

            <b-switch
              v-model="form.enabled"
              type="is-primary">
              {{ $t('storePages.product.categories.manage.fields.enabled.label') }}
            </b-switch>
          </div>

          <validation-provider
            rules="required"
            :name="$t('storePages.product.categories.manage.fields.name.label')"
            v-slot="{ errors, valid }"
          >
            <div class="field">
              <b-field
                :label="$t('storePages.product.categories.manage.fields.name.label')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
                label-position="inside">
                <b-input v-model="form.name" />
              </b-field>
            </div>
          </validation-provider>

          <div class="field">
            <b-field
              :label="$t('storePages.product.categories.manage.fields.parentCategory.label')"
              label-position="inside">
              <ig-tree-select
                v-model="form.parentId"
                :placeholder="$t(
                  'storePages.product.categories.manage.fields.parentCategory.placeholder',
                )"
                :load-options="loadCategories"
                :options="listCategories"
                clearable
                searchable
              />
            </b-field>
          </div>

          <div class="field">
            <b-field
              :label="$t('storePages.product.categories.manage.fields.description.label')"
              label-position="inside">
              <b-input type="textarea" v-model="form.description" />
            </b-field>
          </div>

          <div class="buttons categories__form-actions">
            <b-button
              tag="router-link"
              :to="{ name: 'categories' }"
              type="is-default"
            >
              {{ $t('storePages.product.categories.manage.btnCancel') }}
            </b-button>

            <b-button
              native-type="submit"
              type="is-primary"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t(`storePages.product.categories.manage.${isUpdate
              ? 'btnUpdate' : 'btnCreate'}`) }}
            </b-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase,
  IgTreeSelect,
  EventBus,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('categories');

export default Vue.extend({
  name: 'page-manage-category',
  components: {
    IgPageBase,
    IgTreeSelect,
  },
  data() {
    return {
      isUpdate: false,
      form: {
        enabled: true,
      },
    };
  },
  computed: {
    ...mapState([
      'loading',
      'categories',
    ]),
    listCategories() {
      if (this.categories.length === 0) {
        return null;
      }

      return [
        {
          id: 0,
          label: '[ Raiz ]',
        },
        ...this.transformCategories(this.categories),
      ];
    },
  },
  methods: {
    ...mapActions([
      'getCategories',
      'getCategory',
      'createCategory',
      'updateCategory',
    ]),
    transformCategories(categories) {
      return categories
        .filter((category) => category.id !== this.$route?.params?.id)
        .map((category) => {
          const item = {
            id: category.id,
            label: category.name,
          };

          if (Array.isArray(category.children)) {
            Object.assign(item, {
              children: this.transformCategories(category.children),
            });
          }

          return item;
        });
    },
    async loadCategories({ action }) {
      if (action === 'LOAD_ROOT_OPTIONS') {
        await this.getCategories();
      }
    },
    async submit() {
      const form = {
        ...this.form,
        parentId: this.form.parentId === 0 ? null : this.form.parentId,
      };

      if (this.isUpdate) {
        const {
          id,
          ...data
        } = form;

        await this.updateCategory({
          id,
          form: data,
        });
      } else {
        await this.createCategory(form);
      }

      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t(
          `storePages.product.categories.manage.${this.isUpdate ? 'updateSuccess' : 'createSuccess'}`,
        ),
      });

      this.$router.push({ name: 'categories' });
    },
  },
  async beforeMount() {
    const { id } = this.$route.params;
    if (id) {
      try {
        this.form = await this.getCategory(id);
        this.form.parentId = this.form.parentId || 0;
        this.isUpdate = true;
      } catch {
        this.$router.push({ name: 'categories' });
      }
    }
  },
});
</script>

<style lang="scss">
  .categories{
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__form-header{
      display: flex;
      border-bottom: 1px solid #b5b5b5;
      padding: 1rem 0;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }
    &__form-title{
      font-weight: 700;
    }
    &__form-actions{
      justify-content: flex-end;
    }
  }

  .field{
    margin-bottom: 2rem;
  }
</style>
