<template>
  <ig-page-base>
    <div class="box products__content">
      <div class="products__title-box">
        <h2 class="title products__title has-text-weight-semibold">
          {{ $t('storePages.product.products.title') }}
        </h2>

<!--        <b-button-->
<!--          type="is-success"-->
<!--          :label="$t('storePages.product.products.btnNewProduct')"-->
<!--        ></b-button>-->
      </div>

      <div class="products__filters">
        <div class="columns">
          <div class="column is-6">
            <form @submit.prevent="getProductsList()">
              <b-field grouped>
                <b-input
                  v-model="filters.query"
                  :placeholder="$t('storePages.product.products.filters.query')"
                  expanded />
                <p class="control">
                  <b-button
                    native-type="submit"
                    type="is-primary"
                    :label="$t('storePages.product.products.filters.btnSearch')"
                    :loading="loading"
                    :disabled="loading"
                  ></b-button>
                </p>
              </b-field>
            </form>
          </div>
          <div class="column has-text-right">
            <b-button
              type="is-primary"
              icon-left="filter"
              outlined
              :label="$t('storePages.product.products.filters.btnFilters')"
              :disabled="loading"
              @click="showFilters = true"
            ></b-button>

            <ig-sidebar-filters
              v-model="showFilters"
              v-slot="{ filters }"
              @submit="applyFilters"
            >
              <b-field
                :label="$t('storePages.product.products.filters.enabled.label')"
                label-position="inside">

                <b-select
                  v-model="filters.enabled"
                  :placeholder="$t('storePages.product.products.filters.enabled.options.all')"
                  expanded>
                  <option :value="undefined">
                    {{ $t('storePages.product.products.filters.enabled.options.all') }}
                  </option>
                  <option :value="true">
                    {{ $t('storePages.product.products.filters.enabled.options.active') }}
                  </option>
                  <option :value="false">
                    {{ $t('storePages.product.products.filters.enabled.options.inactive') }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                :label="$t('storePages.product.products.filters.type.label')"
                label-position="inside">
                <b-select v-model="filters.type" expanded>
                  <option :value="null">
                    {{ $t('storePages.product.products.filters.type.options.all') }}
                  </option>
                  <option value="SIMPLE">
                    {{ $t('storePages.product.products.filters.type.options.simple') }}
                  </option>
                  <option value="CONFIGURABLE">
                    {{ $t('storePages.product.products.filters.type.options.configurable') }}
                  </option>
                </b-select>
              </b-field>
            </ig-sidebar-filters>
          </div>
        </div>
      </div>

      <hr />

      <b-table
        :loading="loading"
        :data="products"
        backend-sorting
        default-sort="createdAt"
        default-sort-direction="desc"
        paginated
        pagination-simple
        backend-pagination
        @page-change="onPageChange"
        :total="total"
        :per-page="filters.limit"
        :current-page.sync="filters.page"
        @sort="sort"
        @click="row => editProduct(row.id)"
        striped
        hoverable
        scrollable
      >
        <template #empty>
          <ig-empty />
        </template>

        <template #bottom-left>
          <ig-per-page
            :total-displayed="products.length"
            :total="total"
            :perPage="filters.limit"
            @change="changePerPage"
          />
        </template>

        <b-table-column
          field="name"
          :label="$t('storePages.product.products.table.header.product')"
          v-slot="props"
          sortable
        >
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <img
                  :src="props.row.photos.length > 0
                  ? props.row.photos[0] : 'https://assets.integrai.com.br/store/no_image.png'"
                  :alt="props.row.name">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>{{ props.row.name }}</strong>
                </p>
              </div>
            </div>
          </article>
        </b-table-column>

        <b-table-column
          field="sku"
          :label="$t('storePages.product.products.table.header.sku')"
          v-slot="props"
          centered
          sortable
        >
          {{ props.row.sku }}
        </b-table-column>

        <b-table-column
          field="type"
          :label="$t('storePages.product.products.table.header.type')"
          v-slot="props"
          centered
          sortable
        >
          <b-tag
            :type="props.row.type === 'SIMPLE' ? 'is-primary' : 'is-warning'"
            class="is-uppercase"
          >
            {{ $t(`storePages.product.products.table.data.types.${props.row.type}`) }}
          </b-tag>
        </b-table-column>

        <b-table-column
          field="price"
          :label="$t('storePages.product.products.table.header.price')"
          v-slot="props"
          centered
          sortable
        >
          <span :class="{
            'products__line-through': !!props.row.specialPrice,
          }">R$ {{ props.row.price | finance }}</span>
          <br>
          <span v-if="props.row.specialPrice">R$ {{ props.row.specialPrice | finance }}</span>

        </b-table-column>

        <b-table-column
          field="stock.quantity"
          :label="$t('storePages.product.products.table.header.stock')"
          v-slot="props"
          centered
          sortable
        >
          {{ props.row.stock.quantity }}
        </b-table-column>

        <b-table-column
          field="enabled"
          :label="$t('storePages.product.products.table.header.status')"
          centered
          sortable
          v-slot="props"
        >
          <span class="tag is-uppercase" :class="{
          'is-success': props.row.enabled,
          'is-danger':  !props.row.enabled,
          }">
            {{ $t(`storePages.product.products.table.data.statusEnum.${props.row.enabled}`) }}
          </span>
          {{ props.row.status }}
        </b-table-column>

<!--        <b-table-column-->
<!--          width="100"-->
<!--          v-slot="props"-->
<!--        >-->
<!--          <b-dropdown-->
<!--            aria-role="list"-->
<!--            position="is-bottom-left"-->
<!--            append-to-body>-->
<!--            <template #trigger>-->
<!--              <b-button-->
<!--                :label="$t('storePages.product.products.table.header.actions')"-->
<!--                type="is-primary"-->
<!--                size="is-small"-->
<!--                icon-right="caret-down" />-->
<!--            </template>-->

<!--            <b-dropdown-item aria-role="listitem" @click="editProduct(props.row.id)">-->
<!--              <b-icon-->
<!--                icon="external-link-alt"-->
<!--                size="is-small"/>-->
<!--              {{ $t('storePages.product.products.table.data.actions.view') }}-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item aria-role="listitem" @click="editProduct(props.row.id)">-->
<!--              <b-icon-->
<!--                icon="pen"-->
<!--                size="is-small"/>-->
<!--              {{ $t('storePages.product.products.table.data.actions.edit') }}-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item aria-role="listitem" @click="deleteProduct(props.row.id)">-->
<!--              <b-icon-->
<!--                icon="trash"-->
<!--                size="is-small"/>-->
<!--              {{ $t('storePages.product.products.table.data.actions.delete') }}-->
<!--            </b-dropdown-item>-->
<!--          </b-dropdown>-->
<!--        </b-table-column>-->
      </b-table>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase,
  IgEmpty,
  IgSidebarFilters,
  IgPerPage,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('products');

export default Vue.extend({
  name: 'page-products',
  components: {
    IgPageBase,
    IgEmpty,
    IgSidebarFilters,
    IgPerPage,
  },
  data() {
    return {
      showFilters: false,
      filters: {
        limit: 10,
        page: 1,
      },
    };
  },
  computed: {
    ...mapState([
      'loading',
      'products',
      'total',
    ]),
  },
  methods: {
    ...mapActions([
      'getProducts',
    ]),
    sort(sortField, sortOrder) {
      Object.assign(this.filters, {
        sortField,
        sortOrder,
      });

      this.getProductsList();
    },
    onPageChange(page) {
      Object.assign(this.filters, {
        page,
      });

      this.getProductsList();
    },
    changePerPage(limit) {
      Object.assign(this.filters, {
        limit,
      });

      this.getProductsList();
    },
    applyFilters(filters) {
      this.getProductsList({
        ...this.filters,
        ...filters || {},
      });
    },
    async getProductsList(filters) {
      await this.getProducts(filters || this.filters);
    },
    editProduct(productId) {
      console.log('productId', productId);
    },
    deleteProduct(productId) {
      console.log('productId', productId);
    },
  },
  async beforeMount() {
    await this.getProductsList();
  },
});
</script>

<style lang="scss">
  .products{
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__line-through {
      text-decoration: line-through;
    }
  }
</style>
