import { ActionContext } from 'vuex';
import { Http } from '@integrai/ui';
import StateType from '@/features/private/settings/pages/account/store/state.type';

export default {
  async getEventsEnum(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('setEventsEnum', await Http.get('event-history/events'));
  },
  async getEventHistory(context: ActionContext<StateType, {}>, filters: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await Http.post('event-history', filters);

      commit('setEvents', response.events.map((event: any) => ({
        ...event,
        loadingRerun: false,
        loadingRequests: false,
        requests: [],
      })));
      commit('setTotalEvents', response.count);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async getModules(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('setModules', await Http.get('module/clients'));
  },
  async rerunEvent(context: ActionContext<StateType, {}>, eventId: string): Promise<void> {
    await Http.post('event-history/rerun', {
      eventId,
    });
  },
  async getEventRequests(
    context: ActionContext<StateType, {}>,
    params: any,
  ): Promise<any> {
    const url = [
      'event-history',
      params.eventId,
      'requests',
      params.moduleSourceConfigId,
      params.moduleTargetConfigId,
    ].filter((a) => !!a).join('/');

    return Http.get(url);
  },
};
