<template>
  <section v-if="!loading && data" class="ig-finance-info">
    <p class="content is-size-7">
      <span v-html="$t('pageFinance.financeInfo.info')"></span>
    </p>

    <div>
      <ig-form-schema
        v-if="!loading"
        :schema="formSchema"
        :model="data"
        @submit="save"
      >
        <template slot="actions">
          <span></span>
        </template>
      </ig-form-schema>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgFormSchema,
  EventBus,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('finance');

export default Vue.extend({
  name: 'ig-finance-info',
  data() {
    return {
      data: null,
    };
  },
  computed: {
    ...mapState([
      'loading',
      'clientPlanInfo',
    ]),
    formSchema() {
      return {
        class: 'columns is-vcentered',
        fields: [
          {
            name: 'dueDate',
            component: 'select',
            containerClass: 'column is-2',
            fieldAttrs: {
              label: this.$t('pageFinance.financeInfo.dueDate'),
            },
            validation: {
              name: this.$t('pageFinance.financeInfo.dueDate'),
              rules: 'required',
            },
            options: [
              {
                value: 1,
                label: `${this.$t('pageFinance.financeInfo.prefixDueDate')} 01`,
              },
              {
                value: 5,
                label: `${this.$t('pageFinance.financeInfo.prefixDueDate')} 05`,
              },
              {
                value: 10,
                label: `${this.$t('pageFinance.financeInfo.prefixDueDate')} 10`,
              },
              {
                value: 15,
                label: `${this.$t('pageFinance.financeInfo.prefixDueDate')} 15`,
              },
              {
                value: 20,
                label: `${this.$t('pageFinance.financeInfo.prefixDueDate')} 20`,
              },
              {
                value: 25,
                label: `${this.$t('pageFinance.financeInfo.prefixDueDate')} 25`,
              },
            ],
          },
          {
            name: 'financeEmails',
            component: 'taginput',
            containerClass: 'column is-narrow',
            fieldAttrs: {
              label: this.$t('pageFinance.financeInfo.emails'),
            },
            attrs: {
              icon: 'envelope',
              placeholder: this.$t('pageFinance.financeInfo.emailsPlaceholder'),
              ellipsis: true,
            },
            validation: {
              name: this.$t('pageFinance.financeInfo.emails'),
              rules: 'required|emailList',
            },
          },
          {
            component: 'button',
            containerClass: 'column',
            attrs: {
              type: 'is-primary',
              'native-type': 'submit',
              label: this.$t('pageAccount.btnSave'),
              class: 'is-uppercase ig-finance-info__btn',
            },
            modelProps: {
              loading: 'loading',
              disabled: 'loading',
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions([
      'getClientPlanInfo',
      'updateClientPlanInfo',
    ]),
    async save(form) {
      const {
        dueDate,
        financeEmails,
      } = form;

      this.data.loading = true;

      try {
        await this.updateClientPlanInfo({
          dueDate,
          financeEmails,
        });

        this.data.loading = false;

        EventBus.$emit('toast', {
          type: 'is-success',
          message: this.$t('pageFinance.financeInfo.messageSuccess'),
        });
      } catch (e) {
        this.data.loading = false;
      }
    },
  },
  components: {
    IgFormSchema,
  },
  async beforeMount() {
    this.data = {
      loading: true,
    };

    await this.getClientPlanInfo();

    this.data = {
      loading: false,
      ...this.clientPlanInfo || {},
    };
  },
});
</script>

<style lang="scss" scoped>
  .ig-finance-info{
    padding-bottom: 2rem;
  }
</style>
