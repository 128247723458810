




























































import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { Auth, EventBus, IgPageLoading } from '@integrai/ui';
import IgNavBar from '@/components/ig-navbar/IgNavBar.vue';
import IgFooter from '@/components/ig-footer/IgFooter.vue';
import IgOpenTicketPopup from '@/components/ig-open-ticket-popup/IgOpenTicketPopup.vue';
import { ClientType } from '@/features/private/home/store/state.type';
import IgLoginClient from '@/components/ig-login-client/IgLoginClient.vue';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('home');

export default Vue.extend({
  name: 'page-home',
  components: {
    IgLoginClient,
    IgNavBar,
    IgFooter,
    IgPageLoading,
    IgOpenTicketPopup,
  },
  computed: {
    ...mapState([
      'loading',
      'emailSent',
      'client',
    ]),
    ...mapGetters([
      'emailVerified',
      'usedFreeTier',
    ]),
    user(): ClientType {
      return Auth.getUser();
    },
    showEmailVerify(): boolean {
      return this.emailVerified !== undefined ? !this.emailVerified : false;
    },
    daysOfFreeTier(): number {
      const {
        plan,
      } = this.user as any;

      return plan.daysFreeTier;
    },
  },
  methods: {
    ...mapActions([
      'getClient',
      'me',
      'sendAccountVerificationEmail',
      'subscribeShopifyPlan',
    ]),
    async sendEmail(): Promise<void> {
      const { id } = this.client || {};

      try {
        await this.sendAccountVerificationEmail(id);

        EventBus.$emit('toast', {
          type: 'is-success',
          message: this.$t('pageHome.success.emailSend'),
        });
      } catch (e) {
        EventBus.$emit('toast', {
          type: 'is-danger',
          message: this.$t('pageHome.error.emailSend'),
        });
      }
    },
    redirectToPlans() {
      if (this.daysOfFreeTier !== null && this.daysOfFreeTier <= 0 && this.$route.name !== 'plans') {
        this.$router.push({ name: 'plans' });
      }
    },
  },
  async beforeMount(): Promise<void> {
    const { jwt } = this.$route.query || {};

    if (jwt) {
      Auth.updateUser({
        accessToken: jwt,
        plan: {},
      });

      const userInfo = await this.me();

      Auth.updateUser(userInfo);

      const query = {
        ...this.$route.query,
      };

      delete query.jwt;

      this.$router.replace({ query });

      window.location.reload();
    }

    if (!Auth.isAuth()) {
      EventBus.$emit('redirectToLogin');
    }

    await this.getClient();
  },
  async mounted() {
    const { charge_id: chargeId, plan } = this.$route.query || {};

    if (chargeId && plan) {
      await this.subscribeShopifyPlan({
        chargeId,
        plan,
      });
      const userInfo = await this.me();

      Auth.updateUser(userInfo);

      window.location.href = `${process.env.VUE_APP_MANAGE_URL}/settings/plan-and-consumption?successShopify=1`;
    } else {
      this.redirectToPlans();
    }
  },
});
