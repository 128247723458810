<template>
  <ig-page-base :loading="loading">
    <div class="box integration-billing__content">
      <ig-payment-methods></ig-payment-methods>

      <h2 class="title integration-billing__title has-text-weight-semibold">
        {{ $t('pageBilling.title') }}
      </h2>

      <ig-finance-info />

      <p class="content is-size-7">
        <span v-html="$t('pageBilling.info')"></span>
        <strong class="has-text-primary">{{ nextCycleDate | date }}</strong>.
      </p>

      <div class="columns">
        <div class="column">
          <b-field
            :label="$t('pageBilling.filters.rangeDate.label')"
            label-position="inside">
            <b-datepicker
              :placeholder="$t('pageBilling.filters.rangeDate.placeholder')"
              v-model="filters.dates"
              :disabled="loading"
              locale="pt-BR"
              range>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$t('pageBilling.filters.limit')"
            label-position="inside">
            <b-select
              v-model="filters.limit"
              :disabled="loading"
              expanded>
              <option :value="5">5</option>
              <option :value="10">10</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <b-table
        :loading="tableLoading"
        :data="billings"
        backend-sorting
        default-sort="dateAcquisition"
        default-sort-direction="desc"
        paginated
        backend-pagination
        :total="totalBillings"
        :per-page="filters.limit"
        :current-page.sync="filters.page"
        @sort="sort"
        striped
        hoverable
      >
        <template #empty>
          <ig-empty />
        </template>

        <b-table-column
          field="dateAcquisition"
          :label="$t('pageBilling.table.endDate')"
          sortable
          centered
          v-slot="props"
        >
          {{ props.row.dateAcquisition | date('DD/MM/YYYY') }}
        </b-table-column>

        <b-table-column
          field="subscription.plan.name"
          :label="$t('pageBilling.table.planAndProduct')"
          centered
          sortable
          v-slot="props"
        >
          <span v-if="props.row.subscription" class="tag is-uppercase is-light is-primary">
            {{ props.row.subscription.plan.name }}
          </span>
          <span v-else>
            {{ props.row.product.name }}
          </span>
        </b-table-column>

        <b-table-column
          field="totalValue"
          :label="$t('pageBilling.table.totalValue')"
          sortable
          v-slot="props"
        >
          {{
            props.row.paymentMethod === 'SHOPIFY' ? 'U$' : 'R$' }}{{ props.row.totalValue | finance
          }}
        </b-table-column>

        <b-table-column
          field="status"
          :label="$t('pageBilling.table.status')"
          sortable
          centered
          v-slot="props"
        >
          <span
            class="tag is-uppercase is-light"
            :class="[transformStatus(props.row.status).color]">
            {{ transformStatus(props.row.status).label }}
          </span>
        </b-table-column>

        <b-table-column
          :label="$t('pageBilling.table.action')"
          centered
          v-slot="props">
          <b-field>
            <b-button
              v-if="props.row.boletoUrl && ['PENDING', 'OVERDUE'].includes(props.row.status)"
              tag="a"
              :href="props.row.boletoUrl"
              target="_blank"
              type="is-success"
              size="is-small"
              outlined>
                {{ props.row.paymentMethod === 'SHOPIFY' ? 'Pagar' : 'Pagar boleto' }}
            </b-button>
          </b-field>
          <b-field>
            <b-button
              v-if="props.row.status === 'REFUSED'"
              type="is-danger"
              size="is-small"
              outlined>Trocar forma de pagemento</b-button>
          </b-field>
          <b-field>
            <b-button
              v-if="props.row.invoiceUrl"
              tag="a"
              :href="props.row.invoiceUrl"
              target="_blank"
              type="is-info"
              size="is-small"
              outlined>Ver Nota Fiscal</b-button>
          </b-field>
        </b-table-column>
      </b-table>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgEmpty } from '@integrai/ui';
import IgPaymentMethods from './components/IgPaymentMethods.vue';
import IgFinanceInfo from './components/IgFinanceInfo.vue';

const { mapState, mapActions } = createNamespacedHelpers('finance');

export default Vue.extend({
  name: 'page-finance',
  data() {
    return {
      filters: {
        limit: 10,
        page: 1,
        sort: {
          field: 'dateAcquisition',
          order: 'desc',
        },
      },
    };
  },
  components: {
    IgPaymentMethods,
    IgFinanceInfo,
    IgPageBase,
    IgEmpty,
  },
  computed: {
    ...mapState([
      'loading',
      'tableLoading',
      'totalBillings',
      'billings',
      'nextCycleDate',
    ]),
  },
  watch: {
    filters: {
      async handler() {
        await this.getListBillings();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'getBillings',
    ]),
    transformStatus(status) {
      const statusInfo = {
        PENDING: {
          label: this.$t('pageBilling.table.statusLabels.PENDING'),
          color: 'is-warning',
        },
        PAID: {
          label: this.$t('pageBilling.table.statusLabels.PAID'),
          color: 'is-success',
        },
        REFUSED: {
          label: this.$t('pageBilling.table.statusLabels.REFUSED'),
          color: 'is-danger',
        },
        OVERDUE: {
          label: this.$t('pageBilling.table.statusLabels.OVERDUE'),
          color: 'is-danger',
        },
        CANCELED: {
          label: this.$t('pageBilling.table.statusLabels.CANCELED'),
          color: 'is-danger',
        },
      };

      return statusInfo[status];
    },
    sort(field, order) {
      Object.assign(this.filters, {
        sort: {
          field,
          order,
        },
      });
    },
    async getListBillings() {
      const {
        dates = [],
        ...restFilters
      } = this.filters;

      const [startDate, endDate] = dates;

      await this.getBillings({
        ...restFilters,
        startDate,
        endDate,
      });
    },
  },
  async beforeMount() {
    await this.getListBillings();
  },
});
</script>

<style lang="scss" scoped>
  .integration-billing{
    &__title{
      padding-bottom: 1rem;
    }
    &__content{
      width: 100%;
    }
  }
</style>
