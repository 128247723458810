<template>
  <ig-page-base>
    <div class="box categories__content">
      <div class="categories__title-box">
        <h2 class="title categories__title has-text-weight-semibold">
          {{ $t('storePages.product.categories.title') }}
        </h2>

        <b-button
          type="is-success"
          tag="router-link"
          :to="{ name: 'manage-category' }"
          :label="$t('storePages.product.categories.btnNewCategory')"
        ></b-button>
      </div>

      <hr />

      <b-table
        :loading="loading"
        :data="categories"
        striped
        hoverable
        scrollable
        detailed
        custom-detail-row
        :has-detailed-visible="hasChildren"
        @click="row => editCategory(row.id)"
        :row-class="() => 'categories__table-row'"
      >
        <template #empty>
          <ig-empty />
        </template>

        <b-table-column
          field="name"
          :label="$t('storePages.product.categories.table.header.category')"
          v-slot="props"
          sortable
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="enabled"
          :label="$t('storePages.product.categories.table.header.status')"
          centered
          sortable
          v-slot="props"
        >
          <span class="tag is-uppercase" :class="{
          'is-success': props.row.enabled,
          'is-danger':  !props.row.enabled,
          }">
            {{ $t(`storePages.product.categories.table.data.statusEnum.${props.row.enabled}`) }}
          </span>
        </b-table-column>

        <b-table-column
          width="100"
          v-slot="props"
          custom-key="actions"
        >
          <div class="buttons">
            <b-tooltip
              append-to-body
              :label="$t('storePages.product.categories.table.data.actions.edit')">
              <b-button
                class="categories__btn-action"
                type="is-default is-light"
                icon-left="pen"
                size="is-small"
                @click.stop="editCategory(props.row.id)">
              </b-button>
            </b-tooltip>
            <b-tooltip
              append-to-body
              :label="$t('storePages.product.categories.table.data.actions.delete')">
              <b-button
                class="categories__btn-action"
                type="is-danger is-light"
                icon-left="trash"
                size="is-small"
                @click.stop="deleteCategory(props.row)">
              </b-button>
            </b-tooltip>
          </div>
        </b-table-column>

        <template slot="detail" slot-scope="props">
          <tr v-for="item in props.row.children" :key="item.name">
            <td></td>
            <td>
              <div class="categories__children">
                <div class="categories__children-arrow"></div>
                <label>{{ item.name }}</label>
              </div>
            </td>
            <td class="has-text-centered">
              <span class="tag is-uppercase" :class="{
                'is-success': item.enabled,
                'is-danger':  !item.enabled,
              }">
                {{ $t(`storePages.product.categories.table.data.statusEnum.${item.enabled}`) }}
              </span>
            </td>
            <td class="has-text-centered">
              <div class="buttons">
                <b-tooltip
                  append-to-body
                  :label="$t('storePages.product.categories.table.data.actions.edit')">
                  <b-button
                    class="categories__btn-action"
                    type="is-default is-light"
                    icon-left="pen"
                    size="is-small"
                    @click.stop="editCategory(item.id)">
                  </b-button>
                </b-tooltip>
                <b-tooltip
                  append-to-body
                  :label="$t('storePages.product.categories.table.data.actions.delete')">
                  <b-button
                    class="categories__btn-action"
                    type="is-danger is-light"
                    icon-left="trash"
                    size="is-small"
                    @click.stop="deleteCategory(item)">
                  </b-button>
                </b-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </b-table>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase,
  IgEmpty,
  EventBus,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('categories');

export default Vue.extend({
  name: 'page-categories',
  components: {
    IgPageBase,
    IgEmpty,
  },
  computed: {
    ...mapState([
      'loading',
      'categories',
    ]),
  },
  methods: {
    ...mapActions([
      'getCategories',
      'removeCategory',
    ]),
    hasChildren(row) {
      return Array.isArray(row.children) && row.children.length > 0;
    },
    editCategory(id) {
      return this.$router.push({
        name: 'manage-category',
        params: {
          id,
        },
      });
    },
    deleteCategory(row) {
      return this.$buefy.dialog.confirm({
        title: String(this.$t('storePages.product.categories.deleteDialog.title')),
        message: String(this.$t('storePages.product.categories.deleteDialog.message', {
          name: row.name,
        })),
        confirmText: String(this.$t('storePages.product.categories.deleteDialog.confirm')),
        cancelText: String(this.$t('storePages.product.categories.deleteDialog.cancel')),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.removeCategory(row.id);
          await this.getCategories();

          EventBus.$emit('toast', {
            type: 'is-success',
            message: this.$t('storePages.product.categories.deleteDialog.successMessage'),
          });
        },
      });
    },
  },
  async beforeMount() {
    await this.getCategories();
  },
});
</script>

<style lang="scss">
  .categories{
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__children{
      display: flex;
      &-arrow{
        border-width: 0px 0px 1px 1px;
        border-style: solid;
        border-color: rgba(96,112,129,var(--tw-border-opacity));
        margin-right: 0.5rem;
        margin-top: 0.15rem;
        height: 0.75rem;
        width: 0.75rem;
        --tw-border-opacity:1;
      }
    }
    &__table-row{
      cursor: pointer;
      .categories__btn-action{
        visibility: hidden;
      }
      &:hover{
        .categories__btn-action{
          visibility: visible;
        }
      }
    }
  }
</style>
