import { RouteConfig } from 'vue-router';

export default {
  path: 'reset-password/:token',
  name: 'page-reset-password',
  component: () => import('./ResetPassword.vue'),
  meta: {
    title: 'Redefinir senha',
  },
} as RouteConfig;
