<template>
  <div class="ig-login-client">
    <div class="container">
      <b-field
        style="margin: 0"
        label="Pesquise o cliente para logar"
        label-position="inside">
        <ig-tree-select
          placeholder="Nome ou email"
          :async="true"
          :load-options="loadOptions"
          @input="updateValue"
          :disabled="getUser.adminLogged"
        />
        <p class="control" v-if="getUser.adminLogged">
          <b-button @click="back">Voltar para Integrai</b-button>
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
import { IgTreeSelect, Auth, Cookie } from '@integrai/ui';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('home');

export default {
  name: 'ig-login-client',
  components: {
    IgTreeSelect,
  },
  computed: {
    getUser() {
      return Auth.getUser();
    },
  },
  methods: {
    ...mapActions([
      'searchClient',
      'loginClient',
    ]),
    async loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        const clients = await this.searchClient(searchQuery);

        callback(null, clients.map((client) => ({
          id: client.id,
          label: `${client.name} - ${client.email}`,
        })));
      }
    },
    getBkUser() {
      return Cookie.get('bkUser');
    },
    async updateValue(id) {
      const user = this.getUser;

      if (user.isAdmin) {
        Cookie.set('bkUser', user);
      }

      const res = await this.loginClient(id);

      console.log('res', res);

      Auth.logout();
      Auth.updateUser({
        ...res,
        adminLogged: true,
      });
      window.location.reload();
    },
    back() {
      Auth.logout();
      Auth.updateUser(this.getBkUser());
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.ig-login-client{
  padding: 1rem;
  background-color: #22b2f0;
  .container{
    display: flex;
    align-items: center;
  }
}
</style>
