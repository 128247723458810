<template>
  <ig-page-base :loading="loading">
    <div class="box integration-contigency__content">
      <h2 class="title integration-contigency__title has-text-weight-semibold">
        {{ $t('pageContingency.title') }}
      </h2>

      <p class="content is-size-7">
        {{ $t('pageContingency.info') }}
      </p>

      <ig-empty
        v-if="isEmpty"
        :message="$t('pageContingency.empty')"></ig-empty>

      <section v-if="!isEmpty">
        <b-field
          :label="$t('pageContingency.form.category.label')"
          label-position="inside">
          <b-select
            v-model="form.category"
            :placeholder="$t('pageContingency.form.category.placeholder')"
            icon="sitemap"
            expanded
            @input="setModules"
          >
            <option
              v-for="category in contingency.categories"
              :value="category._id"
              :key="category._id"
            >
              {{ category.name }}
            </option>
          </b-select>
        </b-field>

        <b-field :label="$t('pageContingency.form.modules.label')">
          <ig-draggable
            :list="form.modules"
            :animation="200"
            ghost-class="moving-card"
            group="users"
            filter=".action-button"
            class="w-full max-w-xs"
            tag="ul"
          >
            <card
              v-for="(module, moduleIndex) in form.modules"
              :key="module._id"
              :index="moduleIndex"
              :module="module"></card>
          </ig-draggable>
        </b-field>

        <div class="buttons">
          <b-button
            class="is-uppercase integration-contigency__btn"
            :label="$t('pageContingency.btnSave')"
            :disabled="loading"
            :loading="loading"
            type="is-primary"
            expanded
            @click="save"
          ></b-button>
        </div>
      </section>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase, IgDraggable, IgEmpty, EventBus,
} from '@integrai/ui';
import card from './components/Card.vue';

const { mapState, mapActions } = createNamespacedHelpers('contingency');

export default Vue.extend({
  name: 'page-contingency',
  components: {
    IgPageBase,
    IgDraggable,
    IgEmpty,
    card,
  },
  data() {
    return {
      form: {
        modules: [],
      },
    };
  },
  computed: {
    ...mapState([
      'loading',
      'contingency',
    ]),
    isEmpty() {
      const {
        categories = [],
      } = this.contingency;
      return categories.length === 0;
    },
  },
  methods: {
    ...mapActions([
      'getContingency',
      'updateContigency',
    ]),
    setModules(categoryId) {
      const {
        categories = [],
        modules = [],
      } = this.contingency;

      const [category = {}] = categories || [];
      this.form.category = categoryId || category._id; // eslint-disable-line
      this.form.modules = [...modules[this.form.category] || []];
    },
    async save() {
      await this.updateContigency(this.form);
      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t('pageContingency.messageSuccess'),
      });
    },
  },
  async beforeMount() {
    await this.getContingency();
    this.setModules();
  },
});
</script>

<style lang="scss">
  .integration-contigency{
    &__title{
      padding-bottom: 1rem;
    }
    &__content{
      width: 50%;
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
    &__btn {
      margin-top: 2rem;
    }
  }

  .moving-card {
    opacity: 0.5;
    background: #f7fafc;
    border: 1px solid #4299e1;
  }
</style>
