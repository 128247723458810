

























import Vue from 'vue';
import Lodash from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { CategoryType } from '@/features/private/integrations/store/state.type';

const { mapState, mapMutations } = createNamespacedHelpers('integrations');

export default Vue.extend({
  name: 'integrations-header',
  computed: {
    ...mapState([
      'modules',
      'categories',
      'form',
    ]),
    listCategories() {
      const categories = Lodash.uniqBy(this.modules.map((module: any) => module.category), 'code');

      if (categories.length === 0) {
        return [];
      }

      return [
        {
          code: 'all',
          name: 'Todos',
        },
        ...categories,
      ];
    },
  },
  methods: {
    ...mapMutations([
      'setForm',
    ]),
    changeCategory(category: CategoryType) {
      // eslint-disable-next-line
      // @ts-ignore
      this.setForm({
        categorySelected: category.code,
      });
    },
  },
});
