<template>
  <div>
    <ig-spinner v-if="loading" style="width: 80px; margin: 0 auto" />
    <div class='timeline_wrapper' v-if="!loading">
      <div class='timeline_steps' id='steps'>
        <div class='timeline_step'
             v-for="(request, requestKey) in requests" :key="request.timestamp">
          <div class='timeline_number'
               :class="{
                'timeline_success': request.status === 'SUCCESS',
                'timeline_warning': request.status === 'CACHED',
                'timeline_error': request.status === 'ERROR'
          }">
            <b-icon
              :icon="['SUCCESS', 'CACHED'].includes(request.status) ? 'check' : 'times'"></b-icon>
          </div>
          <div class='timeline_info'>
            <div class="timeline_header">
              <p class='timeline_title'>
                <b-tag
                  :type="`${methodToTag(request.method)} is-light`"
                >{{ request.method }}</b-tag> {{ request.url }}
                <small
                  class="timeline_time"
                  data-balloon-pos="up"
                  data-balloon-length="none"
                  :aria-label="formatTime(request.timestamp).full">
                  {{ formatTime(request.timestamp).small }}
                </small>
                <b-tag
                  v-if="request.eventType"
                  :type="`is-primary`"
                  rounded
                >{{ request.eventType.toLowerCase() }}</b-tag>
              </p>
              <b-button
                type="is-primary"
                size="is-small"
                @click="expandEventRequest({ requestKey, eventIndex })">
                {{ request.expanded ? 'Ocultar' : 'Expandir' }}
              </b-button>
            </div>
            <div class='timeline_text'>
              <p>
                <code>base:</code> {{ request.baseUrl }}
                <code>status:</code> {{ request.statusCode }}
                <code>duração:</code> {{ formatDuration(request.response.time) }}
              </p>
              <p v-if="request.errorMessage" style="margin-top: 0.5rem;">
                <code>erro:</code> {{ request.errorMessage }}
              </p>
            </div>

            <div class="timeline_json" v-if="request.expanded">
              <div v-if="request.url === 'integrai-event'">
                <code>payload</code>
                <json-viewer :value="request.response.data"
                             copyable
                             boxed
                             sort>
                  <template #copy="props">{{ props.copied ? 'copiado' : 'copiar' }}</template>
                </json-viewer>
              </div>
              <div class="columns" v-else>
                <div class="column">
                  <code>request</code>
                  <json-viewer :value="request.request"
                               copyable
                               boxed
                               sort>
                    <template #copy="props">{{ props.copied ? 'copiado' : 'copiar' }}</template>
                  </json-viewer>
                </div>
                <div class="column">
                  <code>response</code>
                  <json-viewer :value="request.response"
                               copyable
                               boxed
                               sort>
                    <template #copy="props">{{ props.copied ? 'copiado' : 'copiar' }}</template>
                  </json-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import * as Moment from 'moment';
import { IgSpinner } from '@integrai/ui';
import { createNamespacedHelpers } from 'vuex';

const { mapMutations } = createNamespacedHelpers('event-history');

export default {
  props: ['loading', 'requests', 'eventIndex'],
  components: {
    IgSpinner,
    JsonViewer,
  },
  methods: {
    ...mapMutations([
      'expandEventRequest',
    ]),
    methodToTag(method) {
      return {
        GET: 'is-success',
        POST: 'is-warning',
        PUT: 'is-info',
        DELETE: 'is-danger',
      }[method.toUpperCase()];
    },
    formatTime(time) {
      return {
        small: Moment(time).locale('pt-br').fromNow(),
        full: Moment(time).format('DD/MM/YYYY HH:mm:ss'),
      };
    },
    formatDuration(time) {
      const mm = Moment.duration(
        String(time).length > 3 ? Math.floor(0.001 * time) : time,
        'ms',
      );

      return [
        mm.hours() ? `${mm.hours()}h` : null,
        mm.minutes() ? `${mm.minutes()}m` : null,
        mm.seconds() ? `${mm.seconds()}s` : null,
        mm.milliseconds() ? `${mm.milliseconds()}ms` : null,
      ].filter((a) => !!a).join(', ');
    },
  },
};
</script>

<style lang="scss">
.timeline_wrapper {
  display: flex;
  .timeline_steps {
    width: 100%;
  }

  .timeline_step {
    display: flex;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 15px;
      top: 32px;
      height: 0;
      width: 2px;
      background-color: #000;
    }

    .timeline_header {
      display: flex;
      justify-content: space-between;
    }

    .timeline_info {
      margin: 8px 0 20px;
      width: 100%;
    }

    .timeline_title {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 8px;
      .tag{
        margin-right: 5px;
      }
      .timeline_time{
        font-weight: normal;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 0.7rem;
      }
    }

    .timeline_text {
      font-size: 14px;
      padding-bottom: 0;
      margin: 0;
    }

    &:not(:last-child) {
      &:after {
        height: 100%;
      }
    }

    .timeline_json {
      margin-top: 0.5rem;
    }
  }

  .timeline_number {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #000;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 15px;
    font-weight: 600;
    margin-right: 14px;

    &.timeline_success {
      background-color: #bff6b3;
      border-color: #bff6b3;
    }

    &.timeline_warning {
      background-color: #fffbeb;
      border-color: #fffbeb;
    }

    &.timeline_error {
      background-color: #fbc0bc;
      border-color: #fbc0bc;
    }

    .icon {
      width: 16px;
      height: 16px;
      color: black;
    }
  }
}
</style>
