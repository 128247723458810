import StateType from './state.type';

export default {
  loading: false,
  loadingSave: false,
  flows: [],
  flow: {},
  modules: [],
  eventsEnum: [],
} as StateType;
