import StateType, { ContingencyType } from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setContingency(state: StateType, contingency: ContingencyType): void {
    state.contingency = contingency;
  },
};
