import { RouteConfig } from 'vue-router';

import PageStore from './PageStore.vue';
/* eslint-disable */
// @ts-ignore
import * as children from './pages/*/routes.ts';
/* eslint-enable */

export default {
  name: 'store',
  path: 'store',
  component: PageStore,
  children,
  redirect: {
    name: 'product-import-and-export',
  },
} as RouteConfig;
