import { ActionContext } from 'vuex';
import { Http } from '@integrai/ui';
import StateType from '@/features/private/integrations/store/state.type';

export default {
  async getCategories(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      commit('setCategories', await Http.get('module-categories'));
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async getIntegrations(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      commit('setModules', await Http.get('module/clients'));
      commit('setModulesList', await Http.get('module/list'));
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async getIntegration(context: ActionContext<StateType, {}>, params: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const urlModule = [
        'module',
        params.id,
        params.moduleConfigId,
      ]
        .filter((param) => !!param)
        .join('/');

      commit('setModule', await Http.get(urlModule));
      commit('setClient', await Http.get('client'));
      commit('toggleLoading', false);
    } catch (e) {
      commit('toggleLoading', false);
      throw e;
    }
  },
  async checkAndSaveAuth(context: ActionContext<StateType, {}>, form: any) {
    const { commit } = context || {};

    commit('toggleLoadingSave', true);

    try {
      await Http.post('module/check-auth', form);
      commit('toggleLoadingSave', false);
    } catch (err) {
      commit('toggleLoadingSave', false);
      throw err;
    }
  },
  async saveModule(context: ActionContext<StateType, {}>, form: any) {
    const { commit } = context || {};

    commit('toggleLoadingSave', true);

    try {
      await Http.post('module/client', form);
      commit('toggleLoadingSave', false);
    } catch {
      commit('toggleLoadingSave', false);
    }
  },
  async comingSoon(context: ActionContext<StateType, {}>, moduleId: string) {
    await Http.post(`module/coming-soon/${moduleId}`);
  },
  async createConfig(context: ActionContext<StateType, {}>, form: any) {
    const { commit } = context || {};

    commit('toggleLoadingSave', true);

    try {
      const res = await Http.post('module/new', form);
      commit('toggleLoadingSave', false);
      return res;
    } catch {
      commit('toggleLoadingSave', false);
      return null;
    }
  },
};
