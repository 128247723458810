<template>
  <b-modal
    v-model="open"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    close-button-aria-label="Close"
    aria-modal>
    <template>
      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="modal-card" style="width: 800px">
            <header class="modal-card-head">
              <p class="modal-card-title">
                {{ $t('pageFlow.manage.title') }}
              </p>
              <button
                type="button"
                class="delete"
                @click="closeModal()"/>
            </header>
            <section class="modal-card-body">
              <div class="columns">
                <div class="column is-6">
                  <validation-provider
                    rules="required"
                    :name="$t('pageFlow.filters.sourceModule.label')"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="$t('pageFlow.filters.sourceModule.label')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                      <b-select
                        :placeholder="$t('pageFlow.filters.sourceModule.placeholder')"
                        v-model="formFlow.moduleSourceConfig"
                        :disabled="loading"
                        expanded>
                        <option :value="null">
                          {{ $t('pageFlow.filters.sourceModule.all') }}
                        </option>
                        <option
                          v-for="module in modules"
                          :key="module.moduleConfigId"
                          :value="module.moduleConfigId">{{ module.name }}</option>
                      </b-select>
                    </b-field>
                  </validation-provider>
                </div>
                <div class="column">
                  <validation-provider
                    rules="required"
                    :name="$t('pageFlow.filters.targetModule.label')"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="$t('pageFlow.filters.targetModule.label')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                      <b-select
                        :placeholder="$t('pageFlow.filters.targetModule.placeholder')"
                        v-model="formFlow.moduleTargetConfig"
                        :disabled="loading"
                        expanded>
                        <option :value="null">
                          {{ $t('pageFlow.filters.targetModule.all') }}
                        </option>
                        <option
                          v-for="module in modules"
                          :key="module.moduleConfigId"
                          :value="module.moduleConfigId">{{ module.name }}</option>
                      </b-select>
                    </b-field>
                  </validation-provider>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <validation-provider
                    rules="required"
                    :name="$t('pageFlow.filters.event.label')"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="$t('pageFlow.filters.event.label')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                      <b-select
                        :placeholder="$t('pageFlow.filters.event.placeholder')"
                        v-model="formFlow.event"
                        :disabled="loading"
                        expanded>
                        <option :value="null">
                          {{ $t('pageFlow.filters.event.all') }}
                        </option>
                        <option
                          v-for="event in filterEvents"
                          :key="event.value"
                          :value="event.value">{{ event.label }}</option>
                      </b-select>
                    </b-field>
                  </validation-provider>
                </div>
                <div class="column">
                  <validation-provider
                    rules="required"
                    :name="$t('pageFlow.filters.sourceModule.label')"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-switch
                        v-model="formFlow.active"
                        type="is-primary">
                        {{ $t('pageFlow.filters.status.label') }}
                      </b-switch>
                    </b-field>
                  </validation-provider>
                </div>
              </div>

              <b-field
                :label="$t('pageFlow.manage.fields.functionCheck.label')"
                :message="$t('pageFlow.manage.fields.functionCheck.info')"
                label-position="inside">
                <b-input
                  type="textarea"
                  v-model="formFlow.functionCheck"
                  :placeholder="$t('pageFlow.manage.fields.functionCheck.placeholder')"
                />
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button
                :label="$t('pageFlow.manage.buttons.cancel')"
                :disabled="loadingSave"
                @click="closeModal()" />

              <b-button
                native-type="submit"
                :label="$t('pageFlow.manage.buttons.save')"
                :loading="loadingSave"
                :disabled="loadingSave"
                type="is-primary" />
            </footer>
          </div>
        </form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { EventBus } from '@integrai/ui';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('flows');

export default {
  name: 'ig-manage-flow',
  props: ['open'],
  data() {
    return {
      isActive: false,
      isOpen: this.open,
      formFlow: {},
    };
  },
  watch: {
    open(value) {
      this.isOpen = value;
    },
    flow: {
      handler() {
        this.formFlow = {
          ...this.flow,
        };
      },
      deep: true,
    },
  },
  computed: {
    ...mapState([
      'loading',
      'loadingSave',
      'eventsEnum',
      'modules',
      'flow',
    ]),
    filterEvents() {
      const events = this.eventsEnum || [];

      return events
        .map((event) => ({
          value: event,
          label: this.$t(`pageEventHistory.events.${event}`),
        }));
    },
  },
  methods: {
    ...mapActions([
      'saveFlow',
    ]),
    async onSubmit() {
      await this.saveFlow(this.formFlow);

      this.closeModal(true);
    },
    closeModal(reload) {
      this.$emit('close', reload);
      this.formFlow = {};
    },
  },
};
</script>
