var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ig-page-base',[_c('div',{staticClass:"box categories__content"},[_c('div',{staticClass:"categories__title-box"},[_c('h2',{staticClass:"title categories__title has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t(("storePages.product.categories.manage." + (_vm.isUpdate ? 'titleUpdate' : 'titleCreate'))))+" ")])]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"categories__form-header"},[_c('h2',{staticClass:"categories__form-title"},[_vm._v(" "+_vm._s(_vm.$t('storePages.product.categories.manage.fields.headerDefault'))+" ")]),_c('b-switch',{attrs:{"type":"is-primary"},model:{value:(_vm.form.enabled),callback:function ($$v) {_vm.$set(_vm.form, "enabled", $$v)},expression:"form.enabled"}},[_vm._v(" "+_vm._s(_vm.$t('storePages.product.categories.manage.fields.enabled.label'))+" ")])],1),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('storePages.product.categories.manage.fields.name.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"label":_vm.$t('storePages.product.categories.manage.fields.name.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)]}}],null,true)}),_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"label":_vm.$t('storePages.product.categories.manage.fields.parentCategory.label'),"label-position":"inside"}},[_c('ig-tree-select',{attrs:{"placeholder":_vm.$t(
                'storePages.product.categories.manage.fields.parentCategory.placeholder'
              ),"load-options":_vm.loadCategories,"options":_vm.listCategories,"clearable":"","searchable":""},model:{value:(_vm.form.parentId),callback:function ($$v) {_vm.$set(_vm.form, "parentId", $$v)},expression:"form.parentId"}})],1)],1),_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"label":_vm.$t('storePages.product.categories.manage.fields.description.label'),"label-position":"inside"}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"buttons categories__form-actions"},[_c('b-button',{attrs:{"tag":"router-link","to":{ name: 'categories' },"type":"is-default"}},[_vm._v(" "+_vm._s(_vm.$t('storePages.product.categories.manage.btnCancel'))+" ")]),_c('b-button',{attrs:{"native-type":"submit","type":"is-primary","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t(("storePages.product.categories.manage." + (_vm.isUpdate ? 'btnUpdate' : 'btnCreate'))))+" ")])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }