<template>
  <router-view/>
</template>

<script>
import { EventBus } from '@integrai/ui';

export default {
  created() {
    EventBus.$on('toast', (event) => {
      const { type = 'is-info', message = null } = event;
      if (message) {
        this.$buefy.toast.open({
          message: this.$t(message),
          type,
          position: 'is-bottom',
        });
      }
    });

    EventBus.$on('redirectToLogin', () => {
      window.location.href = `${process.env.VUE_APP_MANAGE_URL}/auth/login?return=${window.location.href}`;
    });
  },
};
</script>
