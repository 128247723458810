var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('hr'),_c('section',{staticClass:"section"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('pageMapping.manage.fields.type.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('pageMapping.manage.fields.type.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":_vm.$t('pageMapping.manage.fields.type.placeholder'),"disabled":_vm.loading,"expanded":""},on:{"input":function($event){return _vm.changeType()}},model:{value:(_vm.formMapping.type),callback:function ($$v) {_vm.$set(_vm.formMapping, "type", $$v)},expression:"formMapping.type"}},_vm._l((_vm.types),function(type){return _c('option',{key:type.id,domProps:{"value":type}},[_vm._v(_vm._s(_vm.$t(type.label)))])}),0)],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-5"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('pageMapping.manage.fields.moduleConfig.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('pageMapping.manage.fields.moduleConfig.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":_vm.$t('pageMapping.manage.fields.moduleConfig.placeholder'),"disabled":_vm.loading || !_vm.formMapping.type,"expanded":""},on:{"input":function($event){return _vm.changeLeftModuleConfig()}},model:{value:(_vm.formMapping.leftModuleConfig),callback:function ($$v) {_vm.$set(_vm.formMapping, "leftModuleConfig", $$v)},expression:"formMapping.leftModuleConfig"}},_vm._l((_vm.leftModulesConfigs),function(moduleConfig){return _c('option',{key:moduleConfig.id,domProps:{"value":moduleConfig}},[_vm._v(_vm._s(_vm.$t(moduleConfig.label)))])}),0)],1)]}}],null,true)}),(_vm.formMapping.leftModuleConfig &&
            _vm.formMapping.leftModuleConfig.customEvent && !_vm.leftManual)?_c('validation-provider',{attrs:{"rules":"required","name":_vm.identifierLabel(_vm.formMapping.leftModuleConfig.label)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.identifierLabel(_vm.formMapping.leftModuleConfig.label),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('ig-external-config',{key:_vm.formMapping.leftModuleConfig.id,attrs:{"moduleId":_vm.formMapping.leftModuleConfig.moduleId,"moduleConfigId":_vm.formMapping.leftModuleConfig.id,"customEvent":_vm.formMapping.leftModuleConfig.customEvent,"valueFormat":"object","placeholder":_vm.$t('pageMapping.manage.fields.identifier.placeholder'),"loadingText":_vm.$t('pageMapping.manage.fields.identifier.loading')},model:{value:(_vm.formMapping.leftIdentifier),callback:function ($$v) {_vm.$set(_vm.formMapping, "leftIdentifier", $$v)},expression:"formMapping.leftIdentifier"}})],1),(!_vm.leftManual)?_c('a',{staticClass:"help",on:{"click":function($event){return _vm.toggleLeftManual()}}},[_vm._v(" "+_vm._s(_vm.$t('pageMapping.manage.fields.identifier.notFound'))+" ")]):_vm._e()]}}],null,true)}):_c('div',[(_vm.formMapping.leftModuleConfig && _vm.formMapping.leftIdentifier)?[_c('validation-provider',{attrs:{"rules":"required","name":_vm.manualIdentifierLabel(_vm.formMapping.leftModuleConfig.label)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.manualIdentifierLabel(_vm.formMapping.leftModuleConfig.label),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":_vm.$t('pageMapping.manage.fields.identifier.manualPlaceholder')},model:{value:(_vm.formMapping.leftIdentifier.id),callback:function ($$v) {_vm.$set(_vm.formMapping.leftIdentifier, "id", $$v)},expression:"formMapping.leftIdentifier.id"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.manualIdentifierLabel(_vm.formMapping.leftModuleConfig.label)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.manualLabel(_vm.formMapping.leftModuleConfig.label),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":_vm.$t('pageMapping.manage.fields.identifier.manualLabelPlaceholder')},model:{value:(_vm.formMapping.leftIdentifier.label),callback:function ($$v) {_vm.$set(_vm.formMapping.leftIdentifier, "label", $$v)},expression:"formMapping.leftIdentifier.label"}})],1)]}}],null,true)}),(_vm.leftManual)?_c('a',{staticClass:"help",on:{"click":function($event){return _vm.toggleLeftManual()}}},[_vm._v(" "+_vm._s(_vm.$t('pageMapping.manage.fields.identifier.notFoundBack'))+" ")]):_vm._e()]:_vm._e()],2)],1),_c('div',{staticClass:"column is-2 has-text-centered"},[_c('img',{staticStyle:{"height":"50px"},attrs:{"src":require("@/assets/arrows.png"),"alt":"Setas ilustrando mapeamento"}})]),_c('div',{staticClass:"column is-5"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('pageMapping.filters.moduleConfig.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('pageMapping.filters.moduleConfig.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":_vm.$t('pageMapping.filters.moduleConfig.placeholder'),"disabled":_vm.loading || !_vm.formMapping.leftModuleConfig,"expanded":""},on:{"input":function($event){return _vm.changeRightModuleConfig()}},model:{value:(_vm.formMapping.rightModuleConfig),callback:function ($$v) {_vm.$set(_vm.formMapping, "rightModuleConfig", $$v)},expression:"formMapping.rightModuleConfig"}},_vm._l((_vm.rightModulesConfigs),function(moduleConfig){return _c('option',{key:moduleConfig.id,domProps:{"value":moduleConfig}},[_vm._v(_vm._s(_vm.$t(moduleConfig.label)))])}),0)],1)]}}],null,true)}),(_vm.formMapping.rightModuleConfig &&
              _vm.formMapping.rightModuleConfig.customEvent && !_vm.rightManual)?_c('validation-provider',{attrs:{"rules":"required","name":_vm.identifierLabel(_vm.formMapping.rightModuleConfig.label)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.identifierLabel(_vm.formMapping.rightModuleConfig.label),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('ig-external-config',{key:_vm.formMapping.rightModuleConfig.id,attrs:{"moduleId":_vm.formMapping.rightModuleConfig.moduleId,"moduleConfigId":_vm.formMapping.rightModuleConfig.id,"customEvent":_vm.formMapping.rightModuleConfig.customEvent,"valueFormat":"object","placeholder":_vm.$t('pageMapping.manage.fields.identifier.placeholder'),"loadingText":_vm.$t('pageMapping.manage.fields.identifier.loading')},model:{value:(_vm.formMapping.rightIdentifier),callback:function ($$v) {_vm.$set(_vm.formMapping, "rightIdentifier", $$v)},expression:"formMapping.rightIdentifier"}})],1),(!_vm.rightManual)?_c('a',{staticClass:"help",on:{"click":function($event){return _vm.toggleRightManual()}}},[_vm._v(" "+_vm._s(_vm.$t('pageMapping.manage.fields.identifier.notFound'))+" ")]):_vm._e()]}}],null,true)}):_c('div',[(_vm.formMapping.rightModuleConfig && _vm.formMapping.rightIdentifier)?[_c('validation-provider',{attrs:{"rules":"required","name":_vm.manualIdentifierLabel(_vm.formMapping.rightModuleConfig.label)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.manualIdentifierLabel(_vm.formMapping.rightModuleConfig.label),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":_vm.$t('pageMapping.manage.fields.identifier.manualPlaceholder')},model:{value:(_vm.formMapping.rightIdentifier.id),callback:function ($$v) {_vm.$set(_vm.formMapping.rightIdentifier, "id", $$v)},expression:"formMapping.rightIdentifier.id"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.manualIdentifierLabel(_vm.formMapping.rightModuleConfig.label)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.manualLabel(_vm.formMapping.rightModuleConfig.label),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":_vm.$t('pageMapping.manage.fields.identifier.manualLabelPlaceholder')},model:{value:(_vm.formMapping.rightIdentifier.label),callback:function ($$v) {_vm.$set(_vm.formMapping.rightIdentifier, "label", $$v)},expression:"formMapping.rightIdentifier.label"}})],1)]}}],null,true)}),(_vm.rightManual)?_c('a',{staticClass:"help",on:{"click":function($event){return _vm.toggleRightManual()}}},[_vm._v(" "+_vm._s(_vm.$t('pageMapping.manage.fields.identifier.notFoundBack'))+" ")]):_vm._e()]:_vm._e()],2)],1)])]),_c('footer',{staticClass:"field has-addons but has-addons-right"},[_c('b-button',{attrs:{"native-type":"submit","label":_vm.$t('pageMapping.manage.buttons.save'),"loading":_vm.loadingSave,"disabled":_vm.loadingSave,"type":"is-primary"}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }