<template>
  <section>
    <div class="integration-detail__text-box" v-if="module.textWhatIs">
      <strong class="integration-detail__text-title">
        {{ $t('pageIntegrationDetail.step_1.textWhatIs', { name: module.name }) }}
      </strong>
      <div class="integration-detail__text-content" v-html="formatHtml(module.textWhatIs)"></div>
    </div>

    <div class="integration-detail__text-box" v-if="module.textDoes && module.textDoes.length > 0">
      <strong class="integration-detail__text-title">
        {{ $t('pageIntegrationDetail.step_1.textDoes') }}
      </strong>
      <div class="integration-detail__text-content">
        <ul style="padding-inline-start: 40px;list-style: disc;">
          <li v-for="item in module.textDoes" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>

    <div class="integration-detail__text-box" v-if="module.textToDo">
      <strong class="integration-detail__text-title">
        {{ $t('pageIntegrationDetail.step_1.textToDo') }}
      </strong>
      <div class="integration-detail__text-content">
        <span v-html="formatHtml(module.textToDo)"></span>
        <br><br>
        Para ver como configurar, <a :href="module.linkHelp" target="blank">clique aqui</a>.
      </div>
    </div>

    <template v-if="!module.setupReleased">
      <b-message
        type="is-warning"
        icon="exclamation-circle"
        icon-size="is-small"
        has-icon>
        <span v-html="$t('pageIntegrationDetail.step_1.setupNotReleased')"></span>
      </b-message>
    </template>

    <template v-else-if="!module.setupRequested">
      <b-message
        type="is-success"
        icon="check-circle"
        icon-size="is-small"
        has-icon>
        <span v-html="$t('pageIntegrationDetail.step_1.setupText')"></span>
      </b-message>

      <b-button
        @click="openCheckout(module.product)"
        type="is-success">
        <span v-html="$t('pageIntegrationDetail.step_1.setupBtn')"></span>
      </b-button>

      <b-modal
        v-model="showCheckout"
        has-modal-card
        :can-cancel="['x']"
        trap-focus
        aria-role="dialog"
        aria-label="Checkout"
        aria-modal>
        <template>
          <ig-checkout-modal
            has-product
            @close="showCheckout = false" />
        </template>
      </b-modal>
    </template>

    <template v-else>
      <b-button
        v-if="canEnable && !module.categoryDisabled && canEnableForSubAccount"
        class="is-uppercase"
        :label="$t('pageIntegrationDetail.step_1.btn')"
        type="is-primary"
        @click="changeStep(2)"/>

      <template v-else>
        <b-message
          type="is-warning"
          icon="exclamation-circle"
          icon-size="is-small"
          has-icon>
        <span
          v-if="module.categoryDisabled"
          v-html="$t(`pageIntegrationDetail.step_1.categoryDisabled`, {
            category: module.categoryDisabled,
          })">
        </span>
        <span
          v-if="!module.canEnableForSubAccount"
          v-html="$t(`pageIntegrationDetail.step_1.canEnableForSubAccount`, {
            category: module.categoryDisabled,
          })">
        </span>
        <span v-else>
          {{ $t('pageIntegrationDetail.step_1.limitIntegrationsMessage') }}
        </span>
        </b-message>

        <router-link
          class="button is-success"
          :to="{ name: 'plans' }">
          {{ $t('pageIntegrationDetail.step_1.btnDisabled') }}
        </router-link>
      </template>
    </template>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import IgCheckoutModal from '../../plan/components/IgCheckoutModal.vue';

const { mapState, mapMutations } = createNamespacedHelpers('integrations');

export default {
  name: 'integration-step1',
  data() {
    return {
      showCheckout: false,
    };
  },
  computed: {
    ...mapState([
      'module',
    ]),
    canEnable() {
      return this.module.canEnable;
    },
    canEnableForSubAccount() {
      return this.module.canEnableForSubAccount;
    },
  },
  methods: {
    ...mapMutations([
      'changeStep',
    ]),
    formatHtml(html) {
      return unescape(html);
    },
    openCheckout(product) {
      this.$store.commit('plans/setCheckout', {
        product,
        plan: null,
      });
      this.showCheckout = true;
    },
  },
  components: {
    IgCheckoutModal,
  },
};
</script>

<style lang="scss">
  .integration-detail{
    &__text-box{
      padding: 1rem 0;
    }
    &__text-content{
      padding: 1rem;
    }
  }
</style>
