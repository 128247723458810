


































import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase } from '@integrai/ui';
import IntegrationStep1 from './steps/IntegrationStep1.vue';
import IntegrationStep2 from './steps/IntegrationStep2.vue';
import IntegrationStep3 from './steps/IntegrationStep3.vue';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('integrations');

export default Vue.extend({
  name: 'page-integration-steps',
  components: {
    IgPageBase,
    IntegrationStep1,
    IntegrationStep2,
    IntegrationStep3,
  },
  computed: {
    getTitle() {
      if (this.module && this.module.name) {
        return `${this.$t('pageIntegrationDetail.title')} ${this.module.name}`;
      }

      return '';
    },
    ...mapState([
      'loading',
      'module',
      'actualStep',
    ]),
  },
  methods: {
    ...mapMutations([
      'setModule',
      'changeStep',
    ]),
    ...mapActions([
      'getIntegration',
    ]),
  },
  async beforeMount(): Promise<void> {
    try {
      this.setModule({});
      const { params, query } = this.$route;
      try {
        await this.getIntegration({
          id: params.id,
          moduleConfigId: params.moduleConfigId,
        });
      } catch (e) {
        this.$router.push({ name: 'integrations' });
      }

      const {
        oauth: {
          attributeCodeName = null,
        } = {},
      } = this.module;

      if (this.module.enabled) {
        this.changeStep(3);
      } else if (attributeCodeName && query[attributeCodeName]) {
        this.changeStep(2);
      } else {
        this.changeStep(1);
      }
    } catch (e) {
      this.$router.back();
    }
  },
});
