<template>
  <section class="ig-checkout-success">
    <h2 class="title has-text-centered ig-checkout-success__title">
      <b-icon icon="check-circle"></b-icon>
      {{ $t(`${checkoutSuccess}.title`) }}
    </h2>
    <p class="content is-size-6 has-text-centered ig-checkout-success__info">
      {{ $t(`${checkoutSuccess}.info`) }}
    </p>
    <b-button
      class="is-uppercase ig-checkout-success__btn"
      type="is-success"
      @click="finish"
    >
      {{ checkout.method === 'BOLETO' ?
      $t(`${checkoutSuccess}.btnBoleto`) : $t(`${checkoutSuccess}.btnContinue`) }}
    </b-button>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('plans');

export default {
  name: 'ig-checkout-success',
  props: {
    boletoUrl: {
      type: String,
    },
  },
  computed: {
    ...mapState([
      'checkout',
    ]),
    checkoutSuccess() {
      return this.checkout.product ? 'pageCheckoutProductSuccess' : 'pageCheckoutPlanSuccess';
    },
  },
  methods: {
    finish() {
      if (this.boletoUrl && this.checkout.method === 'BOLETO') {
        window.open(this.boletoUrl, '_blank');
      }

      window.location.href = '/';
    },
  },
};
</script>

<style lang="scss" scoped>
  .ig-checkout-success{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title{
      color: #3dc616;
      margin-bottom: 3rem!important;
    }
    &__info{
      padding: 0 2rem;
      margin-bottom: 3rem!important;
    }
    &__btn{}
  }
</style>
