import { RouteConfig } from 'vue-router';

import PageContingency from './PageContingency.vue';

export default {
  path: 'contingency',
  name: 'contingency',
  component: PageContingency,
  meta: {
    title: 'Contigência',
  },
} as RouteConfig;
