import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleLoadingRemove(state: StateType, isLoading: boolean): void {
    state.loadingRemove = isLoading;
  },
  setAccount(state: StateType, account: any): void {
    state.account = Object.assign(state.account, account);
  },
};
