import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setPlans(state: StateType, plans: any[]): void {
    state.plans = plans;
  },
  setPlanConsumption(state: StateType, planConsumption: any): void {
    state.planConsumption = planConsumption;
  },
  setActualPlan(state: StateType, actualPlan: any): void {
    state.actualPlan = actualPlan;
  },
};
