import { ActionContext, Commit } from 'vuex';
import { Http } from '@integrai/ui';
import { ResetPasswordParams, StateType } from '../types/StateType';

export default {
  checkToken(context: ActionContext<StateType, Commit>, token: string) {
    const { commit } = context || {};

    commit('toggleLoading', true);

    return Http.post('/auth/forgot-password/validate-token', { token })
      .finally(() => commit('toggleLoading', false));
  },
  resetPassword(context: ActionContext<StateType, Commit>, form: ResetPasswordParams) {
    const { commit } = context || {};

    commit('toggleLoading', true);

    return Http.post('/auth/reset-password', form)
      .finally(() => commit('toggleLoading', false));
  },
};
