import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setPresignedUrl(state: StateType, presignedUrl: string): void {
    state.presignedUrl = presignedUrl;
  },
};
