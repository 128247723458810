import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleTableLoading(state: StateType, isLoading: boolean): void {
    state.tableLoading = isLoading;
  },
  setTotalBillings(state: StateType, totalBillings: number): void {
    state.totalBillings = totalBillings;
  },
  setNextCycleDate(state: StateType, nextCycleDate: Date): void {
    state.nextCycleDate = nextCycleDate;
  },
  setBillings(state: StateType, billings: any[]): void {
    state.billings = billings;
  },
  setPaymentMethodInfo(state: StateType, paymentMethodInfo: any): void {
    state.paymentMethodInfo = paymentMethodInfo;
  },
  setActualPlan(state: StateType, actualPlan: any): void {
    state.actualPlan = actualPlan;
  },
  setClientPlanInfo(state: StateType, clientPlanInfo: any): void {
    state.clientPlanInfo = clientPlanInfo;
  },
};
