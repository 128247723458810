<template>
  <section v-if="actualPlan.price > 0 && !isPaymentMethodShopify">
    <h2 class="title ig-payment-methods__title has-text-weight-semibold">
      {{ $t('pageFinance.paymentMethod.title') }}
    </h2>

    <div class="ig-payment-methods__methods">
      <div
        class="ig-payment-methods__methods-card"
        :class="{
           'ig-payment-methods__methods-card--active ig-payment-methods__methods-card--no-hover':
           isPaymentMethodDefault(paymentMethodsEnum.BOLETO)
          }"
      >
        <div class="ig-payment-methods__methods-inner">
          <div class="ig-payment-methods__methods-front">
            <div class="ig-payment-methods__methods-info">
              <b-icon
                size="is-large"
                class="ig-payment-methods__methods-icon"
                icon="barcode"></b-icon>
              <strong class="ig-payment-methods__methods-label">
                {{ $t('pageFinance.paymentMethod.methods.boleto') }}
              </strong>
            </div>

            <div
              v-if="isPaymentMethodDefault(paymentMethodsEnum.BOLETO)"
              class="ig-payment-methods__methods-tag-default tag is-info is-uppercase">
              {{ $t('pageFinance.paymentMethod.default') }}
            </div>
          </div>
          <div class="ig-payment-methods__methods-back">
            <b-button
              v-if="!isPaymentMethodDefault(paymentMethodsEnum.BOLETO)"
              type="is-success is-light"
              @click="changeDefault(paymentMethodsEnum.BOLETO)">
              {{ $t('pageFinance.paymentMethod.btnSetAsDefault') }}
            </b-button>
          </div>
        </div>
      </div>

      <div
        class="ig-payment-methods__methods-card"
        :class="{
           'ig-payment-methods__methods-card--active': isPaymentMethodDefault(
            paymentMethodsEnum.CREDIT_CARD,
           )
          }"
      >
        <div class="ig-payment-methods__methods-inner">
          <div class="ig-payment-methods__methods-front">
            <ig-credit-card
              v-if="creditCardInfo"
              :credit-card="creditCardInfo"
              disable-validation
              no-border
              no-color></ig-credit-card>
            <div class="ig-payment-methods__methods-info" v-else>
              <b-icon
                size="is-large"
                class="ig-payment-methods__methods-icon"
                icon="credit-card"></b-icon>
              <strong class="ig-payment-methods__methods-label">
                {{ $t('pageFinance.paymentMethod.methods.creditCard') }}
              </strong>
            </div>

            <div
              v-if="isPaymentMethodDefault(paymentMethodsEnum.CREDIT_CARD)"
              class="ig-payment-methods__methods-tag-default tag is-info is-uppercase">
              {{ $t('pageFinance.paymentMethod.default') }}
            </div>
          </div>
          <div class="ig-payment-methods__methods-back">
            <b-button
              class="ig-payment-methods__methods-btn"
              v-if="!creditCardInfo"
              @click="showFormCreditCard = true"
              type="is-primary is-light">
              {{ $t('pageFinance.paymentMethod.btnAddCreditCard') }}
            </b-button>
            <b-button
              class="ig-payment-methods__methods-btn"
              v-if="creditCardInfo"
              @click="showFormCreditCard = true"
              type="is-primary is-light">
              {{ $t('pageFinance.paymentMethod.btnChangeCreditCard') }}
            </b-button>
            <b-button
              class="ig-payment-methods__methods-btn"
              v-if="creditCardInfo && !isPaymentMethodDefault(paymentMethodsEnum.CREDIT_CARD)"
              type="is-success is-light"
              @click="changeDefault(paymentMethodsEnum.CREDIT_CARD)">
              {{ $t('pageFinance.paymentMethod.btnSetAsDefault') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showFormCreditCard"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal>
      <template>
        <ig-modal @close="showFormCreditCard = false">
          <h2 class="ig-payment-methods__modal-title subtitle">
            {{ $t('pageFinance.paymentMethod.creditCardInfo') }}
          </h2>
          <ig-form-payment-credit-card
            :loading="loading"
            :public-key="publicKey"
            :is-sandbox="paymentIsSandbox"
            @save="saveCreditCard"/>
        </ig-modal>
      </template>
    </b-modal>

    <b-modal
      v-model="showClientInfoForm"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal>
      <template>
        <ig-modal @close="showClientInfoForm = false">
          <h2 class="ig-payment-methods__modal-title subtitle">
            {{ $t('igPaymentClientInfo.title') }}
          </h2>
          <ig-payment-client-info-form
            :external-loading="loading"
            @save="saveDefaultPayment" />
        </ig-modal>
      </template>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgCreditCard, IgFormPaymentCreditCard, IgModal, EventBus,
} from '@integrai/ui';
import IgPaymentClientInfoForm
  from '@/components/ig-payment-client-info-form/IgPaymentClientInfoForm.vue';

const { mapState, mapActions } = createNamespacedHelpers('finance');

export default Vue.extend({
  name: 'ig-payment-methods',
  data() {
    return {
      publicKey: process.env.VUE_APP_PAYMENT_PUBLIC_KEY || '',
      paymentIsSandbox: JSON.parse(process.env.VUE_APP_PAYMENT_SANDBOX || false),
      paymentMethodsEnum: {
        BOLETO: 'BOLETO',
        CREDIT_CARD: 'CREDIT_CARD',
      },
      showFormCreditCard: false,
      showClientInfoForm: false,
      formDefault: {},
    };
  },
  components: {
    IgCreditCard,
    IgFormPaymentCreditCard,
    IgModal,
    IgPaymentClientInfoForm,
  },
  computed: {
    ...mapState([
      'loading',
      'paymentMethodInfo',
      'actualPlan',
    ]),
    creditCardInfo() {
      const {
        creditCard,
      } = this.paymentMethodInfo;

      if (!creditCard) {
        return null;
      }

      return {
        number: `**** **** **** ${creditCard.lastFourDigits}`,
        brand: creditCard.brand,
        holder: creditCard.holderName,
        expiration: {
          month: creditCard.expirationMonth,
          year: creditCard.expirationYear,
        },
      };
    },
  },
  methods: {
    ...mapActions([
      'getPaymentMethod',
      'changePaymentMethod',
    ]),
    isPaymentMethodShopify() {
      return this.paymentMethodInfo.paymentMethod === 'SHOPIFY';
    },
    isPaymentMethodDefault(paymentMethod) {
      return this.paymentMethodInfo.paymentMethod === paymentMethod;
    },
    changeDefault(method, creditCard = {}) {
      this.formDefault = {
        method,
        creditCard,
      };
      this.showClientInfoForm = true;
    },
    saveCreditCard(creditCard) {
      this.changeDefault(this.paymentMethodsEnum.CREDIT_CARD, creditCard);
      this.showFormCreditCard = false;
    },
    async saveDefaultPayment(clientInfo) {
      await this.changePaymentMethod({
        ...this.formDefault,
        clientInfo,
      });

      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t('pageFinance.paymentMethod.changeDefaultMessageSuccess'),
      });

      this.showClientInfoForm = false;
    },
  },
  async beforeMount() {
    await this.getPaymentMethod();
  },
});
</script>

<style lang="scss" scoped>
  .ig-payment-methods{
    &__title{
      padding-bottom: 1rem;
    }
    &__btn {
      margin-top: 2rem;
    }
    &__methods{
      display: flex;
      justify-content: space-around;
      margin: 2rem 0 4rem;
      @media screen and (max-width: 760px) {
        flex-direction: column;
        margin: 2rem 0;
      }
      &-card{
        width: 320px;
        height: 190px;
        perspective: 1000px;
        background-color: transparent;
        @media screen and (max-width: 760px) {
          margin-bottom: 1rem;
        }
        &:not(.ig-payment-methods__methods-card--no-hover):hover{
          .ig-payment-methods__methods-inner{
            transform: rotateY(180deg);
          }
          .ig-payment-methods__methods-front{
            visibility: hidden;
          }
        }
        &--active{
          .ig-payment-methods__methods-inner{
            border: 1px solid #22b2f0;
            color: #22b2f0;
          }
          .ig-payment-methods__methods-label{
            color: #22b2f0;
          }
        }
      }
      &-inner{
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        border: 1px dashed #bdbdbd;
        border-radius: 10px;
        color: #bdbdbd;
      }
      &-info{
        text-align: center;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-icon{
        height: 4rem;
        width: 4rem;
        font-size: 1.5rem;
      }
      &-label{
        color: #bdbdbd;
        font-size: 1.3rem;
      }
      &-front, &-back{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
      }
      &-front{
        background-color: #fff;
      }
      &-back{
        background-color: #fff;
        transform: rotateY(180deg);
        padding: 3rem;
      }
      &-btn{
        margin: .5rem 0;
      }
      &-tag-default{
        position: absolute;
        top: .5rem;
        left: .5rem;
      }
    }
    &__modal{
      &-title{
        padding-bottom: 1rem;
      }
    }
  }
</style>
