import { ActionContext, Commit } from 'vuex';
import { Http } from '@integrai/ui';
import { StateType } from '../types/StateType';

export default {
  checkToken(context: ActionContext<StateType, Commit>, token: string) {
    const { commit } = context || {};

    commit('toggleLoading', true);

    return Http.post('/auth/email-confirmation', { token })
      .finally(() => commit('toggleLoading', false));
  },
};
