<template>
  <section>
    <p>
      {{ $t('pageIntegrationDetail.step_2.introduction') }}
    </p>

    <div class="integration-detail__form">
      <h2 class="integration-detail__form-title sub-title">
        {{ $t('pageIntegrationDetail.step_2.formTitle') }}
      </h2>

      <b-field
        :label="$t('pageIntegrationDetail.step_3.fieldName.label')"
        label-position="inside">
        <b-input
          v-model="module.name"
          :placeholder="$t('pageIntegrationDetail.step_3.fieldName.placeholder')" />
      </b-field>

      <b-field
        :label="$t('pageIntegrationDetail.step_2.formEnabled')"
        label-position="inside"
        :type="enabled ? 'is-success' : 'is-danger'">
        <b-select v-model="enabled" expanded>
          <option :value="true">{{ $t('pageIntegrationDetail.step_3.enabledYes') }}</option>
          <option :value="false">{{ $t('pageIntegrationDetail.step_3.enabledNo') }}</option>
        </b-select>
      </b-field>

      <b-field
        :label="$t('pageIntegrationDetail.step_2.formMain')"
        label-position="inside">
        <b-select v-model="isMain" expanded>
          <option :value="true">{{ $t('pageIntegrationDetail.step_3.enabledYes') }}</option>
          <option :value="false">{{ $t('pageIntegrationDetail.step_3.enabledNo') }}</option>
        </b-select>
      </b-field>

      <ig-input-clipboard
        v-if="module.configs.webhookUrl"
        :label="$t('pageIntegrationDetail.step_3.webhook.title')"
        :message="$t('pageIntegrationDetail.step_3.webhook.introduction')"
        :text="module.configs.webhookUrl"
        :btnLabel="$t('pageIntegrationDetail.step_3.webhook.btnLabel')"
        :btnLabelCopied="$t('pageIntegrationDetail.step_3.webhook.btnLabelCopied')"
      />

      <div
        class="integration-detail__features"
        v-if="module.featuresDispatch && module.featuresDispatch.length > 0">
        <h2
          class="integration-detail__features-title sub-title"
          :class="{'has-text-danger': featuresDispatchFailed}"
        >
          {{ $t('pageIntegrationDetail.step_3.featuresDispatch.title') }}
        </h2>
        <p class="content is-size-7">
          <span v-html="$t('pageIntegrationDetail.step_3.featuresDispatch.info')"></span>
        </p>

        <div class="columns is-multiline">
          <div
            class="column is-6"
            v-for="feature in module.featuresDispatch"
            :key="feature.event"
          >
            <b-field :message="feature.info">
              <b-switch
                v-model="featuresDispatch[feature.event]"
                :true-value="true"
                :false-value="false"
                type="is-primary"
                passive-type="is-danger"
                outlined
                :rounded="false">{{ feature.label }}</b-switch>
            </b-field>
          </div>
        </div>

        <div class="help is-danger" v-if="featuresDispatchFailed">
          {{ $t('pageIntegrationDetail.step_3.featuresDispatch.errorRequired') }}
        </div>
      </div>

      <div
        class="integration-detail__features"
        v-if="module.featuresListener && module.featuresListener.length > 0">
        <h2
          class="integration-detail__features-title sub-title"
          :class="{'has-text-danger': featuresListenerFailed}"
        >
          {{ $t('pageIntegrationDetail.step_3.featuresListener.title') }}
        </h2>
        <p class="content is-size-7">
          <span v-html="$t('pageIntegrationDetail.step_3.featuresListener.info')"></span>
        </p>

        <div class="columns is-multiline">
          <div
            class="column is-6"
            v-for="feature in module.featuresListener"
            :key="feature.event"
          >
            <b-field :message="feature.info">
              <b-switch
                v-model="featuresListener[feature.event]"
                :true-value="true"
                :false-value="false"
                type="is-primary"
                passive-type="is-danger"
                outlined
                :rounded="false">{{ feature.label }}</b-switch>
            </b-field>
          </div>
        </div>

        <div class="help is-danger" v-if="featuresListenerFailed">
          {{ $t('pageIntegrationDetail.step_3.featuresListener.errorRequired') }}
        </div>

        <div v-if="rulesRerun.length > 0">
          <h3 class="integration-detail__features-title">
            {{ $t('pageIntegrationDetail.step_3.featuresRerun.title') }}
          </h3>

          <p class="content is-size-7">
            <span v-html="$t('pageIntegrationDetail.step_3.featuresRerun.info')"></span>
          </p>

          <div class="columns">
            <div class="column is-6"
                 v-for="rule in rulesRerun"
                 :key="rule.model">
              <b-field
                :label="rule.title"
                label-position="inside">
                <b-select v-model="featuresRerun[rule.model]" expanded>
                  <option value="DISABLE">
                    {{ $t('pageIntegrationDetail.step_3.featuresRerun.options.DISABLE') }}
                  </option>
                  <option value="ONLY_CREATE">
                    {{ $t('pageIntegrationDetail.step_3.featuresRerun.options.ONLY_CREATE') }}
                  </option>
                  <option value="ONLY_UPDATE">
                    {{ $t('pageIntegrationDetail.step_3.featuresRerun.options.ONLY_UPDATE') }}
                  </option>
                  <option value="BOTH">
                    {{ $t('pageIntegrationDetail.step_3.featuresRerun.options.BOTH') }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </div>

      <ig-form-schema
        v-if="formSchema"
        :schema="formSchema"
        :model="formConfigs"
        :validation="enabled"
        @submit="confirmSaveConfig">
        <template slot="actions">
          <b-button
            native-type="submit"
            class="is-uppercase"
            :label="$t(`pageIntegrationDetail.step_3.${module.enabled ? 'btnUpdate' : 'btnSave'}`)"
            :disabled="loadingSave"
            :loading="loadingSave"
            type="is-primary" />
        </template>
      </ig-form-schema>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgFormSchema, IgInputClipboard } from '@integrai/ui';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('integrations');

export default Vue.extend({
  name: 'integration-step3',
  components: {
    IgFormSchema,
    IgInputClipboard,
  },
  data() {
    return {
      enabled: false,
      isMain: false,
      featuresDispatch: {},
      featuresListener: {},
      featuresDispatchFailed: false,
      featuresListenerFailed: false,
      featuresRerun: {},
    };
  },
  computed: {
    ...mapState([
      'loadingSave',
      'module',
    ]),
    formConfigs() {
      const {
        _id: moduleId,
        name: moduleName,
        moduleConfigId,
        configs,
        oauth,
        authError,
      } = this.module;

      return {
        ...configs,
        moduleId,
        moduleConfigId,
        moduleName,
        configs,
        oauth,
        authError,
      };
    },
    formSchema() {
      if (!this.module || !this.module.formSchema) {
        return {};
      }

      if (this.module.enabled) {
        return {
          groupFields: [
            ...this.module.formSchemaAuth.groupFields,
            ...this.module.formSchema.groupFields,
          ],
        };
      }

      return this.module.formSchema;
    },
    rulesRerun() {
      const {
        featuresListener = [],
      } = this.module || {};

      return [
        {
          title: this.$t('pageIntegrationDetail.step_3.featuresRerun.rules.ORDER'),
          model: 'ORDER',
        },
        {
          title: this.$t('pageIntegrationDetail.step_3.featuresRerun.rules.PRODUCT'),
          model: 'PRODUCT',
        },
      ].filter((rule) => !!featuresListener.find((feat) => feat.event.includes(rule.model)));
    },
  },
  methods: {
    ...mapMutations([
      'changeStep',
    ]),
    ...mapActions([
      'getIntegration',
      'saveModule',
    ]),
    async callSaveModule(newConfigs) {
      const featuresDispatch = this.getFeatures('Dispatch');
      const featuresListener = this.getFeatures('Listener');

      if (!featuresDispatch && !featuresListener) {
        this.featuresDispatchFailed = true;
        this.featuresListenerFailed = true;
      }

      if (featuresListener || featuresDispatch) {
        const {
          oauth, //eslint-disable-line
          configs, //eslint-disable-line
          ...restConfigs
        } = newConfigs;

        await this.saveModule({
          enabled: this.enabled,
          isMain: this.isMain,
          module: this.module._id, //eslint-disable-line
          moduleConfigId: this.module.moduleConfigId,
          name: this.module.name,
          featuresListener: featuresListener || [],
          featuresDispatch: featuresDispatch || [],
          featuresRerun: this.featuresRerun,
          configs: {
            ...restConfigs,
          },
        });

        this.showSuccess();

        await this.$router.push({
          name: 'integrations',
        });
      }
    },
    getFeatures(type) {
      this[`features${type}Failed`] = false;

      const features = Object.keys(this[`features${type}`])
        .filter((feature) => this[`features${type}`][feature]);

      if (features.length > 0) {
        return features;
      }

      return false;
    },
    transformFeatures(features) {
      return features
        .map((feature) => ({
          [feature]: true,
        }))
        .reduce((a, b) => ({ ...a, ...b }), {});
    },
    confirmSaveConfig(configs) {
      if (this.module.enabled && !this.enabled) {
        return this.$buefy.dialog.confirm({
          title: String(this.$t('pageIntegrationDetail.step_2.modalConfirmDisabled.title')),
          message: String(this.$t('pageIntegrationDetail.step_2.modalConfirmDisabled.message', {
            moduleName: this.module.name,
          })),
          confirmText: String(this.$t('pageIntegrationDetail.step_2.modalConfirmDisabled.confirm')),
          cancelText: String(this.$t('pageIntegrationDetail.step_2.modalConfirmDisabled.cancel')),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.callSaveModule(configs),
        });
      }

      return this.callSaveModule(configs);
    },
    showSuccess() {
      this.$buefy.toast.open({
        type: 'is-success',
        message: String(this.$t('pageIntegrationDetail.step_3.endConfigurationMessage')),
        duration: 3000,
      });
    },
  },
  created() {
    this.$nextTick(() => {
      const {
        enabled, isMain, featuresSaved = {}, featuresRerun,
      } = this.module;
      const { featuresDispatch = [], featuresListener = [] } = featuresSaved;

      this.enabled = enabled;
      this.isMain = isMain;
      this.featuresDispatch = this.transformFeatures(featuresDispatch);
      this.featuresListener = this.transformFeatures(featuresListener);
      this.featuresRerun = featuresRerun || this.rulesRerun.map((rule) => ({
        [rule.model]: 'DISABLE',
      })).reduce((a, b) => ({ ...a, ...b }), {});
    });
  },
});
</script>

<style lang="scss">
  .integration-detail{
    &__form{
      padding: 2rem 0;
    }
    &__form-title{
      padding-bottom: 1rem;
    }
    &__features{
      margin: 2rem 0;
      &-title{
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }
</style>
