<template>
  <li>
    <div class="card ig-contingency-card__cards-item">
      <div class="card-content ig-contingency-card__cards-content">
        <div class="media ig-contingency-card__cards-info">
          <div class="media-left ig-contingency-card__cards-logo-container">
            <figure>
              <img :src="module.logo" :alt="module.name" class="ig-contingency-card__cards-logo" >
            </figure>
          </div>
          <div class="media-content">
            <p class="subtitle is-5">{{ module.name }}</p>
          </div>
        </div>

        <div class="ig-contingency-card__cards-count tag is-normal is-light is-success">
          {{ index + 1 }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'card',
  props: {
    module: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
  .ig-contingency-card{
    &__cards{
      &-item{
        background-color: #fff;
        border: 1px solid #e5e5e5;
        display: inline-block;
        overflow: hidden;
        width: 100%;
        margin-bottom: .5rem;
        transition: box-shadow 0.5s;
        border-radius: 10px;
        padding: .5rem 0;
        cursor: move;
      }
      &-content{
        padding: 1rem;
      }
      &-logo{}
      &-logo-container{
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8rem;
      }
      &-info{
        align-items: center;
        margin-bottom: 0!important;
      }
      &-count {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: 1px solid #2f9711;
      }
    }
  }
</style>
