import { ActionContext } from 'vuex';
import { Auth, Http } from '@integrai/ui';
import StateType from '@/features/private/settings/pages/account/store/state.type';

export default {
  async getAccount(context: ActionContext<StateType, {}>): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const account = await Http.get('client');
      commit('setAccount', {
        docType: 'cpf',
        ...account,
      });
      commit('toggleLoading', false);
      return account;
    } catch {
      commit('toggleLoading', false);
    }
    return null;
  },
  async updateAccount(context: ActionContext<StateType, {}>, account: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      await Http.put('client', account);
      Auth.updateUser({
        name: account.name,
      });
      commit('setAccount', account);
      commit('toggleLoading', false);
    } catch (e) {
      commit('toggleLoading', false);
      throw e;
    }
  },
  async removeAccount(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoadingRemove', true);

    try {
      await Http.delete('client', {
        timeout: 300000,
      });
      Auth.logout();
      commit('toggleLoadingRemove', false);
    } catch (e) {
      commit('toggleLoadingRemove', false);
      throw e;
    }
  },
};
