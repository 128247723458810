import { RouteConfig } from 'vue-router';

import PagePlan from './PagePlan.vue';

export default {
  path: 'plans',
  name: 'plans',
  component: PagePlan,
  meta: {
    title: 'Planos',
  },
} as RouteConfig;
