import { ActionContext } from 'vuex';
import { Http } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getBillings(context: ActionContext<StateType, {}>, filters: any): Promise<void> {
    const { commit } = context || {};

    commit('toggleTableLoading', true);

    try {
      const response: any = await Http.post('billing', filters);

      commit('setBillings', response.billings);
      commit('setTotalBillings', response.count);
      commit('setNextCycleDate', response.nextCycleDate);
      commit('toggleTableLoading', false);
    } catch {
      commit('toggleTableLoading', false);
    }
  },
  async getPaymentMethod(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      commit('setPaymentMethodInfo', await Http.get('plan/payment-method'));
      commit('setActualPlan', await Http.get('plan'));
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
  async changePaymentMethod(context: ActionContext<StateType, {}>, form: any): Promise<void> {
    const { commit, dispatch } = context || {};

    commit('toggleLoading', true);

    try {
      await Http.put('plan/payment-method', form);
      await dispatch('getPaymentMethod');
      commit('toggleLoading', false);
    } catch (e) {
      commit('toggleLoading', false);
      throw e;
    }
  },
  async getClientPlanInfo(context: ActionContext<StateType, {}>): Promise<void> {
    const { commit } = context || {};

    const { planInfo = {} }: any = await Http.get('client');
    commit('setClientPlanInfo', planInfo);
  },
  async updateClientPlanInfo(context: ActionContext<StateType, {}>, form: any): Promise<void> {
    const { dispatch } = context || {};

    await Http.put('plan/plan-info', form);
    await dispatch('getClientPlanInfo');
  },
};
