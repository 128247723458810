<template>
  <section v-if="!loading && data">
    <ig-form-schema
      ref="formSchema"
      :schema="formSchema"
      :model="data"
      @submit="save">
      <template slot="actions">
        <b-button
          native-type="submit"
          class="is-uppercase"
          :label="$t('igPaymentClientInfo.form.btnSave')"
          type="is-primary"
          :loading="externalLoading"
          :disabled="externalLoading"
          expanded></b-button>
      </template>
    </ig-form-schema>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { IgFormSchema, IgFormSchemaAddress } from '@integrai/ui';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('clientInfo');

export default {
  name: 'ig-payment-client-info-form',
  props: {
    externalLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uf: null,
      data: null,
    };
  },
  computed: {
    ...mapState([
      'loading',
      'clientInfo',
    ]),
    formSchema() {
      return {
        class: 'columns is-multiline',
        fields: [
          {
            name: 'name',
            component: 'input',
            containerClass: 'column is-full',
            fieldAttrs: {
              label: this.$t('igPaymentClientInfo.form.name.label'),
            },
            attrs: {
              icon: 'user',
            },
            validation: {
              name: this.$t('igPaymentClientInfo.form.name.label'),
              rules: 'required',
            },
          },
          {
            name: 'docType',
            component: 'select',
            containerClass: 'column is-5',
            fieldAttrs: {
              label: this.$t('igPaymentClientInfo.form.docType.label'),
            },
            attrs: {
              icon: 'id-card-alt',
              expanded: true,
              'data-checkout': 'docType',
              placeholder: this.$t('igPaymentClientInfo.form.docType.placeholder'),
            },
            validation: {
              name: this.$t('igPaymentClientInfo.form.docType.label'),
              rules: 'required',
            },
            options: [
              {
                label: this.$t('igPaymentClientInfo.form.docType.types.CPF'),
                value: 'cpf',
              },
              {
                label: this.$t('igPaymentClientInfo.form.docType.types.CNPJ'),
                value: 'cnpj',
              },
            ],
          },
          {
            name: 'docNumber',
            component: 'doc-number',
            componentPrefix: 'ig',
            containerClass: 'column is-7',
            fieldAttrs: {
              label: this.$t('igPaymentClientInfo.form.docNumber.label'),
            },
            attrs: {
              icon: 'id-card',
              docType: 'cnpj',
              'data-checkout': 'docNumber',
            },
            validation: {
              name: this.$t('igPaymentClientInfo.form.docNumber.label'),
              rules: 'required|cpfcnpj',
            },
            modelProps: {
              docType: 'docType',
            },
          },
          {
            name: 'stateRegistration',
            component: 'state-registration',
            componentPrefix: 'ig',
            containerClass: 'column is-full',
            isHidden: (model) => model.docType === 'cpf',
            fieldAttrs: {
              label: this.$t('pageAccount.infoFields.form.stateRegistration.label'),
            },
            attrs: {
              icon: 'id-card',
              class: 'control',
            },
            listeners: {
              changeUf: (newUf) => {
                this.uf = newUf;
              },
            },
            validation: {
              name: this.$t('pageAccount.infoFields.form.stateRegistration.label'),
              rules: `required|ie:${this.uf}`,
            },
          },
          {
            name: 'planInfo.financeEmails',
            component: 'taginput',
            containerClass: 'column is-full',
            fieldAttrs: {
              label: this.$t('igPaymentClientInfo.form.planInfo.financeEmails.label'),
            },
            attrs: {
              icon: 'envelope',
              placeholder: this.$t('igPaymentClientInfo.form.planInfo.financeEmails.placeholder'),
              ellipsis: true,
            },
            validation: {
              name: this.$t('igPaymentClientInfo.form.planInfo.financeEmails.label'),
              rules: 'required|emailList',
            },
          },
          ...IgFormSchemaAddress(this, this.data, true),
        ],
      };
    },
  },
  methods: {
    ...mapActions([
      'getClientInfo',
    ]),
    ...mapMutations([
      'toggleLoading',
    ]),
    save(clientInfo) {
      this.$emit('save', clientInfo);
    },
  },
  async beforeMount() {
    await this.getClientInfo();
    this.data = {
      docType: 'cpf',
      address: {},
      ...this.clientInfo,
    };
    this.toggleLoading(false);
  },
  components: {
    IgFormSchema,
  },
};
</script>
