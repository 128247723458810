<template>
  <ig-page-base :loading="loading">
    <div class="box integration-account__content">
      <h2 class="title integration-account__title has-text-weight-semibold">
        {{ $t('pageAccount.title') }}
      </h2>

      <ig-form-schema
        v-if="!loading"
        :schema="customSchema"
        :model="account"
        @submit="save"
      >
        <template slot="actions">
          <b-button
            native-type="submit"
            class="is-uppercase integration-account__btn"
            :label="$t('pageAccount.btnSave')"
            :disabled="loading"
            :loading="loading"
            type="is-primary"
            expanded></b-button>
        </template>
      </ig-form-schema>

      <div class="integration-account__remove">
        <h2 class="subtitle integration-account__remove-title">
          {{ $t('pageAccount.remove.title') }}
        </h2>

        <b-checkbox v-model="checkRemove">
          <div class="integration-account__remove-term"
                v-html="$t('pageAccount.remove.term')"></div>
        </b-checkbox>

        <b-button
          @click="remove"
          native-type="submit"
          class="is-uppercase integration-account__remove-btn"
          :label="$t('pageAccount.remove.btnRemove')"
          :disabled="!checkRemove || loadingRemove"
          :loading="loadingRemove"
          type="is-danger"
          expanded></b-button>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase, IgFormSchema, EventBus, IgFormSchemaAddress,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('account');

export default Vue.extend({
  name: 'page-account',
  components: {
    IgPageBase,
    IgFormSchema,
  },
  data() {
    return {
      uf: null,
      checkRemove: false,
    };
  },
  computed: {
    customSchema() {
      return {
        groupFields: [
          {
            title: this.$t('pageAccount.infoFields.title'),
            fields: [
              {
                name: 'name',
                component: 'input',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.name'),
                },
                attrs: {
                  icon: 'user',
                },
                validation: {
                  name: this.$t('pageAccount.infoFields.form.name'),
                  rules: 'required',
                },
              },
              {
                name: 'storeUrl',
                component: 'input',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.storeUrl'),
                },
                attrs: {
                  icon: 'link',
                },
                validation: {
                  name: this.$t('pageAccount.infoFields.form.storeUrl'),
                  rules: 'required|checkUrl',
                },
              },
              {
                name: 'email',
                component: 'input',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.email'),
                },
                attrs: {
                  icon: 'envelope',
                },
                validation: {
                  name: this.$t('pageAccount.infoFields.form.email'),
                  rules: 'required|email|uniqueField:email,client',
                },
              },
              {
                name: 'docType',
                component: 'select',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.docType.label'),
                },
                attrs: {
                  icon: 'id-card-alt',
                  expanded: true,
                  placeholder: this.$t('pageAccount.infoFields.form.docType.placeholder'),
                },
                options: [
                  {
                    label: this.$t('pageAccount.infoFields.form.docType.types.CPF'),
                    value: 'cpf',
                  },
                  {
                    label: this.$t('pageAccount.infoFields.form.docType.types.CNPJ'),
                    value: 'cnpj',
                  },
                ],
              },
              {
                name: 'docNumber',
                component: 'doc-number',
                componentPrefix: 'ig',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.docNumber.label'),
                },
                attrs: {
                  icon: 'id-card',
                  docType: 'cnpj',
                  'data-checkout': 'docNumber',
                },
                validation: {
                  name: this.$t('pageAccount.infoFields.form.docNumber.label'),
                  rules: 'cpfcnpj',
                },
                modelProps: {
                  docType: 'docType',
                },
              },
              {
                name: 'stateRegistration',
                component: 'state-registration',
                componentPrefix: 'ig',
                isHidden: (model) => model.docType === 'cpf',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.stateRegistration.label'),
                },
                attrs: {
                  icon: 'id-card',
                  class: 'control',
                },
                listeners: {
                  changeUf: (newUf) => {
                    this.uf = newUf;
                  },
                },
                validation: {
                  name: this.$t('pageAccount.infoFields.form.stateRegistration.label'),
                  rules: `required|ie:${this.uf}`,
                },
              },
              {
                name: 'phone',
                component: 'input',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.phone'),
                },
                attrs: {
                  icon: 'phone',
                  'v-mask': "'cellphone'",
                },
                validation: {
                  name: this.$t('pageAccount.infoFields.form.phone'),
                  rules: 'min:14',
                },
              },
              {
                name: 'apiKey',
                component: 'input-clipboard',
                componentPrefix: 'ig',
                attrs: {
                  label: this.$t('pageAccount.infoFields.form.apiKey'),
                  text: this.account.apiKey,
                  inputId: 'apiKey',
                },
              },
              {
                name: 'secretKey',
                component: 'input-clipboard',
                componentPrefix: 'ig',
                attrs: {
                  label: this.$t('pageAccount.infoFields.form.secretKey'),
                  text: this.account.secretKey,
                  inputType: 'password',
                  inputId: 'secretKey',
                },
              },
              {
                name: 'enableMarketing',
                component: 'select',
                fieldAttrs: {
                  label: this.$t('pageAccount.infoFields.form.enableMarketing.label'),
                },
                attrs: {
                  icon: 'envelope',
                  expanded: true,
                },
                options: [
                  {
                    value: false,
                    label: this.$t('pageAccount.infoFields.form.enableMarketing.options.no'),
                  },
                  {
                    value: true,
                    label: this.$t('pageAccount.infoFields.form.enableMarketing.options.yes'),
                  },
                ],
              },
            ],
          },
          {
            title: this.$t('pageAccount.addressFields.title'),
            fields: IgFormSchemaAddress(this, {}),
            class: 'columns is-multiline',
          },
        ],
      };
    },
    ...mapState([
      'loading',
      'loadingRemove',
      'account',
    ]),
  },
  methods: {
    ...mapActions([
      'getAccount',
      'updateAccount',
      'removeAccount',
    ]),
    async save(account) {
      await this.updateAccount(account);
      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t('pageAccount.messageSuccess'),
      });
    },
    remove() {
      return this.$buefy.dialog.confirm({
        title: String(this.$t('pageAccount.remove.confirmDialog.title')),
        message: String(this.$t('pageAccount.remove.confirmDialog.message')),
        confirmText: String(this.$t('pageAccount.remove.confirmDialog.confirm')),
        cancelText: String(this.$t('pageAccount.remove.confirmDialog.cancel')),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.callRemoveAccount(),
      });
    },
    async callRemoveAccount() {
      await this.removeAccount();

      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t('pageAccount.remove.messageSuccess'),
      });

      setTimeout(() => {
        window.location.href = 'https://integrai.com.br';
      }, 1000);
    },
  },
  async beforeMount() {
    await this.getAccount();
  },
});
</script>

<style lang="scss">
  .integration-account{
    &__title{
      padding-bottom: 1rem;
    }
    &__content{
      width: 50%;
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
    &__btn {
      margin-top: 2rem;
    }
    &__remove{
      margin-top: 4rem;
      &-title{
        font-weight: bold;
        color: #f43122;
      }
      &-term{
        padding-bottom: 2rem;
        line-height: 2;
        font-size: 0.9rem;
        strong{
          font-size: 1rem;
          color: #f43122;
        }
      }
    }
  }
</style>
