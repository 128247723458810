<template>
  <ig-page-base>
    <div class="box integration-events-history__content">
      <h2 class="title integration-events-history__title has-text-weight-semibold">
        {{ $t('pageEventHistory.title') }}
      </h2>

      <p class="content is-size-7" v-html="$t('pageEventHistory.info')"></p>

      <form @submit.prevent="getListEvents">
        <div class="columns">
          <div class="column">
            <b-field
              :label="$t('pageEventHistory.filters.rangeDate.label')"
              label-position="inside">
              <b-datepicker
                :placeholder="$t('pageEventHistory.filters.rangeDate.placeholder')"
                v-model="filters.dates"
                :disabled="loading"
                locale="pt-BR"
                range>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageEventHistory.filters.sourceModule.label')"
              label-position="inside">
              <b-select
                :placeholder="$t('pageEventHistory.filters.sourceModule.placeholder')"
                v-model="filters.sourceModule"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageEventHistory.filters.sourceModule.all') }}
                </option>
                <option
                  v-for="module in modules"
                  :key="module.moduleConfigId || module._id"
                  :value="module.moduleConfigId || module._id">{{ module.name }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageEventHistory.filters.targetModule.label')"
              label-position="inside">
              <b-select
                :placeholder="$t('pageEventHistory.filters.targetModule.placeholder')"
                v-model="filters.targetModule"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageEventHistory.filters.targetModule.all') }}
                </option>
                <option
                  v-for="module in modules"
                  :key="module.moduleConfigId || module._id"
                  :value="module.moduleConfigId || module._id">{{ module.name }}</option>
              </b-select>
            </b-field>
          </div>

        </div>

        <div class="columns">
          <div class="column">
            <b-field
              :label="$t('pageEventHistory.filters.event.label')"
              label-position="inside">
              <b-select
                :placeholder="$t('pageEventHistory.filters.event.placeholder')"
                v-model="filters.event"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageEventHistory.filters.event.all') }}
                </option>
                <option
                  v-for="event in filterEvents"
                  :key="event.value"
                  :value="event.value">{{ event.label }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageEventHistory.filters.identifier')"
              label-position="inside">
              <b-input
                v-model="filters.identifier"
                :disabled="loading"
                expanded>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageEventHistory.filters.status.label')"
              label-position="inside">
              <b-select
                v-model="filters.status"
                :disabled="loading"
                expanded>
                <option :value="null">
                  {{ $t('pageEventHistory.filters.status.options.all') }}
                </option>
                <option value="PROCESSING">
                  {{ $t('pageEventHistory.filters.status.options.processing') }}
                </option>
                <option value="DUPLICATED">
                  {{ $t('pageEventHistory.filters.status.options.duplicated') }}
                </option>
                <option value="SUCCESS">
                  {{ $t('pageEventHistory.filters.status.options.success') }}
                </option>
                <option value="ERROR">
                  {{ $t('pageEventHistory.filters.status.options.error') }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('pageEventHistory.filters.limit')"
              label-position="inside">
              <b-select
                v-model="filters.limit"
                :disabled="loading"
                expanded>
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div class="field has-addons but has-addons-right">
          <div class="buttons">
            <b-button
              class="is-uppercase"
              :label="$t('pageEventHistory.filters.btnReset')"
              type="is-default"
              @click="cleanFilters" />
            <b-button
              native-type="submit"
              class="is-uppercase"
              :label="$t('pageEventHistory.filters.btnSearch')"
              :disabled="loading"
              :loading="loading"
              type="is-primary" />
          </div>
        </div>
      </form>

      <hr />

      <div class="table-containerx">
        <b-table
          :loading="loading"
          :data="events"
          backend-sorting
          default-sort="createdAt"
          default-sort-direction="desc"
          paginated
          backend-pagination
          @page-change="onPageChange"
          :total="totalEvents"
          :per-page="filters.limit"
          :current-page.sync="filters.page"
          @sort="sort"
          striped
          hoverable
          scrollable
          detailed
          detail-key="id"
          @details-open="viewRequests"
        >
          <template #empty>
            <ig-empty />
          </template>

          <template #detail="props">
            <ig-event-timeline
              :key="props.row.id"
              :eventIndex="events.findIndex((event) => event.id === props.row.id)"
              :loading="props.row.loadingRequests"
              :requests="props.row.requests"
            />
          </template>

          <b-table-column
            field="createdAt"
            :label="$t('pageEventHistory.table.createdAt')"
            sortable
            width="40"
            centered
            v-slot="props"
          >
            {{ props.row.createdAt | date('DD/MM/YYYY HH:mm:ss') }}
          </b-table-column>

          <b-table-column
            field="sourceModule"
            :label="$t('pageEventHistory.table.sourceModule')"
            sortable
          >
            <template #header="{ column }">
              <span class="integration-events-history__nowrap">
                {{ column.label }}
              </span>
            </template>
            <template v-slot="props">
              {{ getSourceModuleName(props.row) }}
            </template>
          </b-table-column>

          <b-table-column
            field="targetModule"
            :label="$t('pageEventHistory.table.targetModule')"
            sortable
          >
            <template #header="{ column }">
              <span class="integration-events-history__nowrap">
                {{ column.label }}
              </span>
            </template>
            <template v-slot="props">
              {{ getTargetModuleName(props.row) }}
            </template>
          </b-table-column>

          <b-table-column
            width="200"
            field="event"
            :label="$t('pageEventHistory.table.event')"
            sortable
            v-slot="props"
          >
          <span class="integration-events-history__nowrap">
            <span style="display: block;">
              {{ $t(`pageEventHistory.events.${props.row.event}`) }}
            </span>
            <span class="tag is-warning has-text-weight-bold" v-if="props.row.originalEvent">
              <small>
                Original: {{ $t(`pageEventHistory.events.${props.row.originalEvent}`) }}
              </small>
            </span>
          </span>
          </b-table-column>

          <b-table-column
            field="identifier"
            :label="$t('pageEventHistory.table.identifier')"
            sortable
            centered
            v-slot="props"
          >
          <span v-if="props.row.attributeIdentifier && props.row.identifier">
            <small style="display: block;">{{ props.row.attributeIdentifier }}:</small>
            <span class="tag is-primary has-text-weight-bold">
             {{ props.row.identifier }}
            </span>
          </span>
          </b-table-column>

          <b-table-column
            field="status"
            :label="$t('pageEventHistory.table.status')"
            sortable
            centered
            v-slot="props"
          >
          <span class="tag is-uppercase is-light" :class="{
          'is-warning': ['PROCESSING', 'DUPLICATED'].includes(props.row.status),
          'is-success': props.row.status === 'SUCCESS',
          'is-danger':  props.row.status === 'ERROR',
          }">
            {{ $t(`pageEventHistory.table.statusEnum.${props.row.status}`) }}
          </span>
          </b-table-column>

          <b-table-column
            field="errorMessage"
            :label="$t('pageEventHistory.table.errorMessage')"
          >
            <template #header="{ column }">
              <span class="integration-events-history__nowrap">
                {{ column.label }}
              </span>
            </template>
            <template v-slot="props">
              {{ props.row.errorMessage }}
            </template>

          </b-table-column>

          <b-table-column
            width="200"
            :label="$t('pageEventHistory.table.actions')"
            centered
          >
            <template v-slot="props">
              <div class="buttons">
                <b-field>
                  <b-tooltip
                    :label="$t('pageEventHistory.table.actionsToottips.copy')">
                    <b-button
                      size="is-small"
                      icon-right="copy"
                      type="is-success"
                      class="btn-copy" :data-clipboard-text="props.row.eventId"
                    />
                  </b-tooltip>
                  <b-tooltip
                    :label="$t('pageEventHistory.table.actionsToottips.retry')">
                    <b-button
                      size="is-small"
                      icon-right="redo"
                      type="is-warning"
                      :disabled="props.row.status !== 'ERROR' || props.row.loadingRerun"
                      :loading="props.row.loadingRerun"
                      @click="tryRerunEvent(props)"
                    />
                  </b-tooltip>
                </b-field>
              </div>
            </template>

          </b-table-column>
        </b-table>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import ClipboardJS from 'clipboard';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgEmpty, EventBus } from '@integrai/ui';
import IgEventTimeline from './IgEventTimeline.vue';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('event-history');

export default Vue.extend({
  name: 'page-event-history',
  data() {
    return {
      filters: {
        limit: 10,
        page: 1,
        sortField: 'createdAt',
        sortOrder: 'desc',
      },
    };
  },
  components: {
    IgPageBase,
    IgEmpty,
    IgEventTimeline,
  },
  computed: {
    ...mapState([
      'loading',
      'totalEvents',
      'eventsEnum',
      'events',
      'modules',
    ]),
    filterEvents() {
      const events = this.eventsEnum || [];

      return events
        .map((event) => ({
          value: event,
          label: this.$t(`pageEventHistory.events.${event}`),
        }));
    },
  },
  methods: {
    ...mapActions([
      'getEventsEnum',
      'getEventHistory',
      'getModules',
      'rerunEvent',
      'getEventRequests',
    ]),
    ...mapMutations([
      'toggleLoadingRerun',
      'setEventRequests',
    ]),
    getSourceModuleName(row) {
      const {
        sourceModule,
        sourceModuleConfig,
      } = row;

      return sourceModuleConfig && sourceModuleConfig?.name
        ? sourceModuleConfig.name : sourceModule.name;
    },
    getTargetModuleName(row) {
      const {
        targetModule,
        targetModuleConfig,
      } = row;

      if (targetModuleConfig && targetModuleConfig?.name) {
        return targetModuleConfig.name;
      }

      if (targetModule) {
        return targetModule.name;
      }

      return '---';
    },
    cleanFilters() {
      this.filters = {
        limit: 10,
        page: 1,
        sortField: 'createdAt',
        sortOrder: 'desc',
      };
    },
    sort(sortField, sortOrder) {
      Object.assign(this.filters, {
        sortField,
        sortOrder,
      });

      this.getListEvents();
    },
    onPageChange(page) {
      Object.assign(this.filters, {
        page,
      });

      this.getListEvents();
    },
    async tryRerunEvent(props) {
      this.toggleLoadingRerun({
        eventIndex: props.index,
        isLoading: true,
      });

      await this.rerunEvent(props.row.eventId);

      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t('pageEventHistory.retrySuccessMessage'),
      });

      this.toggleLoadingRerun({
        eventIndex: props.index,
        isLoading: false,
      });

      await this.getListEvents();
    },
    async getListEvents() {
      const {
        dates = [],
        ...restFilters
      } = this.filters;

      const [startDate, endDate] = dates;

      await this.getEventHistory({
        ...restFilters,
        startDate,
        endDate,
      });
    },
    async viewRequests(row) {
      const eventIndex = this.events.findIndex((event) => event.id === row.id);

      this.setEventRequests({
        loadingRequests: false,
        eventIndex,
        requests: [],
      });

      if (row.requests.length === 0) {
        const requests = await this.getEventRequests({
          eventId: row.eventId,
          moduleSourceConfigId: row.sourceModuleConfig.id,
          moduleTargetConfigId: row.targetModuleConfig?.id,
        });

        this.setEventRequests({
          loadingRequests: false,
          eventIndex,
          requests,
        });
      }
    },
  },
  async beforeMount() {
    await this.getEventsEnum();
    await this.getModules();
    await this.getListEvents();
  },
  mounted() {
    // eslint-disable-next-line no-new
    const clipboard = new ClipboardJS('.btn-copy');
    clipboard.on('success', () => {
      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t('pageEventHistory.copySuccessMessage'),
      });
    });
  },
});
</script>

<style lang="scss">
  .integration-events-history{
    &__title{
      padding-bottom: 1rem;
    }

    &__content{
      width: 100%;
      max-width: 1200px;

      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }

    &__btn {
      margin-top: 2rem;
    }

    &__nowrap{
      white-space: nowrap;
    }
  }
</style>
