import { RouteConfig } from 'vue-router';

export default {
  path: 'forgot-password',
  name: 'page-forgot-password',
  component: () => import('./ForgotPassword.vue'),
  meta: {
    title: 'Redefinir senha',
  },
} as RouteConfig;
