







































































import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { ModuleType } from '@/features/private/integrations/store/state.type';
import { Auth, EventBus } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('integrations');

export default Vue.extend({
  name: 'integrations-header',
  computed: {
    ...mapState([
      'form',
      'modules',
    ]),
    modulesFiltered(): ModuleType[] {
      return this.modules
        .filter((module: ModuleType) => {
          const { categorySelected } = this.form;

          if (categorySelected && categorySelected !== 'all' && module.category) {
            return categorySelected === module.category.code;
          }

          return true;
        })
        .filter((module: ModuleType) => {
          const { query } = this.form;

          if (query) {
            return module.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          }

          return true;
        });
    },
  },
  methods: {
    ...mapActions([
      'comingSoon',
    ]),
    planBlocked() {
      const {
        plan,
      } = Auth.getUser();

      return plan.daysFreeTier !== null && plan.daysFreeTier <= 0;
    },
    openModule(module: any) {
      if (this.planBlocked()) {
        return this.$router.push({ name: 'plans' });
      }

      if (module.comingSoon) {
        return this.$buefy.dialog.confirm({
          title: String(this.$t('pageIntegration.card.comingSoon.confirmDialog.title')),
          message: String(this.$t('pageIntegration.card.comingSoon.confirmDialog.message', {
            moduleName: module.name,
          })),
          confirmText: String(this.$t('pageIntegration.card.comingSoon.confirmDialog.confirm')),
          cancelText: String(this.$t('pageIntegration.card.comingSoon.confirmDialog.cancel')),
          type: 'is-info',
          hasIcon: true,
          onConfirm: () => {
            // eslint-disable-next-line no-underscore-dangle
            this.comingSoon(module._id);
            EventBus.$emit('toast', {
              type: 'is-success',
              message: String(this.$t('pageIntegration.card.comingSoon.successMessage')),
            });
          },
        });
      }

      if (!module.moduleConfigId) {
        // eslint-disable-next-line no-underscore-dangle
        console.log('module._id', module._id);
        // eslint-disable-next-line no-underscore-dangle
        return this.$emit('openNewConfig', module._id);
      }

      return this.$router.push({
        name: 'integrations-detail',
        params: {
          // eslint-disable-next-line no-underscore-dangle
          id: module._id,
          moduleConfigId: module.moduleConfigId,
        },
      });
    },
  },
});
