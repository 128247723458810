<template>
  <b-navbar shadow class="ig-nav-bar" wrapper-class="ig-nav-bar__container container">
    <template slot="brand" class="ig-nav-bar__brand">
      <b-navbar-item tag="router-link" :to="{ name: 'home' }">
        <img
          class="ig-nav-bar__logo"
          src="@/assets/logo.png"
          alt="Integrai"
        >
      </b-navbar-item>
    </template>

    <template slot="start">
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'integrations' }"
        class="ig-nav-bar__menu-item"
      >
        {{ $t('navBar.menu.integrations') }}
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'flows' }"
        class="ig-nav-bar__menu-item"
      >
        {{ $t('navBar.menu.flows') }}
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'mappings' }"
        class="ig-nav-bar__menu-item"
      >
        {{ $t('navBar.menu.mappings') }}
      </b-navbar-item>
<!--      <b-navbar-item-->
<!--        tag="router-link"-->
<!--        :to="{ name: 'store' }"-->
<!--        class="ig-nav-bar__menu-item"-->
<!--      >-->
<!--        {{ $t('navBar.menu.store') }}-->
<!--      </b-navbar-item>-->
    </template>

    <template slot="end" v-if="user">
      <b-navbar-dropdown right>
        <template slot="label">
          <span>
            {{ formatUserName(user.name) }} <small style="display: block">{{ user.email }}</small>
          </span>
        </template>
        <b-navbar-item class="ig-nav-bar__menu-plan" href="#">
          <label class="ig-nav-bar__menu-plan-label has-text-weight-bold is-uppercase">
            {{ $t('navBar.user.plan') }}
          </label>
          <span class="ig-nav-bar__menu-plan-plan tag is-success is-small is-uppercase is-light">
            {{ user.plan.name }}
          </span>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'account' }">
          {{ $t('navBar.user.account') }}
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'plan-and-consumption' }"
          v-if="!user.plan.isMarketplacePlan">
          {{ $t('navBar.user.billing') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'event-history' }">
          {{ $t('navBar.user.events') }}
        </b-navbar-item>
        <b-navbar-item @click="logout">
          {{ $t('navBar.user.logout') }}
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { Auth } from '@integrai/ui';

export default {
  name: 'ig-header',
  computed: {
    user() {
      return Auth.getUser();
    },
  },
  methods: {
    formatUserName(userName) {
      const [name] = userName ? userName.split(' ') : [''];
      return name;
    },
    logout() {
      Auth.logout();
      window.location.href = process.env.VUE_APP_MANAGE_URL;
    },
  },
};
</script>

<style lang="scss">
  .ig-nav-bar{
    &__container{
      .navbar-brand{
        align-items: center;
      }
    }
    &__logo{
      height: 90px;
      max-height: 90px!important;
    }
    &__menu-plan{
      border-bottom: 1px solid #e2e8ef;
      padding-bottom: 1rem!important;
      margin-bottom: 1rem;
      &-label{
        color: #a2aab5;
      }
      &-plan{
        margin-left: 0.5rem;
      }
    }
    &__menu-item{
      align-self: center;
      margin: 0 1rem;
      &.router-link-exact-active,
      &.router-link-active,
      &.is-active{
        border-bottom: 3px solid #22b2f0;
      }
    }
  }
</style>
