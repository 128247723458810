import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleLoadingCheckout(state: StateType, loadingCheckout: boolean): void {
    state.loadingCheckout = loadingCheckout;
  },
  toggleLoadingVoucher(state: StateType, loadingVoucher: boolean): void {
    state.loadingVoucher = loadingVoucher;
  },
  toggleIsShopifyCustomer(state: StateType, isShopifyCustomer: boolean): void {
    state.isShopifyCustomer = isShopifyCustomer;
  },
  setPlans(state: StateType, plans: any[]): void {
    state.plans = plans;
  },
  setActualPlan(state: StateType, actualPlan: any): void {
    state.actualPlan = actualPlan;
  },
  setMethods(state: StateType, methods: any[]): void {
    state.methods = methods;
  },
  setCheckout(state: StateType, checkout: any): void {
    state.checkout = {
      ...state.checkout,
      ...checkout,
    };
  },
};
