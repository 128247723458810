import { RouteConfig } from 'vue-router';
/* eslint-disable */
// @ts-ignore
import * as children from './pages/*/routes.ts';
/* eslint-enable */

export default {
  path: 'order',
  name: 'order',
  component: { template: '<router-view></router-view>' },
  children,
  redirect: {
    name: 'product-import-and-export',
  },
} as RouteConfig;
