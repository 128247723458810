<template>
  <section class="integration-page-plan">
    <h2 class="title has-text-weight-semibold has-text-centered">
      {{ $t('pagePlan.title') }}
    </h2>

    <p class="content is-size-6 has-text-centered">
      {{ $t('pagePlan.info') }}
    </p>

    <b-message
      type="is-warning"
      icon="exclamation-circle"
      icon-size="is-small"
      v-if="isShopifyCustomer"
      has-icon>
      <span v-html="$t('pagePlan.shopifyWarning')"></span>
    </b-message>

    <div class="integration-pricing" v-if="plans.length > 0">
      <div class="integration-pricing__pricing-wrapper">
        <div class="integration-pricing__plans-specs is-hidden-mobile is-hidden-touch">
          <div
            v-for="spec in specs"
            :key="spec.fieldRestriction"
            class="integration-pricing__spec-item">
            <span
              data-balloon-pos="up"
              :data-balloon-length="spec.tooltipLarge ? 'large': 'none'"
              :aria-label="spec.tooltip">
              {{ spec.label }}
              <b-icon :icon="spec.icon"></b-icon>
              </span>
          </div>
        </div>
        <div class="integration-pricing__plans-wrapper">
          <div
            v-for="plan in allPlans"
            :key="plan._id"
            class="integration-pricing__plan-inner">
            <h2 class="integration-pricing__plan-title">
              {{ plan.name }}
            </h2>

            <div v-if="plan.slug === 'enterprise'" class="integration-pricing__plan-price-wrapper">
              <span class="integration-pricing__plan-period">
                {{ $t('pagePlan.specs.costExtraEvent.unlimited') }}
              </span>
            </div>

            <div v-else class="integration-pricing__plan-price-wrapper">
              <span class="integration-pricing__plan-price" v-if="isShopifyCustomer">
                <span class="integration-pricing__plan-price-symbol">U$</span>
                <span>{{ plan.priceUsd }}</span>
              </span>
              <span class="integration-pricing__plan-price" v-else>
                <span class="integration-pricing__plan-price-symbol">R$</span>
                <span v-if="plan.price > 0">
                {{plan.price | finance(0, ',') }}
                </span>
                <span v-else>
                  {{ plan.price  }}
                </span>
              </span>
              <span class="integration-pricing__plan-period">mensal</span>
            </div>

            <div class="integration-pricing__plans-specs">
              <div
                v-for="spec in specs"
                :key="spec.fieldRestriction"
                class="integration-pricing__spec-item">
                <span class="is-hidden-desktop">
                  {{ spec.label }}
                </span>
                <span class="integration-pricing__plan-info">
                  <span v-if="spec.isDaysFree">
                    <span v-if="plan.daysFreeTier > 0">
                      {{ plan.daysFreeTier }}{{ $t('pagePlan.specs.daysFree.suffix') }}
                    </span>
                    <span v-else>
                      {{ $t('pagePlan.specs.daysFree.always') }}
                    </span>
                  </span>
                  <span v-if="spec.isFinance">
                    <span
                      v-if="spec.fieldRestriction === 'maxRequest' && plan.slug === 'enterprise'">
                      +
                    </span>
                    <span
                      v-if="spec.fieldRestriction === 'maxIntegration' &&
                      plan.slug === 'enterprise'">
                      +
                    </span>
                    <span
                      v-if="plan.slug === 'enterprise' &&
                      spec.fieldRestriction === 'costExtraEvent'">
                      {{ $t('pagePlan.specs.costExtraEvent.unlimited') }}
                    </span>
                    <span v-else>
                      <span v-if="spec.fieldRestriction === 'costExtraEvent'">
                        {{ isShopifyCustomer ? 'U$' : 'R$' }}
                      </span>
                      <span v-if="spec.fieldRestriction === 'costExtraEvent'">
                        {{ plan.restrictions[isShopifyCustomer ?
                        'costExtraEventUsd' : 'costExtraEvent'] | finance(2, '', '.') }}
                      </span>
                      <span v-else>
                        {{ plan.restrictions[spec.fieldRestriction] | finance(0, '', '.') }}
                      </span>
                    </span>
                  </span>
                  <span v-if="spec.isBoolean">
                    <b-icon
                      :type="plan.restrictions[spec.fieldRestriction] ? 'is-success' : 'is-danger'"
                      :icon="plan.restrictions[spec.fieldRestriction] ? 'check' : 'times'"></b-icon>
                  </span>
                </span>
              </div>
              <div class="integration-pricing__spec-item">
                <b-button
                  v-if="showBtnSubscribe(plan)"
                  type="is-primary is-uppercase"
                  :loading="loadingCheckout"
                  :disabled="loadingCheckout"
                  @click="choosePlan(plan)"
                >
                  {{ $t('pagePlan.specs.action.btnSubscribe') }}
                </b-button>
                <span
                  class="has-text-centered"
                  v-if="actualPlan.migrationPlanSlug === plan.slug">
                  <span class="tag is-success is-uppercase is-light is-outlined">
                    {{ $t('pagePlan.specs.action.migrationPlan') }}
                  </span>
                  <small class="is-block integration-pricing__migration-text">
                    {{ $t('pagePlan.specs.action.migrationPlanText') }}
                  </small>
                </span>
                <span
                  class="tag is-primary is-uppercase is-light is-outlined"
                  v-if="plan.slug === actualPlan.slug && !isExpiredPlan">
                  {{ $t('pagePlan.specs.action.subscribed') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showCheckout"
      has-modal-card
      :can-cancel="['x']"
      trap-focus
      aria-role="dialog"
      aria-label="Checkout"
      aria-modal>
      <template>
        <ig-checkout-modal @close="showCheckout = false" />
      </template>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { Auth } from '@integrai/ui';
import IgCheckoutModal from './components/IgCheckoutModal.vue';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('plans');

export default Vue.extend({
  name: 'page-plan',
  data() {
    return {
      showCheckout: false,
      specs: [
        {
          label: this.$t('pagePlan.specs.maxModules.label'),
          tooltip: this.$t('pagePlan.specs.maxModules.tooltip'),
          tooltipLarge: true,
          icon: 'question-circle',
          fieldRestriction: 'maxIntegration',
          isFinance: true,
        },
        {
          label: this.$t('pagePlan.specs.maxRequest.label'),
          tooltip: this.$t('pagePlan.specs.maxRequest.tooltip'),
          tooltipLarge: true,
          icon: 'question-circle',
          fieldRestriction: 'maxRequest',
          isFinance: true,
        },
        {
          label: this.$t('pagePlan.specs.retry.label'),
          tooltip: this.$t('pagePlan.specs.retry.tooltip'),
          tooltipLarge: true,
          icon: 'question-circle',
          fieldRestriction: 'hasRetry',
          isBoolean: true,
        },
        {
          label: this.$t('pagePlan.specs.multiCd.label'),
          tooltip: this.$t('pagePlan.specs.multiCd.tooltip'),
          tooltipLarge: true,
          icon: 'question-circle',
          fieldRestriction: 'hasMultiCd',
          isBoolean: true,
        },
        {
          label: this.$t('pagePlan.specs.marketplace.label'),
          tooltip: this.$t('pagePlan.specs.marketplace.tooltip'),
          tooltipLarge: true,
          icon: 'question-circle',
          fieldRestriction: 'hasMarketplace',
          isBoolean: true,
        },
        {
          label: this.$t('pagePlan.specs.support.label'),
          tooltip: this.$t('pagePlan.specs.support.tooltip'),
          tooltipLarge: true,
          icon: 'question-circle',
          fieldRestriction: 'hasSupport',
          isBoolean: true,
        },
        {
          label: this.$t('pagePlan.specs.costExtraEvent.label'),
          tooltip: this.$t('pagePlan.specs.costExtraEvent.tooltip'),
          tooltipLarge: true,
          icon: 'question-circle',
          fieldRestriction: 'costExtraEvent',
          isFinance: true,
        },
        {
          label: this.$t('pagePlan.specs.daysFree.label'),
          tooltip: this.$t('pagePlan.specs.daysFree.tooltip'),
          icon: 'question-circle',
          isDaysFree: true,
        },
      ],
    };
  },
  computed: {
    ...mapState([
      'loading',
      'loadingCheckout',
      'plans',
      'actualPlan',
      'isShopifyCustomer',
    ]),
    allPlans() {
      if (this.isShopifyCustomer) {
        return this.plans.filter((plan) => plan.slug !== 'enterprise');
      }
      return this.plans;
    },
    isExpiredPlan() {
      const user = Auth.getUser();
      const { daysFreeTier } = user.plan;

      if (this.isShopifyCustomer && daysFreeTier > 0) {
        return true;
      }

      return daysFreeTier !== null && daysFreeTier <= 0;
    },
  },
  methods: {
    ...mapMutations([
      'setCheckout',
    ]),
    ...mapActions([
      'checkIsShopifyCustomer',
      'getPlans',
      'subscribeShopify',
    ]),
    showBtnSubscribe(plan) {
      if (this.isExpiredPlan) {
        return true;
      }

      const isActualPlan = plan.slug === this.actualPlan.slug;
      if (this.actualPlan.migrationPlanSlug) {
        return plan.price > this.actualPlan.price && !isActualPlan;
      }

      return !isActualPlan;
    },
    async choosePlan(plan) {
      if (this.isShopifyCustomer) {
        const data = await this.subscribeShopify(plan);
        if (data?.url) {
          window.location.href = data?.url;
        }
      } else if (plan.price < this.actualPlan.price) {
        this.$buefy.dialog.confirm({
          title: String(this.$t('pagePlan.modalConfirmDowngrade.title')),
          message: String(this.$t('pagePlan.modalConfirmDowngrade.message')),
          confirmText: String(this.$t('pagePlan.modalConfirmDowngrade.confirm')),
          cancelText: String(this.$t('pagePlan.modalConfirmDowngrade.cancel')),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.openCheckout(plan),
        });
      } else {
        this.openCheckout(plan);
      }
    },
    openCheckout(plan) {
      this.setCheckout({
        plan,
        product: null,
      });
      this.showCheckout = true;
    },
  },
  async beforeMount() {
    await this.checkIsShopifyCustomer();
    await this.getPlans();
  },
  components: {
    IgCheckoutModal,
  },
});
</script>

<style lang="scss">
@import "~balloon-css/src/balloon";

.integration-page-plan{
  max-width: 1088px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
}
.integration-pricing{
  width: 120%;
  margin-left: -10%;
  position: relative;
  &__pricing-wrapper{
    display: flex;
  }
  &__plans-specs{}
  &__plans-wrapper{
    display: flex;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      border: 0;
    }
    .integration-pricing__spec-item{
      justify-content: center;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }
    .integration-pricing__plans-specs{
      border-top: 1px solid #e6e4e4;
      margin-top: 0;
    }
  }
  &__plan-inner{
    min-width: 214px;
    border-right: 1px solid #e5e5e5;
    background-color: #fff;
    &:hover{
      box-shadow: inset rgba(143,160,241,0.2) 0 0 0 1px, rgba(213,220,247,0.59) 0 10px 20px;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 2rem;
      border: 1px solid #e5e5e5!important;
    }
    &:first-child{
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child{
      border-right: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  &__plan-title{
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0 1rem;
    color: #778ba0;
    border-bottom: 1px solid #e6e4e4;
    padding: 1rem 0;
    height: 64px;
  }
  &__plan-price-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 16px;
    height: 123px;
  }
  &__plan-price-symbol{
    position: absolute;
    left: -1.8rem;
    top: 0.8rem;
    font-size: 1.2rem;
    color: #22b2f0;
  }
  &__plan-price{
    font-size: 3em;
    white-space: normal;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #22b2f0;
    position: relative;
  }
  &__plan-period{
    font-size: .8em;
    color: #778ba0;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  &__plans-specs{
    margin-top: 189px;
    flex: auto;
  }
  &__spec-item{
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    &:nth-child(even){
      background-color: #f9f6f6;
    }
    @media screen and (max-width: 1024px) {
      height: 90px;
    }
  }
  &__migration-text{
    font-size: .7rem;
  }
  &__plan-info{
    font-weight: bold;
    font-size: 1.3rem;
    @media screen and (max-width: 1024px) {
      margin-top: .5rem;
    }
  }
}

[aria-label][data-balloon-pos][data-balloon-length="none"]:after {
  white-space: nowrap!important;
}

.button.is-primary {
  background-color: #22b2f0;
  &:hover{
    background-color: #078bc3;
  }
}
</style>
