import { Route, RouteRecord } from 'vue-router';

interface MetaTag {
  [key: string]: string;
}

export default class RouteManager {
  public pageSufix = 'Integrai';

  private to: Route;

  private from: Route;

  private next: Function;

  private oldTags: Element[] | undefined;

  private nearestWithTitle: RouteRecord | undefined;

  private nearestWithMeta: RouteRecord | undefined;

  private previousNearestWithMeta: RouteRecord | undefined;

  public constructor(to: Route, from: Route, next: Function) {
    this.to = to;
    this.from = from;
    this.next = next;
  }

  private getCompletePageTitle(pageTitle: string) {
    return `${pageTitle} | ${this.pageSufix}`;
  }

  private getNearestWithTitle() {
    return this.to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);
  }

  private getNearestWithMeta() {
    return this.to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);
  }

  private getPreviousNearestWithMeta() {
    return this.from.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);
  }

  private setRouteWithMeta() {
    this.nearestWithTitle = this.getNearestWithTitle();
    this.nearestWithMeta = this.getNearestWithMeta();
    this.previousNearestWithMeta = this.getPreviousNearestWithMeta();
  }

  private getPageTitle(
    nearestWithTitle: RouteRecord | undefined,
    previousNearestWithMeta: RouteRecord | undefined,
  ) {
    const titleOrMetaObject = nearestWithTitle || previousNearestWithMeta;
    const { meta } = titleOrMetaObject || {};
    const { title } = meta || {};

    return this.getCompletePageTitle(title);
  }

  private removeOldMetaTags() {
    this.oldTags = Array.from(document.querySelectorAll('[data-vue-router-controlled]'));

    this.oldTags.map((el) => {
      if (el && el.parentNode) {
        return el.parentNode.removeChild(el);
      }

      return el;
    });
  }

  private createNewMetaTags() {
    if (
      !this.nearestWithMeta
      || !this.nearestWithMeta.meta
      || !this.nearestWithMeta.meta.metaTags
    ) {
      return false;
    }

    return this.nearestWithMeta.meta.metaTags.map((tagDef: MetaTag) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
      .forEach((tag: Node) => document.head.appendChild(tag));
  }

  public beforeEach() {
    this.setRouteWithMeta();

    // If a route with a title was found, set the document (page) title to that value.
    document.title = this.getPageTitle(this.nearestWithTitle, this.previousNearestWithMeta);

    // Remove any stale meta tags from the document using the key attribute we set below.
    this.removeOldMetaTags();

    // Skip rendering meta tags if there are none.
    if (!this.nearestWithMeta) return this.next();

    // Turn the meta tag definitions into actual elements in the head.
    this.createNewMetaTags();

    return this.next();
  }
}
