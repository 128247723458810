














import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('integrations');

export default Vue.extend({
  name: 'page-integration-auth',
  components: {
    IgPageBase,
  },
  computed: {
    ...mapState([
      'loading',
      'module',
    ]),
    getTitle(): string {
      if (this.module && this.module.name) {
        return `Conectando com ${this.module.name}...`;
      }

      return '';
    },
  },
  methods: {
    ...mapActions([
      'getIntegration',
      'getCategories',
    ]),
  },
});
