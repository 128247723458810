






























import { Auth } from '@integrai/ui';

export default {
  name: 'ig-header',
  data() {
    return {
      user: Auth.getUser() || {
        name: 'Easyvan',
        plan: {
          name: 'Basic',
        },
      },
    };
  },
  methods: {
    logout() {
      return Auth.logout();
    },
  },
};
