import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  toggleLoadingSave(state: StateType, isLoading: boolean): void {
    state.loadingSave = isLoading;
  },
  setMappings(state: StateType, mappings: any[]): void {
    state.mappings = mappings;
  },
  setTypes(state: StateType, types: any[]): void {
    state.types = types;
  },
};
