import { RouteConfig } from 'vue-router';

export default {
  path: 'register',
  name: 'page-register',
  component: () => import('./PageRegister.vue'),
  meta: {
    title: 'Cadastro',
  },
} as RouteConfig;
