import { RouteConfig } from 'vue-router';

import PageCategories from './PageCategories.vue';
import PageManageCategory from './pages/manage-category/PageManageCategory.vue';

export default {
  path: 'categories',
  children: [
    {
      path: '',
      name: 'categories',
      component: PageCategories,
      meta: {
        title: 'Categories',
      },
    },
    {
      path: ':id?',
      name: 'manage-category',
      component: PageManageCategory,
      meta: {
        title: 'Gerenciar categoria',
      },
    },
  ],
} as RouteConfig;
