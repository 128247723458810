import { ActionContext } from 'vuex';
import { HttpStore } from '@integrai/ui';
import StateType from './state.type';

export default {
  async getOrders(context: ActionContext<StateType, {}>, filters: any): Promise<any> {
    const { commit } = context || {};

    commit('toggleLoading', true);

    try {
      const response: any = await HttpStore.post('orders/filter', filters);

      commit('setOrders', response.orders);
      commit('setTotal', response.total);
      commit('toggleLoading', false);
    } catch {
      commit('toggleLoading', false);
    }
  },
};
