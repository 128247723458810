import { RouteConfig } from 'vue-router';

export default {
  path: 'terms-and-conditions',
  name: 'page-terms-and-conditions',
  component: () => import('./pageTermsAndConditions.vue'),
  meta: {
    title: 'Termos e Condições Gerais de Uso',
  },
} as RouteConfig;
