import { RouteConfig } from 'vue-router';

import PageOrderDetail from './PageOrderDetail.vue';

export default {
  path: 'detail/:id',
  name: 'orderDetail',
  component: PageOrderDetail,
  meta: {
    title: 'Detalhe do Pedido',
  },
} as RouteConfig;
