import StateType from './state.type';

export default {
  loading: false,
  plans: [],
  actualPlan: {},
  planConsumption: {
    modules: {
      actual: 0,
    },
    events: {
      actual: 0,
    },
  },
} as StateType;
