<template>
  <validation-observer v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <hr />

      <section class="section">
        <div class="columns">
          <div class="column is-full">
            <validation-provider
                rules="required"
                :name="$t('pageMapping.manage.fields.type.label')"
                v-slot="{ errors, valid }"
            >
              <b-field
                  :label="$t('pageMapping.manage.fields.type.label')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label-position="inside">
                <b-select
                    :placeholder="$t('pageMapping.manage.fields.type.placeholder')"
                    v-model="formMapping.type"
                    :disabled="loading"
                    @input="changeType()"
                    expanded>
                  <option
                      v-for="type in types"
                      :key="type.id"
                      :value="type">{{ $t(type.label) }}</option>
                </b-select>
              </b-field>
            </validation-provider>
          </div>
        </div>
        <div class="columns">
          <div class="column is-5">
            <validation-provider
                rules="required"
                :name="$t('pageMapping.manage.fields.moduleConfig.label')"
                v-slot="{ errors, valid }"
            >
              <b-field
                  :label="$t('pageMapping.manage.fields.moduleConfig.label')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label-position="inside">
                <b-select
                    :placeholder="$t('pageMapping.manage.fields.moduleConfig.placeholder')"
                    v-model="formMapping.leftModuleConfig"
                    :disabled="loading || !formMapping.type"
                    @input="changeLeftModuleConfig()"
                    expanded>
                  <option
                      v-for="moduleConfig in leftModulesConfigs"
                      :key="moduleConfig.id"
                      :value="moduleConfig">{{ $t(moduleConfig.label) }}</option>
                </b-select>
              </b-field>
            </validation-provider>

            <validation-provider
              rules="required"
              :name="identifierLabel(formMapping.leftModuleConfig.label)"
              v-slot="{ errors, valid }"
              v-if="formMapping.leftModuleConfig &&
              formMapping.leftModuleConfig.customEvent && !leftManual"
            >
              <b-field
                  :label="identifierLabel(formMapping.leftModuleConfig.label)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label-position="inside">
                <ig-external-config
                    v-model="formMapping.leftIdentifier"
                    :moduleId="formMapping.leftModuleConfig.moduleId"
                    :moduleConfigId="formMapping.leftModuleConfig.id"
                    :customEvent="formMapping.leftModuleConfig.customEvent"
                    valueFormat="object"
                    :placeholder="$t('pageMapping.manage.fields.identifier.placeholder')"
                    :loadingText="$t('pageMapping.manage.fields.identifier.loading')"
                    :key="formMapping.leftModuleConfig.id"
                />
              </b-field>
              <a class="help" @click="toggleLeftManual()" v-if="!leftManual">
                {{ $t('pageMapping.manage.fields.identifier.notFound') }}
              </a>
            </validation-provider>

            <div v-else>
              <template v-if="formMapping.leftModuleConfig && formMapping.leftIdentifier">
                <validation-provider
                    rules="required"
                    :name="manualIdentifierLabel(formMapping.leftModuleConfig.label)"
                    v-slot="{ errors, valid }"
                >
                  <b-field
                      :label="manualIdentifierLabel(formMapping.leftModuleConfig.label)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                    <b-input
                      v-model="formMapping.leftIdentifier.id"
                      :placeholder="$t('pageMapping.manage.fields.identifier.manualPlaceholder')"/>
                  </b-field>
                </validation-provider>

                <validation-provider
                    rules="required"
                    :name="manualIdentifierLabel(formMapping.leftModuleConfig.label)"
                    v-slot="{ errors, valid }"
                >
                  <b-field
                      :label="manualLabel(formMapping.leftModuleConfig.label)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                    <b-input
                      v-model="formMapping.leftIdentifier.label"
                    :placeholder="$t('pageMapping.manage.fields.identifier.manualLabelPlaceholder')"
                    />
                  </b-field>
                </validation-provider>

                <a class="help" @click="toggleLeftManual()" v-if="leftManual">
                  {{ $t('pageMapping.manage.fields.identifier.notFoundBack') }}
                </a>
              </template>
            </div>
          </div>
          <div class="column is-2 has-text-centered">
            <img
                src="@/assets/arrows.png"
                style="height: 50px"
                alt="Setas ilustrando mapeamento" />
          </div>
          <div class="column is-5">
            <validation-provider
                rules="required"
                :name="$t('pageMapping.filters.moduleConfig.label')"
                v-slot="{ errors, valid }"
            >
              <b-field
                  :label="$t('pageMapping.filters.moduleConfig.label')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label-position="inside">
                <b-select
                    :placeholder="$t('pageMapping.filters.moduleConfig.placeholder')"
                    v-model="formMapping.rightModuleConfig"
                    :disabled="loading || !formMapping.leftModuleConfig"
                    @input="changeRightModuleConfig()"
                    expanded>
                  <option
                      v-for="moduleConfig in rightModulesConfigs"
                      :key="moduleConfig.id"
                      :value="moduleConfig">{{ $t(moduleConfig.label) }}</option>
                </b-select>
              </b-field>
            </validation-provider>

            <validation-provider
                rules="required"
                :name="identifierLabel(formMapping.rightModuleConfig.label)"
                v-slot="{ errors, valid }"
                v-if="formMapping.rightModuleConfig &&
                formMapping.rightModuleConfig.customEvent && !rightManual"
            >
              <b-field
                  :label="identifierLabel(formMapping.rightModuleConfig.label)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label-position="inside">
                <ig-external-config
                    v-model="formMapping.rightIdentifier"
                    :moduleId="formMapping.rightModuleConfig.moduleId"
                    :moduleConfigId="formMapping.rightModuleConfig.id"
                    :customEvent="formMapping.rightModuleConfig.customEvent"
                    valueFormat="object"
                    :placeholder="$t('pageMapping.manage.fields.identifier.placeholder')"
                    :loadingText="$t('pageMapping.manage.fields.identifier.loading')"
                    :key="formMapping.rightModuleConfig.id"
                />
              </b-field>
              <a class="help" @click="toggleRightManual()" v-if="!rightManual">
                {{ $t('pageMapping.manage.fields.identifier.notFound') }}
              </a>
            </validation-provider>
            <div v-else>
              <template v-if="formMapping.rightModuleConfig && formMapping.rightIdentifier">
                <validation-provider
                    rules="required"
                    :name="manualIdentifierLabel(formMapping.rightModuleConfig.label)"
                    v-slot="{ errors, valid }"
                >
                  <b-field
                      :label="manualIdentifierLabel(formMapping.rightModuleConfig.label)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                    <b-input
                        v-model="formMapping.rightIdentifier.id"
                        :placeholder="$t('pageMapping.manage.fields.identifier.manualPlaceholder')"
                    />
                  </b-field>
                </validation-provider>

                <validation-provider
                    rules="required"
                    :name="manualIdentifierLabel(formMapping.rightModuleConfig.label)"
                    v-slot="{ errors, valid }"
                >
                  <b-field
                      :label="manualLabel(formMapping.rightModuleConfig.label)"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                    <b-input
                        v-model="formMapping.rightIdentifier.label"
                    :placeholder="$t('pageMapping.manage.fields.identifier.manualLabelPlaceholder')"
                    />
                  </b-field>
                </validation-provider>

                <a class="help" @click="toggleRightManual()" v-if="rightManual">
                  {{ $t('pageMapping.manage.fields.identifier.notFoundBack') }}
                </a>
              </template>

            </div>
          </div>
        </div>
      </section>
      <footer class="field has-addons but has-addons-right">
        <b-button
            native-type="submit"
            :label="$t('pageMapping.manage.buttons.save')"
            :loading="loadingSave"
            :disabled="loadingSave"
            type="is-primary" />
      </footer>

    </form>
  </validation-observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { IgExternalConfig } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('mappings');

export default {
  name: 'ig-manage-mapping',
  components: {
    IgExternalConfig,
  },
  data() {
    return {
      formMapping: {
        leftIdentifier: null,
        rightIdentifier: null,
      },
      leftModulesConfigs: [],
      rightModulesConfigs: [],
      leftIdentifiers: [],
      rightIdentifiers: [],
      leftManual: false,
      rightManual: false,
    };
  },
  computed: {
    ...mapState([
      'loading',
      'loadingSave',
      'types',
    ]),
  },
  methods: {
    ...mapActions([
      'getModuleConfigs',
      'createMapping',
    ]),
    reset() {
      this.$refs.form.reset();
    },
    identifierLabel(moduleName) {
      return this.$t('pageMapping.manage.fields.identifier.label', {
        typeName: this.$t(this.formMapping.type?.label),
        moduleName,
      });
    },
    manualIdentifierLabel(moduleName) {
      return this.$t('pageMapping.manage.fields.identifier.manualIdentifierLabel', {
        typeName: this.$t(this.formMapping.type?.label),
        moduleName,
      });
    },
    manualLabel(moduleName) {
      return this.$t('pageMapping.manage.fields.identifier.manualLabel', {
        typeName: this.$t(this.formMapping.type?.label),
        moduleName,
      });
    },
    toggleLeftManual() {
      this.leftManual = !this.leftManual;
      if (this.leftManual) {
        this.formMapping.leftIdentifier = {};
      } else {
        this.formMapping.leftIdentifier = null;
      }
    },
    toggleRightManual() {
      this.rightManual = !this.rightManual;
      if (this.rightManual) {
        this.formMapping.rightIdentifier = {};
      } else {
        this.formMapping.rightIdentifier = null;
      }
    },
    async changeType() {
      this.reset();
      this.formMapping.leftModuleConfig = null;
      this.formMapping.rightModuleConfig = null;
      this.formMapping.leftIdentifier = null;
      this.formMapping.rightIdentifier = null;
      this.leftManual = false;
      this.rightManual = false;

      this.rightModulesConfigs = [];
      this.leftModulesConfigs = await this.getModuleConfigs({
        type: this.formMapping.type.id,
      });
    },
    async changeLeftModuleConfig() {
      this.reset();

      this.formMapping.rightModuleConfig = null;
      this.formMapping.rightIdentifier = null;
      this.leftManual = false;
      this.rightManual = false;

      if (!this.formMapping.leftModuleConfig?.customEvent) {
        this.formMapping.leftIdentifier = {};
      } else {
        this.formMapping.leftIdentifier = null;
      }

      this.rightModulesConfigs = await this.getModuleConfigs({
        type: this.formMapping.type.id,
        leftModuleConfig: this.formMapping.leftModuleConfig.id,
      });
    },
    async changeRightModuleConfig() {
      this.reset();

      if (!this.formMapping.rightModuleConfig?.customEvent) {
        this.formMapping.rightIdentifier = {};
      } else {
        this.formMapping.rightIdentifier = null;
      }

      this.rightManual = false;
    },
    async onSubmit() {
      const {
        leftModuleConfig,
        rightModuleConfig,
        type,
        leftIdentifier,
        rightIdentifier,
      } = this.formMapping;

      await this.createMapping({
        leftModuleConfig: leftModuleConfig.id,
        rightModuleConfig: rightModuleConfig.id,
        type: type.id,
        leftIdentifier: leftIdentifier.id,
        rightIdentifier: rightIdentifier.id,
        leftLabel: leftIdentifier.label,
        rightLabel: rightIdentifier.label,
      });

      this.formMapping.leftIdentifier = null;
      this.formMapping.rightIdentifier = null;
      this.leftManual = false;
      this.rightManual = false;

      this.reset();

      this.$emit('reload', type.id);
    },
  },
};
</script>
