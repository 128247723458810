import { RouteConfig } from 'vue-router';

export default {
  path: 'login',
  name: 'page-login',
  component: () => import('./PageLogin.vue'),
  meta: {
    title: 'Entrar',
  },
} as RouteConfig;
