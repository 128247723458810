import { RouteConfig } from 'vue-router';

import PageEventHistory from './PageEventHistory.vue';

export default {
  path: 'event-history',
  name: 'event-history',
  component: PageEventHistory,
  meta: {
    title: 'Histórico de Eventos',
  },
} as RouteConfig;
