import { RouteConfig } from 'vue-router';

import PageAccount from './PageAccount.vue';

export default {
  path: 'account',
  name: 'account',
  component: PageAccount,
  meta: {
    title: 'Minha conta',
  },
} as RouteConfig;
