import StateType from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setCategories(state: StateType, categories: any): void {
    state.categories = categories;
  },
};
