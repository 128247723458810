<template>
  <div>
    <h2 class="ig-payment-info__title">
      {{ $t('igPaymentInfo.title') }}

      <b-button
        class="ig-payment-info__btn-back"
        @click="$emit('changeTab', 0)">
        <b-icon icon="arrow-alt-circle-left"></b-icon>
      </b-button>
    </h2>

    <div class="ig-payment-info__method" v-if="method">
      <b-icon
        class="ig-payment-info__method-icon"
        :icon="method.icon"
        size="is-large"/>
      <span class="ig-payment-info__method-label">
        {{ method.label }}
      </span>
    </div>

    <div class="ig-payment-info__method-form">
      <ig-form-payment-credit-card
        :loading="loadingCheckout"
        :public-key="publicKey"
        :is-sandbox="paymentIsSandbox"
        :credit-card="creditCardInfo"
        @save="saveCreditCard"
        v-if="checkout.method === 'CREDIT_CARD'" />
      <ig-form-payment-boleto
        :loading="loadingCheckout"
        :boleto="boletoInfo"
        @save="saveBoleto"
        v-if="checkout.method === 'BOLETO'" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { IgFormPaymentCreditCard } from '@integrai/ui';
import IgFormPaymentBoleto from './IgFormPaymentBoleto.vue';

const { mapState, mapMutations } = createNamespacedHelpers('plans');

export default {
  name: 'ig-payment-info',
  data() {
    return {
      publicKey: process.env.VUE_APP_PAYMENT_PUBLIC_KEY || '',
      paymentIsSandbox: JSON.parse(process.env.VUE_APP_PAYMENT_SANDBOX || false),
    };
  },
  computed: {
    ...mapState([
      'loadingCheckout',
      'methods',
      'checkout',
      'clientInfo',
    ]),
    method() {
      return this.methods
        .find((method) => method.value === this.checkout.method);
    },
    creditCardInfo() {
      const {
        clientInfo: {
          creditCard = null,
        } = {},
      } = this.$store.state.clientInfo || {};

      return creditCard;
    },
    boletoInfo() {
      const {
        clientInfo: {
          planInfo: {
            dueDate = null,
          } = {},
        } = {},
      } = this.$store.state.clientInfo || {};

      return {
        dueDate,
      };
    },
  },
  methods: {
    ...mapMutations([
      'setCheckout',
    ]),
    saveCreditCard(creditCard = {}) {
      this.setCheckout({
        creditCard,
      });
      this.nextStep();
    },
    saveBoleto(boleto = {}) {
      this.setCheckout({
        boleto,
      });
      this.nextStep();
    },
    nextStep() {
      this.$emit('changeTab', 2);
    },
  },
  components: {
    IgFormPaymentCreditCard,
    IgFormPaymentBoleto,
  },
};
</script>

<style lang="scss" scoped>
  .ig-payment-info{
    &__title{
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 2rem;
      margin-right: 4rem;
    }
    &__btn-back{
      padding: 0;
      border: 0;
      font-size: 1.2rem;
      position: absolute;
      left: -25px;
      top: 1px;
      height: auto;
      color: rgba(10,10,10,.2);
    }
    &__method{
      border-radius: 10px;
      padding: .2rem;
      display: flex;
      align-items: center;
      background-color: #fff;
      box-shadow: inset rgba(143,160,241,0.2) 0 0 0 1px, rgba(213,220,247,0.59) 0 10px 20px;
      cursor: default;
      color: #22b2f0;
      border-color: #22b2f0;
      margin-bottom: 4rem;
      &-icon{
        font-size: .9rem;
        margin-right: .2rem;
      }
      &-label{
        font-weight: bold;
      }
    }
  }
</style>
