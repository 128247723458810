<template>
  <ig-page-base>
    <div class="box attributes__content">
      <div class="attributes__title-box">
        <h2 class="title attributes__title has-text-weight-semibold">
          {{ $t(`storePages.product.attributes.manage.${isUpdate ?
          'titleUpdate' : 'titleCreate'}`) }}
        </h2>
      </div>

      <validation-observer ref="formAttribute" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="attributes__form-header">
            <h2 class="attributes__form-title">
              {{ $t('storePages.product.attributes.manage.fields.headerDefault') }}
            </h2>

            <b-switch
              v-model="form.isRequired"
              type="is-primary">
              {{ $t('storePages.product.attributes.manage.fields.isRequired.label') }}
            </b-switch>
          </div>

          <div class="field" v-if="form.code">
            <b-field
              :label="$t('storePages.product.attributes.manage.fields.code.label')"
              label-position="inside">
              <b-input v-model="form.code" disabled />
            </b-field>
          </div>

          <validation-provider
            rules="required"
            :name="$t('storePages.product.attributes.manage.fields.name.label')"
            v-slot="{ errors, valid }"
          >
            <div class="field">
              <b-field
                :label="$t('storePages.product.attributes.manage.fields.name.label')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
                label-position="inside">
                <b-input v-model="form.name" />
              </b-field>
            </div>
          </validation-provider>

          <validation-provider
            rules="required"
            :name="$t('storePages.product.attributes.manage.fields.type.label')"
            v-slot="{ errors, valid }"
          >
            <div class="field">
              <b-field
                :label="$t('storePages.product.attributes.manage.fields.type.label')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
                label-position="inside">
                <b-select
                  v-model="form.type"
                  :placeholder="$t('storePages.product.attributes.manage.fields.type.placeholder')"
                  expanded>
                  <option value="COMBOBOX">
                    {{ $t('storePages.product.attributes.typeEnum.COMBOBOX') }}
                  </option>
                  <option value="TEXT">
                    {{ $t('storePages.product.attributes.typeEnum.TEXT') }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </validation-provider>

          <div v-if="form.type === 'COMBOBOX'">
            <div class="attributes__form-header">
              <h2 class="attributes__form-title">
                {{ $t('storePages.product.attributes.manage.fields.headerVariations') }}
              </h2>

              <b-button
                type="is-primary is-light"
                icon-left="plus"
                size="is-small"
                @click="addAndEditVariation()"
              >
                {{ $t('storePages.product.attributes.manage.fields.btnNewVariation') }}
              </b-button>
            </div>

            <b-table
              :data="form.variations"
              @click="row => addAndEditVariation(row)"
              striped
              hoverable
              scrollable
              :row-class="() => 'attributes__table-row'"
            >
              <template #empty>
                <ig-empty />
              </template>

              <b-table-column
                field="name"
                :label="$t('storePages.product.attributes.manage.fields.variations.header.name')"
                v-slot="props"
                sortable
              >
                {{ props.row.value }}
              </b-table-column>

              <b-table-column
                width="100"
                v-slot="props"
                custom-key="actions"
              >
                <div class="buttons">
                  <b-tooltip
                    append-to-body
                    :label="$t(
                      'storePages.product.attributes.manage.fields.variations.data.actions.edit',
                    )">
                    <b-button
                      class="categories__btn-action"
                      type="is-default is-light"
                      icon-left="pen"
                      size="is-small"
                      @click.stop="addAndEditVariation(props.row)">
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    append-to-body
                    :label="$t(
                     'storePages.product.attributes.manage.fields.variations.data.actions.delete',
                    )">
                    <b-button
                      class="categories__btn-action"
                      type="is-danger is-light"
                      icon-left="trash"
                      size="is-small"
                      @click.stop="deleteVariation(props.row)">
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
            </b-table>
          </div>

          <div class="buttons attributes__form-actions">
            <b-button
              tag="router-link"
              :to="{ name: 'attributes' }"
              type="is-default"
            >
              {{ $t('storePages.product.attributes.manage.btnCancel') }}
            </b-button>

            <b-button
              native-type="submit"
              type="is-primary"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t(`storePages.product.attributes.manage.${isUpdate ?
              'btnUpdate' : 'btnCreate'}`) }}
            </b-button>
          </div>
        </form>
      </validation-observer>
    </div>

    <b-modal
      v-model="showModalVariation"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal>
      <template>
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitVariation)">
            <div class="modal-card" style="width: 400px">
              <header class="modal-card-head">
                <p class="modal-card-title">
                  {{ $t('storePages.product.attributes.manage.fields.variations.modal.title') }}
                </p>
                <button
                  type="button"
                  class="delete"
                  @click="closeModalVariation"/>
              </header>
              <section class="modal-card-body">
                <validation-provider
                  rules="required"
                  :name="$t('storePages.product.attributes.manage.fields.variations.modal.name')"
                  v-slot="{ errors, valid }"
                >
                  <div class="field">
                    <b-field
                      :label="$t(
                        'storePages.product.attributes.manage.fields.variations.modal.name',
                      )"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      label-position="inside">
                      <b-input v-model="variation.value" />
                    </b-field>
                  </div>
                </validation-provider>
              </section>
              <footer class="modal-card-foot">
                <b-button
                  :label="$t(
                  'storePages.product.attributes.manage.fields.variations.modal.btnCancel',
                )"
                  :disabled="loadingVariation"
                  @click="closeModalVariation" />

                <b-button
                  native-type="submit"
                  :label="$t(
                    `storePages.product.attributes.manage.fields.variations.modal.${variation.id
                    ? 'btnUpdate' : 'btnCreate'}`,
                  )"
                  :loading="loadingVariation"
                  :disabled="loadingVariation"
                  type="is-primary" />
              </footer>
            </div>
          </form>
        </validation-observer>
      </template>
    </b-modal>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  IgPageBase,
  IgEmpty,
  EventBus,
} from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('attributes');

export default Vue.extend({
  name: 'page-manage-attribute',
  components: {
    IgPageBase,
    IgEmpty,
  },
  data() {
    return {
      isUpdate: false,
      showModalVariation: false,
      form: {
        isRequired: false,
        type: 'TEXT',
      },
      variation: {},
    };
  },
  computed: {
    ...mapState([
      'loading',
      'loadingVariation',
    ]),
  },
  methods: {
    ...mapActions([
      'getAttribute',
      'createAttribute',
      'updateAttribute',
      'createVariation',
      'updateVariation',
      'removeVariation',
    ]),
    async addAndEditVariation(row) {
      let showModal = true;

      if (!this.isUpdate) {
        showModal = false;
        const isValid = await this.$refs.formAttribute.validate();

        if (isValid) {
          const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            variations,
            ...form
          } = this.form;

          const { id } = await this.createAttribute(form);

          this.$router.push({
            name: 'manage-attribute',
            params: {
              id,
            },
            replace: true,
          });

          this.isUpdate = true;
          showModal = true;
        }
      }

      if (showModal) {
        this.variation = row || {};
        this.showModalVariation = true;
      }
    },
    closeModalVariation() {
      this.showModalVariation = false;
      this.variation = {};
    },
    deleteVariation(row) {
      return this.$buefy.dialog.confirm({
        title: String(this.$t(
          'storePages.product.attributes.manage.fields.variations.deleteDialog.title',
        )),
        message: String(this.$t(
          'storePages.product.attributes.manage.fields.variations.deleteDialog.message',
          {
            name: row.value,
          },
        )),
        confirmText: String(this.$t(
          'storePages.product.attributes.manage.fields.variations.deleteDialog.confirm',
        )),
        cancelText: String(this.$t(
          'storePages.product.attributes.manage.fields.variations.deleteDialog.cancel',
        )),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.removeVariation({
            attributeId: this.$route.params.id,
            id: row.id,
          });

          EventBus.$emit('toast', {
            type: 'is-success',
            message: this.$t(
              'storePages.product.attributes.manage.fields.variations.deleteDialog.successMessage',
            ),
          });

          await this.reloadAttribute();
        },
      });
    },
    async reloadAttribute() {
      this.form = await this.getAttribute(this.$route.params?.id);
    },
    async submit() {
      const {
        id,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        variations,
        ...form
      } = this.form;

      if (this.isUpdate) {
        await this.updateAttribute({
          id,
          form,
        });
      } else {
        await this.createAttribute(form);
      }

      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t(
          `storePages.product.attributes.manage.${this.isUpdate ? 'updateSuccess' : 'createSuccess'}`,
        ),
      });

      this.$router.push({ name: 'attributes' });
    },
    async submitVariation() {
      const attributeId = this.$route.params.id;
      if (this.variation.id) {
        const {
          id,
          ...form
        } = this.variation;

        await this.updateVariation({
          attributeId,
          id,
          form,
        });
      } else {
        await this.createVariation({
          attributeId,
          form: this.variation,
        });
      }

      EventBus.$emit('toast', {
        type: 'is-success',
        message: this.$t(
          `storePages.product.attributes.manage.fields.variations.modal.${
            this.variation.id ? 'updateSuccess' : 'createSuccess'
          }`,
        ),
      });

      await this.reloadAttribute();
      this.closeModalVariation();
    },
  },
  async beforeMount() {
    const { id } = this.$route.params;
    if (id) {
      try {
        this.form = await this.getAttribute(id);
        this.isUpdate = true;
      } catch {
        this.$router.push({ name: 'attributes' });
      }
    }
  },
});
</script>

<style lang="scss">
  .attributes{
    &__title-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__title{
      padding-bottom: 1rem;
    }
    &__form-header{
      display: flex;
      border-bottom: 1px solid #b5b5b5;
      padding: 1rem 0;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }
    &__form-title{
      font-weight: 700;
    }
    &__form-actions{
      justify-content: flex-end;
      margin-top: 2rem;
    }
  }

  .field{
    margin-bottom: 2rem;
  }
</style>
