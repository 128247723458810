





















import Vue from 'vue';
import { Auth } from '@integrai/ui';

export default Vue.extend({
  name: 'page-settings',
  computed: {
    menus() {
      const user = Auth.getUser();

      return [
        {
          label: this.$t('menu.groupAccount'),
          children: [
            {
              pathName: 'account',
              label: this.$t('pageAccount.title'),
            },
            {
              pathName: 'password',
              label: this.$t('pagePassword.title'),
            },
          ],
        },
        user.plan.isMarketplacePlan ? {} : {
          label: this.$t('menu.groupFinance'),
          children: [
            {
              pathName: 'plan-and-consumption',
              label: this.$t('pagePlanAndConsumption.title'),
            },
            {
              pathName: 'finance',
              label: this.$t('pageFinance.title'),
            },
          ],
        },
        {
          label: this.$t('menu.groupModule'),
          children: [
            // {
            //   pathName: 'contingency',
            //   label: this.$t('pageContingency.title'),
            // },
            {
              pathName: 'event-history',
              label: this.$t('pageEventHistory.title'),
            },
          ],
        },
      ];
    },
  },
  methods: {
    isActive(pathName: string): boolean {
      return this.$route.name === pathName;
    },
  },
});
