import StateType, { ClientType } from './state.type';

export default {
  toggleLoading(state: StateType, isLoading: boolean): void {
    state.loading = isLoading;
  },
  setEmailSent(state: StateType, emailSent: boolean): void {
    state.emailSent = emailSent;
  },
  setClient(state: StateType, client: ClientType): void {
    state.client = {
      ...state.client,
      ...client,
    };
  },
};
