import { RouteConfig } from 'vue-router';

import PageIntegrations from './PageIntegrations.vue';

export default {
  path: 'integrations',
  name: 'integrations',
  component: PageIntegrations,
  meta: {
    title: 'Integrações',
  },
} as RouteConfig;
